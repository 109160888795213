import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { getFacturePrestataireAction, updateFacturePrestataireAction, validateFacturePrestataireAction, startLoader } from "../../../store/actions/FacturationAction";
import {
    searchActesAction
} from '../../../store/actions/ProviderActions';
import { searchDrugsAction } from '../../../store/actions/ConsultationActions';
import Select from 'react-select';
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import{withRouter} from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import swal from 'sweetalert';
import './FacturePrestataireDetails.css';

export const FacturePrestataireDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const { code } = useParams();

    const [factureDetails, setFactureDetails] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [pendingUpdate, setPendingUpdate] = useState(false);

    const headerTemplate = (data) => {
        return (
            <div className="flex align-items-center gap-2">
                <span className="font-bold" style={{textTransform: 'uppercase'}}>{data.garantie}</span>
            </div>
        );
    };

    const footerTemplate = (data) => {
        return (
            <React.Fragment>
                <td colSpan="6">
                    <div className="flex justify-content-end font-bold w-full">Total: {factureDetails.filter(elt => elt.garantie === data.garantie).reduce((acc, curr) => acc + curr.montantPrestation, 0)}</div>
                </td>
            </React.Fragment>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                {rowData.keyData === selectedRow.keyData && <InputText className="p-inputtext-sm" value={rowData.acteName} onChange={e => {
                    let temp = [...factureDetails];
                    temp[temp.findIndex(x => x.keyData === selectedRow.keyData)].acteName = e.target.value;
                    setFactureDetails(temp);
                    setPendingUpdate(true);
                }}/>}
                {rowData.keyData !== selectedRow.keyData && <span>{rowData.acteName}</span>}
            </div>
        );
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center" style={{width: '100%'}}>
                {rowData.keyData === selectedRow.keyData && <>
                    {rowData.garantie !== 'pharmacie' && <Select
                        className="select-search"
                        value={{ label: rowData.acteAcareName, value: rowData.acteCode }}
                        options={props.actes.map(elt => { return { label: elt.nameFr, value: elt.code } })}
                        placeholder={t('common.choose')}
                        search
                        disabled={props.facturePrestataire.isValidated}
                        onInputChange={(val) => {
                            console.log(val);
                            if (val.length >= 3) {
                                dispatch(searchActesAction(val));
                            }
                        }}
                        onChange={(val) => {
                            let temp = [...factureDetails];
                            temp[temp.findIndex(x => x.keyData === selectedRow.keyData)].acteCode = val.value;
                            temp[temp.findIndex(x => x.keyData === selectedRow.keyData)].acteAcareName = val.label;
                            setFactureDetails(temp);
                            setPendingUpdate(true);
                        }}
                        style={{
                            lineHeight: '40px',
                            color: '#7e7e7e',
                            paddingLeft: ' 15px',
                            width: '100%'
                        }}
                    />}
                    {rowData.garantie === 'pharmacie' && <Select
                        className="select-search"
                        value={{ label: rowData.acteAcareName, value: rowData.acteCode }}
                        options={props.drugs?.map(elt => {return {...elt, label: elt.name, value: elt.code}})}
                        placeholder={t('common.choose')}
                        search
                        disabled={props.facturePrestataire.isValidated}
                        onInputChange={(val) => {
                            console.log(val);
                            if (val.length >= 3) {
                                dispatch(searchDrugsAction(val));
                            }
                        }}
                        onChange={(val) => {
                            let temp = [...factureDetails];
                            temp[temp.findIndex(x => x.keyData === selectedRow.keyData)].acteCode = val.value;
                            temp[temp.findIndex(x => x.keyData === selectedRow.keyData)].acteId = val.id;
                            temp[temp.findIndex(x => x.keyData === selectedRow.keyData)].acteAcareName = val.label;
                            setFactureDetails(temp);
                            setPendingUpdate(true);
                        }}
                        style={{
                            lineHeight: '40px',
                            color: '#7e7e7e',
                            paddingLeft: ' 15px',
                            width: '100%'
                        }}
                    />}
                </>}
                {rowData.keyData !== selectedRow.keyData && <span>{rowData.acteAcareName}</span>}
            </div>
        );
    };

    const montantBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                {rowData.keyData === selectedRow.keyData && <InputNumber disabled={props.facturePrestataire.isValidated} className="p-inputtext-sm" value={rowData.montantPrestation} min="0" onChange={e => {
                    let temp = [...factureDetails];
                    temp[temp.findIndex(x => x.keyData === selectedRow.keyData)].montantPrestation = e.value;
                    temp[temp.findIndex(x => x.keyData === selectedRow.keyData)].montantTotal = e.value + temp.filter(elt => elt.garantie === rowData.garantie && elt.dataKey !== rowData.dataKey).reduce((acc, curr) => acc + curr.montantPrestation, 0);
                    temp.montantTotal = e.value + temp.filter(elt => elt.dataKey !== rowData.dataKey).reduce((acc, curr) => acc + curr.montantPrestation, 0);
                    setFactureDetails(temp);
                    setPendingUpdate(true);
                }}/>}
                {rowData.keyData !== selectedRow.keyData && <span>{rowData.montantPrestation}</span>}
            </div>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                {rowData.keyData === selectedRow.keyData && <InputText disabled={props.facturePrestataire.isValidated} className="p-inputtext-sm" value={rowData.type} onChange={e => {
                    let temp = [...factureDetails];
                    temp[temp.findIndex(x => x.keyData === selectedRow.keyData)].type = e.target.value;
                    setFactureDetails(temp);
                    setPendingUpdate(true);
                }}/>}
                {rowData.keyData !== selectedRow.keyData && <span>{rowData.type}</span>}
            </div>
        );
    };

    const doctorBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                {rowData.keyData === selectedRow.keyData && <InputText disabled={props.facturePrestataire.isValidated} className="p-inputtext-sm" value={rowData.doctorName} onChange={e => {
                    let temp = [...factureDetails];
                    temp[temp.findIndex(x => x.keyData === selectedRow.keyData)].doctorName = e.target.value;
                    setFactureDetails(temp);
                    setPendingUpdate(true);
                }}/>}
                {rowData.keyData !== selectedRow.keyData && <span>{rowData.doctorName}</span>}
            </div>
        );
    };

    const confidenceBodyTemplate = (rowData) => {

        const stockClassName = classNames('border-circle w-3rem h-3rem inline-flex font-bold justify-content-center align-items-center text-sm', {
            'bg-red-100 text-red-900': rowData.confidence < 50,
            'bg-orange-100 text-orange-900': rowData.confidence >= 50 && rowData.confidence < 100,
            'bg-teal-100 text-teal-900': rowData.confidence === 100
        });

        return <div className={stockClassName}>{rowData.confidence}%</div>;
    };

    const deleteBodyTemplate = (rowData) => {
        return (
            <i 
                className="pi pi-trash" 
                onClick={() => deleteRow(rowData)} 
                style={{ fontSize: '1rem', cursor: 'pointer', color: 'red' }} 
            ></i>
        );
    };
    

    const deleteRow = (rowData) => {
        swal({
            title: "Confirmation de suppression",
            text: "Voulez-vous vraiment supprimer " + rowData.acteName + " ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const updatedDetails = factureDetails.filter(item => item.keyData !== rowData.keyData);
                setFactureDetails(updatedDetails);
                setPendingUpdate(true);
                swal("Supprimé!", "L'acte a été supprimé avec succès.", "success");
            }
        });
    };
    
    

    const rowClass = (data) => {
        return {
            'bg-red-300 ': data.confidence < 50,
            'bg-orange-300 ': data.confidence >= 50 && data.confidence < 100,
        };
    };
    
    const validerFacture = (e) => {
        e.preventDefault();
        dispatch(startLoader());
        dispatch(validateFacturePrestataireAction(props.facturePrestataire.consultationCode));
    };

    const updateFacture = (e) => {
        e.preventDefault();
    
        if (!props.facturePrestataire) return;
    
        const { consultationCode, diagnostic, montantRestant, patientName, dateFacture, acteTypeRequest = [] } = props.facturePrestataire;
    
        // Calcul du montant total une seule fois
        const montantTotal = factureDetails.reduce((acc, curr) => acc + (curr.montantPrestation || 0), 0);
    
        // Optimisation du mapping des actes
        const acteTypeRequestMapped = acteTypeRequest.map((acte) => {
            const acteFactures = factureDetails.filter(elt => elt.garantie === acte.garantie);
    
            return {
                garantie: acte.garantie,
                montantTotal: acteFactures.reduce((acc, curr) => acc + (curr.montantPrestation || 0), 0),
                acteFactures: acteFactures.map(({ acteId, acteName, acteCode, acteAcareName, montantPrestation, type, doctorName, confidence }) => ({
                    acteId,
                    acteName,
                    acteCode,
                    acteAcareName,
                    montantPrestation,
                    type,
                    doctorName,
                    confidence
                }))
            };
        });
    
        const data = {
            consultationCode,
            diagnostic,
            montantRestant,
            montantTotal,
            patientName,
            dateFacture,
            acteTypeRequest: acteTypeRequestMapped
        };
    
        console.log(data);
        dispatch(startLoader());
        dispatch(updateFacturePrestataireAction(null, code, data));
    };
    
    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    useEffect(() => {
        setPendingUpdate(false);
    
        if (props.facturePrestataire && Object.keys(props.facturePrestataire).length > 0) {
            const temp = props.facturePrestataire.acteTypeRequest.flatMap((garan, i) =>
                garan.acteFactures.map((acte, j) => ({
                    ...acte,
                    garantie: garan.garantie,
                    garanAmount: garan.montantTotal,
                    keyData: `${garan.garantie}-${i}-${j}`
                }))
            );
    
            setFactureDetails(temp);
        }
    }, [props.facturePrestataire]);
    

    useEffect(() => {
        if(hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'LISTADMINFACTURE')){
            console.log("About to call all the consultations");
            dispatch(startLoader());
            dispatch(getFacturePrestataireAction(code));
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    return (
        <div className="card p-2">
            <h3 className="my-5" style={{textAlign: 'center', textDecoration: 'underline'}}>Détails Facture consultation n° {code} </h3>
            <div className="form-head my-3" style={{display: 'flex', justifyContent: 'space-between', fontSize: '16px'}}>
                <span><b className="text-primary" style={{textTransform: 'uppercase'}}>Patient: </b> {props.facturePrestataire.patientName}</span>
                <span><b className="text-primary" style={{textTransform: 'uppercase'}}>Diagnostic: </b>{props.facturePrestataire.diagnostic}</span>
                <span><b className="text-primary" style={{textTransform: 'uppercase'}}>Date: </b>{formatDate(new Date(props.facturePrestataire.montantTotal))}</span>
                <span><b className="text-primary" style={{textTransform: 'uppercase'}}>Total: </b>{factureDetails.reduce((acc, curr) => acc + curr.montantPrestation, 0)}</span>
                <span><b className="text-primary" style={{textTransform: 'uppercase'}}>Statut: </b>{props.facturePrestataire.isValidated ? 'Validé' : 'Non Validé'}</span>
            </div>
            <span className="text-info" style={{fontStyle: 'italic'}}>****cliquez sur une ligne pour mettre à jour les informations</span>
            <DataTable value={factureDetails} rowClassName={rowClass} rowGroupMode="subheader" groupRowsBy="garantie" sortMode="single" sortField="garantie"
                    sortOrder={1} rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate} tableStyle={{ minWidth: '50rem' }}
                    selectionMode="single" selection={selectedRow} onSelectionChange={(e) => setSelectedRow(e.value)} dataKey="keyData" 
                    loading={props.showLoading} size='small'>
                <Column field="acteName" header="Acte" body={nameBodyTemplate} style={{ minWidth: '200px' }}></Column>
                <Column field="acteAcareName" header="Acte Acare" body={codeBodyTemplate} style={{ minWidth: '200px' }}></Column>
                <Column field="montantPrestation" header="Montant" body={montantBodyTemplate} style={{ minWidth: '200px' }}></Column>
                <Column field="type" header="Type" body={typeBodyTemplate} style={{ minWidth: '200px' }}></Column>
                <Column field="doctorName" header="Médecin" body={doctorBodyTemplate} style={{ minWidth: '200px' }}></Column>
                <Column field="confidence" header="Fiabilité" body={confidenceBodyTemplate} style={{ minWidth: '200px' }}></Column>
                <Column header="Actions" body={deleteBodyTemplate} style={{ minWidth: '120px', textAlign: 'center' }} />
            </DataTable>
            <div className="form-head mt-3 mt-md-4 mr-2" style={{display: 'flex', justifyContent: 'end'}}>
                {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATEACTE') && <button
                    type="button"
                    className="btn btn-primary btn-rounded mr-2"
                    onClick={(e) => updateFacture(e)}
                    disabled={props.facturePrestataire.isValidated || !pendingUpdate}
                >
                    Enregistrer
                </button>}
                {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATEACTE') && <button
                    type="button"
                    className="btn btn-success btn-rounded"
                    onClick={(e) => validerFacture(e)}
                    disabled={props.facturePrestataire.isValidated || pendingUpdate}
                >
                    Valider
                </button>}
            </div>
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        facturePrestataire: state.facturation.facturePrestataire,
        drugs: state.consultation.drugs,
        actes: state.provider.actesRes,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(FacturePrestataireDetails));
