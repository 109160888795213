import {
    GET_DASHBOARD_CONFIRMED_ACTION,
    GET_DASHBOARD_FAILED_ACTION,
    LOADING_TOGGLE_ACTION,
    GET_RAPPORT_AMBUHOSPI_CONFIRMED_ACTION,
    CREATE_JIRA_TICKET_CONFIRMED_ACTION
} from '../actions/HomeAction';

const initialState = {
    dataDashboard: {},
    rapportAmbuHospi: [],
    jiraTicketLink: '',
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const HomeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_CONFIRMED_ACTION:
            return {
                ...state,
                dataDashboard: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_RAPPORT_AMBUHOSPI_CONFIRMED_ACTION:
            return {
                ...state,
                rapportAmbuHospi: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case CREATE_JIRA_TICKET_CONFIRMED_ACTION:
            return {
                ...state,
                jiraTicketLink: action.payload,
                errorMessage: '',
                successMessage: 'Jira succesfully created in JIRA',
                showLoading: false,
            };
            
        case GET_DASHBOARD_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
          return state;
    };
};