import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { getDetailContractAction, getDifferenceOrassAction } from "../../../store/actions/ContratActions";
import { useParams, Link } from "react-router-dom";
const XLSX = require('xlsx');

const PoliceDashboard = (props) => {
  const dispatch = useDispatch();
  const [numeroPolice, setNumeroPolice] = useState("");
  const [data, setData] = useState(null);
  const [selectedAvenantType, setSelectedAvenantType] = useState("");
  const { numPolice } = useParams();
  // State for the differences modal
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  // List of avenant types
  const avenantTypes = [
    { code: 1, label: "Avenant de renouvellement" },
    { code: 2, label: "Avenant de prorogation" },
    { code: 3, label: "Avenant de suspension" },
    { code: 7, label: "Avenant de changement d'assure" },
    { code: 16, label: "Avenant d'extension de garanties" },
    { code: 17, label: "Avenant de retrait de garanties" },
    { code: 22, label: "Avenant d'incorporation" },
    { code: 23, label: "Avenant de retrait" },
    { code: 25, label: "Avenant de modification" },
    { code: 27, label: "Avenant de Correction Interne" },
    { code: 40, label: "Avenant de renouvellement police abonnement" },
    { code: 41, label: "Avenant de modification police abonnement" },
    { code: 50, label: "Avenant Modification de durée" },
    { code: 55, label: "Avenant d'incorporation et Retrait" },
    { code: 57, label: "Avenant de Prorogation" },
    { code: 99, label: "Avenant de mise en conformité" },
  ];
  const handleSearch = () => {
    dispatch(getDetailContractAction(numeroPolice));
  };

  // --- Function to call the API and display differences in a modal ---
  const handleCheckDifference = async (apiType) => {
    if (!data || !data.numeroPolice) return;
    try {
      setModalLoading(true);
      setShowModal(true);
      // Call the API endpoint with the current police number and the passed type.
      dispatch(getDifferenceOrassAction(data.numeroPolice, apiType));

      setModalTitle(`Différences pour ${apiType}`);
    } catch (error) {
      console.error("Error fetching differences:", error);
      setModalContent({
        message: "Erreur lors de la récupération des différences",
        differences: [],
      });
    } finally {
      setModalLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getDetailContractAction(numPolice));
    setNumeroPolice(numPolice)
    if (props.differenceOrass) {
      setModalContent(props.differenceOrass);
    }
  }, [props.differenceOrass]);

  useEffect(() => {
    if (props.detailContract) {
      setData(props.detailContract);
    }
  }, [props.detailContract]);

  // --- Global Avenant Filtering Logic --- //
  const selectedType = selectedAvenantType ? parseInt(selectedAvenantType, 10) : null;
  const validAvenantNumbers =
    selectedType && data?.avenants
      ? data.avenants
        .filter((av) => av.codeTypeAvenant === selectedType)
        .map((av) => av.numeroAvenant)
      : null;

  const filteredAvenants = (data?.avenants || []).filter((item) => {
    if (!selectedAvenantType) return true;
    return item.codeTypeAvenant === selectedType;
  });

  const filteredCaracteristiques = (data?.caracteristiques || []).filter((item) => {
    if (!selectedAvenantType) return true;
    return validAvenantNumbers && validAvenantNumbers.includes(item.numeroAvenant);
  });

  const filteredGroupePlafonds = (data?.groupePlafonds || []).filter((item) => {
    if (!selectedAvenantType) return true;
    return validAvenantNumbers && validAvenantNumbers.includes(item.numeroAvenant);
  });

  const filteredPoliceCollege = (data?.policeCollege || []).filter((item) => {
    if (!selectedAvenantType) return true;
    return validAvenantNumbers && validAvenantNumbers.includes(item.numeroAvenantModif);
  });

  const filteredPoliceGaranties = (data?.policeGaranties || []).filter((item) => {
    if (!selectedAvenantType) return true;
    return validAvenantNumbers && validAvenantNumbers.includes(item.numeroAvenant);
  });

  const filteredBeneficiaries = (data?.beneficiaries || []).filter((item) => {
    if (!selectedAvenantType) return true;
    return validAvenantNumbers && validAvenantNumbers.includes(item.numeroAvenantModif);
  });

  const getBeneficiaryName = (numBeneficiairePrincipal) => {
    const principalBeneficiary = filteredBeneficiaries.find(
      (beneficiary) => beneficiary.numBeneficiaire == numBeneficiairePrincipal
    );
    return principalBeneficiary ? principalBeneficiary.nom : ""; // Return name or empty string if not found
  };
  // Function to export the JSON data to an Excel file with multiple sheets
  const exportToExcel = () => {
    // Extract the top-level data (non-array keys)
    const mainData = {};
    for (const key in data) {
      if (!Array.isArray(data[key])) {
        mainData[key] = data[key];
      }
    }

    // Convert each section to a worksheet
    const ws_main = XLSX.utils.json_to_sheet([mainData]);
    const ws_caracteristiques = XLSX.utils.json_to_sheet(data.caracteristiques);
    const ws_policeCollege = XLSX.utils.json_to_sheet(data.policeCollege);
    const ws_policeGaranties = XLSX.utils.json_to_sheet(data.policeGaranties);
    const ws_beneficiaries = XLSX.utils.json_to_sheet(data.beneficiaries);

    // Create a new workbook and append sheets
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws_beneficiaries, 'Beneficiaries');
    XLSX.utils.book_append_sheet(wb, ws_main, 'Police');
    XLSX.utils.book_append_sheet(wb, ws_caracteristiques, 'Caracteristiques');
    XLSX.utils.book_append_sheet(wb, ws_policeCollege, 'PoliceCollege');
    XLSX.utils.book_append_sheet(wb, ws_policeGaranties, 'PoliceGaranties');

    // Write the workbook to a file
    XLSX.writeFile(wb, 'data_police_' + numeroPolice + '.xlsx');

    console.log("Data has been exported to 'policy_data.xlsx'");
  };
  // get avenant lib
  const getAvenantLibe = (code) => {
    var avenant = avenantTypes.find(
      (item) => item.code == code
    );
    return avenant.label
  }
  return (
    <div className=" mt-4">
      {/* Search Section */}
      <div className="row mb-4">
        <div className="col-md-8">
          <Form.Group controlId="numpolice">
            <Form.Label>Numéro de police</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                value={numeroPolice}
                onChange={(e) => setNumeroPolice(e.target.value)}
                placeholder="Entrer le numéro de police"
                className="me-2"
              />
              <Button onClick={handleSearch} size="sm" variant="primary">
                Rechercher
              </Button>
            </div>
          </Form.Group>
        </div>



        {/* Global Avenant Filter */}
        <div className="col-md-4 mb-4">
          <div className="col-md-12">
            <Form.Group controlId="avenantFilter">
              <Form.Label>Filtrer par type d'avenant</Form.Label>
              <Form.Control
                as="select"
                value={selectedAvenantType}
                onChange={(e) => setSelectedAvenantType(e.target.value)}
              >
                <option value="">Tous les avenants</option>
                {avenantTypes?.map((type) => (
                  <option key={type.code} value={type.code}>
                    {type.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        </div>
      </div>

      {/* Display Contract Details (not filtered) */}
      {data && (
        <>
          <div className="d-flex justify-content-between">
            <h2>Détails de la Police</h2>
            <Button variant="primary" size="sm" onClick={exportToExcel}>
              Exporter en xlsx <i className="fa fa-download" ></i>
            </Button>
          </div>

          <Table striped bordered>
            <tbody>
              <tr>
                <td><strong>Numéro Police:</strong> </td>
                <td><strong>Date Effet:</strong></td>
                <td><strong>Date Expiration:</strong></td>
                <td><strong>Date Souscription:</strong></td>
                <td><strong>Code Intermédiaire:</strong></td>
                <td><strong>Code Produit:</strong></td>
              </tr>
              <tr>
                <td>{data.numeroPolice} (<Link variant="outline-primary" className="text-red" size="sm" to="#" onClick={() => handleCheckDifference("Police")}>
              Comparer avec ORASS <i className="fa fa-exchange" ></i>)
            </Link></td>
                <td>{new Date(data.dateEffet).toLocaleDateString()}</td>
                <td>{new Date(data.dateExpiration).toLocaleDateString()}</td>
                <td>{new Date(data.dateSouscription).toLocaleDateString()}</td>
                <td>{data.codeIntermediaire}</td>
                <td>{data.codeProduit}</td>
              </tr>
            </tbody>
          </Table>

          {/* Tabs Section */}
          <Tabs defaultActiveKey="beneficiaries" id="police-dashboard-tabs" className="mb-3">
            {/* Beneficiaries */}
            <Tab eventKey="beneficiaries" title="Beneficiaries">
              <div className="d-flex justify-content-between mb-2">
                <h4></h4>
                <Link variant="outline-primary" className="text-red" size="sm" onClick={() => handleCheckDifference("Beneficiaires")}>
                  Comparer avec ORASS <i className="fa fa-exchange" ></i>
                </Link>
              </div>
              <Table striped bordered hover>

                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Numero Beneficiaire</th>
                    <th>Nom Beneficiaire Princ</th>
                    <th>Lien parenté</th>
                    <th>Sexe</th>
                    <th>Date Naissance</th>
                    <th>Date Entrée</th>
                    <th>Date Sortie</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBeneficiaries?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.nom}</td>
                      <td>{item.numBeneficiaire}</td>
                      <td>{getBeneficiaryName(item.numBeneficiairePrincipal)}</td>
                      <td>{item.lienParente}</td>
                      <td>{item.sexe}</td>
                      <td>{new Date(item.dateNaissance).toLocaleDateString()}</td>
                      <td>{new Date(item.dateEntree).toLocaleDateString()}</td>
                      <td>{new Date(item.dateSortie).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
            {/* Caractéristiques */}
            <Tab eventKey="caracteristiques" title="Caractéristiques">
              <div className="d-flex justify-content-end mb-2">
                {/* <Button variant="outline-primary" size="sm" onClick={() => handleCheckDifference("Caracteristique")}>
                  Comparer avec Orass
                </Button> */}
              </div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Valeur</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCaracteristiques?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.codeCaracteristique}</td>
                      <td>{item.valeurCaracteristique || "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>

            {/* Couvertures */}
            <Tab eventKey="couvertures" title="Couvertures">
              <div className="d-flex justify-content-end mb-2">
                {/* <Button variant="info" size="sm" onClick={() => handleCheckDifference("Couverture")}>
                  Comparer avec Orass
                </Button> */}
              </div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Code Acte</th>
                    <th>Montant Acte</th>
                    <th>Ticket Moderateur</th>
                    <th>Plafond Acte</th>
                  </tr>
                </thead>
                <tbody>
                  {(data.couvertures || [])?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.codeActe || "N/A"}</td>
                      <td>{item.montantActe}</td>
                      <td>{item.ticketModerateur}</td>
                      <td>{item.plafondActe}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>

            {/* Groupe Plafonds */}
            <Tab eventKey="groupePlafonds" title="Groupe Plafonds">
              <div className="d-flex justify-content-end mb-2">
                {/* <Button variant="info" size="sm" onClick={() => handleCheckDifference("GroupePlafond")}>
                  Comparer avec Orass
                </Button> */}
              </div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Code College</th>
                    <th>Plafond Montant</th>
                    <th>Plafond Nombre</th>
                    <th>Période</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredGroupePlafonds?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.codeCollege}</td>
                      <td>{item.plafondMontant}</td>
                      <td>{item.plafondNbre}</td>
                      <td>{item.periode}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>

            {/* Police College */}
            <Tab eventKey="policeCollege" title="Police College">
              <div className="d-flex justify-content-end mb-2">
                {/* <Button variant="info" size="sm" onClick={() => handleCheckDifference("PoliceCollege")}>
                  Comparer avec Orass
                </Button> */}
              </div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Code College</th>
                    <th>Libe Groupe</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPoliceCollege?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.codeCollege}</td>
                      <td>{item.libeGroupe || "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>

            {/* Police Garanties */}
            <Tab eventKey="policeGaranties" title="Police Garanties">
              <div className="d-flex justify-content-end mb-2">
                {/* <Button variant="info" size="sm" onClick={() => handleCheckDifference("PoliceGarantie")}>
                  Comparer avec Orass
                </Button> */}
              </div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Code Garantie</th>
                    <th>Prime HT</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPoliceGaranties?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.codeGarantie || "N/A"}</td>
                      <td>{item.primeHT}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>

            {/* Quittances */}
            <Tab eventKey="quittances" title="Quittances">
              <div className="d-flex justify-content-end mb-2">
                {/* <Button variant="info" size="sm" onClick={() => handleCheckDifference("Quittance")}>
                  Comparer avec Orass
                </Button> */}
              </div>
              {data.quittances && data.quittances.length > 0 ? (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data.quittances || [])?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.montant} €</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>Aucune quittance disponible.</p>
              )}
            </Tab>

            {/* Avenants */}
            <Tab eventKey="avenants" title="Avenants">
              <div className="d-flex justify-content-end mb-2">
                <Button variant="info" size="sm" onClick={() => handleCheckDifference("Avenants")}>
                  Comparer avec Orass
                </Button>
              </div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Code Type</th>
                    <th>Numero Avenant</th>
                    <th>Date Echeance</th>
                    <th>Date Effet</th>
                    <th>Date Expiration</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAvenants?.map((item, index) => (
                    <tr key={index}>
                      <td>{getAvenantLibe(item.codeTypeAvenant)}</td>
                      <td>{item.numeroAvenant}</td>
                      <td>{item.dateEcheance}</td>
                      <td>{item.dateEffet}</td>
                      <td>{item.dateExpiration}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </>
      )}

      {/* Differences Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalLoading ? (
            <p>Chargement...</p>
          ) : (
            <div>
              {modalContent?.message && <p>{modalContent.message}</p>}
              {modalContent?.differences && modalContent.differences.length > 0 ? (
                <ul>
                  {modalContent.differences?.map((diff, index) => (
                    <li key={index}>{diff}</li>
                  ))}
                </ul>
              ) : (
                <p>Aucune différence trouvée.</p>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  detailContract: state.contrat.detailContract,
  differenceOrass: state.contrat.differenceOrass,
  errorMessage: state.contrat.errorMessage,
  successMessage: state.contrat.successMessage,
  showLoading: state.contrat.showLoading,
});

export default connect(mapStateToProps)(PoliceDashboard);
