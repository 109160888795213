import { useEffect } from 'react';

function Chatbot() {
    useEffect(() => {
        const chatbotContainer = document.getElementById('chatbot-container');
        if (window.ChatbotWidget && chatbotContainer) {
            ChatbotWidget.init({
                endpoint: 'https://activa-chatbot-dev.azurewebsites.net/webhook',
                container: chatbotContainer,
                language: "FR",
                app: "Acare",
                from: Date.now() + Math.random().toString(36).substr(2, 9)
            });
        }
    }, []);

    return <div id="chatbot-container"></div>;
}

export default Chatbot;