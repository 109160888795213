import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const FacturationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

const ParamServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND,
  headers: headers 
});

const AodocsServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_AODOCS,
  headers: headers 
});

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

// Règlements
//export const getFactures = () => FacturationServiceRequest.get('/factures/impaye');
export const getFactures = () => FacturationServiceRequest.get('/facture/bordereaux');
export const getFacture = (id) => FacturationServiceRequest.get('/factures/'+id);
//export const getBordereauDetail = (id) => FacturationServiceRequest.get('/facture/borderaux/'+id);
export const getBordereauDetail = (id) => FacturationServiceRequest.get('/facture/bordereau/'+id);
export const getMotifExclusion = () => ParamServiceRequest.get('/Parameter/exclusions');
export const saveRejet = (id, data) => FacturationServiceRequest.put('/factures/exclusion/'+id, data);
export const restoreRejet = (id) => FacturationServiceRequest.put('/factures/exclusion/'+id+'/restore');
//export const sendFactureForPayment = (id) => AodocsServiceRequest.get('/facture/payment/'+id+'/send');
export const sendFactureForPayment = (id) => AodocsServiceRequest.put('/bordereau/'+id+'/send-to-paiement');

// Remboursements
export const getRemboursements = () => FacturationServiceRequest.get('/factures/patients');
export const getRemboursement = (id) => FacturationServiceRequest.get('/facture/'+id+'/patient');
export const requestRemboursement = (data) => FacturationServiceRequest.post('/factures/remboursement-detail', data);
export const updateRemboursementRequest = (id, data) => FacturationServiceRequest.put('/factures/remboursement-request/'+id, data);
export const updateRemboursement = (id, data) => FacturationServiceRequest.put('/factures/remboursement-detail/'+id, data);
//export const calculationRembBase = (providerId, patientId, acteId, montant) => ParamServiceRequest.get('/parameter/Tarif/base-remboursement/'+providerId+'/'+patientId+'/'+acteId+'/'+montant);
//export const calculationTM = (garantieId, patientId) => ParamServiceRequest.get('/parameter/Tarif/garantie/'+garantieId+'/'+patientId);
export const calculationRembBase = (providerId, patientId, acteId) => ParamServiceRequest.get('/parameter/Tarif/tarif-acte/'+providerId+'/'+patientId+'/'+acteId);
export const calculationNewRembBase = (data) => ParamServiceRequest.post('/parameter/Tarif/remboursement/base-remboursement', data);
export const calculationTM = (patientId) => ParamServiceRequest.get('/parameter/Tarif/pharmacie/tm/'+patientId);
export const sendRembForPayment = (id) => consultationServiceRequest.get('/remboursement/payment/'+id+'/send');

// Prestations
export const getPrestations = () => FacturationServiceRequest.get('/facture/saisi-prestations');
export const getPrestation = (id) => FacturationServiceRequest.get('/facture/saisi-prestation/detail/'+id);
export const createPrestations = (data) => FacturationServiceRequest.post('/facture/saisi-prestation', data);
export const closePrestations = (id) => FacturationServiceRequest.put('/facture/saisi-prestation/close/'+id);
export const deletePrestations = (id) => FacturationServiceRequest.delete('/facture/saisi-prestation/delete/'+id);

//Paiements
export const payFacture = (data) => consultationServiceRequest.post('/Paiement/paiement', data);
export const payRemboursement = (data) => consultationServiceRequest.post('/Paiement/paiement/remboursement', data);
export const getPaiements = () => consultationServiceRequest.get('/Paiement/paiements');
export const updatePaiement = (id, data) => consultationServiceRequest.put('/Paiement/paiement/'+id, data);
export const deletePaiement = (id) => consultationServiceRequest.delete('/Paiement/paiement/'+id);

//Billing Management
export const listFacturePrestataire = () => consultationServiceRequest.get('/get-facture-prestataire-ai');
export const getFacturePrestataire = (code) => consultationServiceRequest.get('/get-facture-prestataire-ai/'+code);
export const updateFacturePrestataire = (id, data) => consultationServiceRequest.put('/update-facture-prestataire-ai/'+id, data);
export const deleteFacturePrestataire = (id) => consultationServiceRequest.delete('/delete-facture-prestataire-ai/'+id);
export const validateFacturePrestataire = (id) => consultationServiceRequest.get('/create-facture-prestataire-from-ai/'+id);
export const uploadFacturesPrestataire = (providerId, data) => consultationServiceRequest.post('/facture/ai/'+providerId, data);
export const listUploadFacturesPrestataire = () => consultationServiceRequest.get('/facture/ai');