import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap'
import GlocalActs from './GlobalActs';
import ParametrageTypeControle from './ParametrageTypeControle';
import ParametrageIdentification from './ParametrageIdentification';
import { useTranslation } from "react-i18next";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";

const GeneralParameters = (props) => {
    const { t } = useTranslation();


    const tabData = [
        {
            label: t("provider.globalActs"),
            name: "Actes globaux",
            icon: 'building',
            content: <GlocalActs/>
        },
        {
            label: t("provider.controlTypes"),
            name: "Types de contrôles",
            icon: 'building',
            content: <ParametrageTypeControle/>
        },
        /*{
            label: t("provider.globalActs"),
            name: "Types d'identification",
            icon: 'building',
            content: <ParametrageIdentification/>
        },*/
    ]
    
    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    const hasAccess = (type) => {
        switch(type){
            case 'actes globaux':
                return hasMenu('MENPARAM') && canDo('MENPARAM', 'LISTSTANDACTS')
            case 'types de contrôles':
                return hasMenu('MENPARAM') && canDo('MENPARAM', 'LISTSTANDACTS')
            case 'types d\'identification':
                return hasMenu('MENPARAM') && canDo('MENPARAM', 'LISTSTANDACTS')
            default:
                return false
            
        }
    }

    return (
        <Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>{t('provider.standardParameter')}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {/* <!-- Nav tabs --> */}
                            <div className='custom-tab-1'>
                                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                                    <Nav as='ul' className='nav-tabs'>
                                        {tabData.map((data, i) => (
                                            <Nav.Item as='li' key={i}>
                                                <Nav.Link disabled={!hasAccess(data.name.toLowerCase())} eventKey={data.name.toLowerCase()}>
                                                    <i className={`la la-${data.icon} mr-2`} />
                                                    {data.label}
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                    <Tab.Content className='pt-4'>
                                        {tabData.map((data, i) => (
                                            <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                                <div>{data.content}</div>
                                            </Tab.Pane>
                                        ))}
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        errorMessage: state.admin.errorMessage,
        successMessage: state.admin.successMessage,
        showLoading: state.admin.showLoading,
        admin: state.admin.administration,
        currentUser: currentUser(state)
    };
};
export default connect(mapStateToProps)(GeneralParameters);