import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { getMissingDataSynchroAction } from "../../../store/actions/ContratActions";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card, Table, Form, Button } from 'react-bootstrap';

const MissingDataSynchro = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Main data states
  const [missingPolicies, setMissingPolicies] = useState([]);
  const [missingBeneficiaries, setMissingBeneficiaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filter states
  const [policyFilter, setPolicyFilter] = useState('');
  const [beneficiaryNameFilter, setBeneficiaryNameFilter] = useState('');

  // Pagination states
  const [currentPolicyPage, setCurrentPolicyPage] = useState(1);
  const [currentBeneficiaryPage, setCurrentBeneficiaryPage] = useState(1);
  const policiesPerPage = 10;
  const beneficiariesPerPage = 10;

  // Dispatch action to load missing data
  useEffect(() => {
    dispatch(getMissingDataSynchroAction());
  }, [dispatch]);

  // When missingData updates in props, update local state
  useEffect(() => {
    console.log(props.missingData);
    setLoading(false);
    setMissingPolicies(props.missingData?.missingPolicies || []);
    setMissingBeneficiaries(props.missingData?.missingBeneficiaries || []);
  }, [props.missingData]);

  // Filter policies by numeroPolice or typePolice (case insensitive)
  const filteredPolicies = missingPolicies.filter(policy =>
    policy.numeroPolice.toLowerCase().includes(policyFilter.toLowerCase()) ||
    (policy.typePolice && policy.typePolice.toLowerCase().includes(policyFilter.toLowerCase()))
  );

  // Filter beneficiaries by nom (case insensitive)
  const filteredBeneficiaries = missingBeneficiaries.filter(beneficiary =>
    beneficiary.nom.toLowerCase().includes(beneficiaryNameFilter.toLowerCase())
  );

  // Pagination for policies
  const indexOfLastPolicy = currentPolicyPage * policiesPerPage;
  const indexOfFirstPolicy = indexOfLastPolicy - policiesPerPage;
  const currentPolicies = filteredPolicies.slice(indexOfFirstPolicy, indexOfLastPolicy);
  const totalPolicyPages = Math.ceil(filteredPolicies.length / policiesPerPage);

  // Pagination for beneficiaries
  const indexOfLastBeneficiary = currentBeneficiaryPage * beneficiariesPerPage;
  const indexOfFirstBeneficiary = indexOfLastBeneficiary - beneficiariesPerPage;
  const currentBeneficiaries = filteredBeneficiaries.slice(indexOfFirstBeneficiary, indexOfLastBeneficiary);
  const totalBeneficiaryPages = Math.ceil(filteredBeneficiaries.length / beneficiariesPerPage);
  
  function formatDate(dateString) {
    if (!dateString) return ''; // Return empty string if input is null or falsy
    if(dateString=='01-01-2001') return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return dateString.split(" ")[0]; // Return empty string if the date is invalid
    }
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }
  
  if (loading) {
    return <div>Loading data...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Card className="mb-4 shadow">
            <Card.Header as="h4">Polices non synchronisées</Card.Header>
            <Card.Body>
              <Form.Group as={Row} controlId="policyFilter">
                <Form.Label column sm="2">Filter:</Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Filter by NumeroPolice or TypePolice"
                    value={policyFilter}
                    onChange={(e) => {
                      setPolicyFilter(e.target.value);
                      setCurrentPolicyPage(1);
                    }}
                  />
                </Col>
              </Form.Group>
              {filteredPolicies.length === 0 ? (
                <p>Aucune police manquante trouvé.</p>
              ) : (
                <>
                  <Table striped bordered hover responsive className="mt-3">
                    <thead>
                      <tr>
                        <th>Numéro Police</th>
                        <th>Numéro Avenant</th>
                        <th>Code Intermediaire</th>
                        <th>Numero Client</th>
                        <th>Date Effet</th>
                        <th>Date Expiration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPolicies.map((policy, index) => (
                        <tr key={index}>
                          <td>{policy.numeroPolice}</td>
                          <td>{policy.numeroAvenantModif}</td>
                          <td>{policy.codeIntermediaire}</td>
                          <td>{policy.numeroClient}</td>
                          <td>{formatDate(new Date(policy.dateEffet).toLocaleString())}</td>
                          <td>{formatDate(new Date(policy.dateExpiration).toLocaleString())}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      variant="primary"
                      className='sm'
                      onClick={() => setCurrentPolicyPage(prev => Math.max(prev - 1, 1))}
                      disabled={currentPolicyPage === 1}
                    >
                      Previous
                    </Button>
                    <span>
                      Page {currentPolicyPage} of {totalPolicyPages}
                    </span>
                    <Button
                      variant="primary"
                      onClick={() => setCurrentPolicyPage(prev => Math.min(prev + 1, totalPolicyPages))}
                      disabled={currentPolicyPage === totalPolicyPages}
                    >
                      Next
                    </Button>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="mb-4 shadow">
            <Card.Header as="h4">Missing Beneficiaries</Card.Header>
            <Card.Body>
              <Form.Group as={Row} controlId="beneficiaryFilter">
                <Form.Label column sm="2">Search:</Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Search by Nom"
                    value={beneficiaryNameFilter}
                    onChange={(e) => {
                      setBeneficiaryNameFilter(e.target.value);
                      setCurrentBeneficiaryPage(1);
                    }}
                  />
                </Col>
              </Form.Group>
              {filteredBeneficiaries.length === 0 ? (
                <p>No missing beneficiaries found.</p>
              ) : (
                <>
                  <Table striped bordered hover responsive className="mt-3">
                    <thead>
                      <tr>
                        <th>NumeroPolice</th>
                        {/* <th>NumeroBeneficiaire</th> */}
                        <th>Nom</th>
                        <th>DateNaissance</th>
                        <th>Sexe</th>
                        {/* <th>LienParente</th> */}
                        <th>DateEntree</th>
                        <th>DateSortie</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentBeneficiaries.map((beneficiary, index) => (
                        <tr key={index}>
                          <td>{beneficiary.numeroPolice}</td>
                          {/* <td>{beneficiary.numeroBeneficiaire}</td> */}
                          <td>{beneficiary.nom}</td>
                          <td>{formatDate(new Date(beneficiary.dateNaissance).toLocaleString())}</td>
                          <td>{beneficiary.sexe}</td>
                          {/* <td>{beneficiary.lienParente}</td> */}
                          <td>{formatDate(new Date(beneficiary.dateEntree).toLocaleString())}</td>
                          <td>{formatDate(new Date(beneficiary.dateSortie).toLocaleString())}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      variant="primary"
                      onClick={() => setCurrentBeneficiaryPage(prev => Math.max(prev - 1, 1))}
                      disabled={currentBeneficiaryPage === 1}
                    >
                      Previous
                    </Button>
                    <span>
                      Page {currentBeneficiaryPage} of {totalBeneficiaryPages}
                    </span>
                    <Button
                      variant="primary"
                      onClick={() => setCurrentBeneficiaryPage(prev => Math.min(prev + 1, totalBeneficiaryPages))}
                      disabled={currentBeneficiaryPage === totalBeneficiaryPages}
                    >
                      Next
                    </Button>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  missingData: state.contrat.missingData,
  errorMessage: state.contrat.errorMessage,
  successMessage: state.contrat.successMessage,
  showLoading: state.contrat.showLoading,
});

export default connect(mapStateToProps)(MissingDataSynchro);
