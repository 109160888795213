
import { connect, useDispatch } from 'react-redux';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import "./PreIdentify.css";
import "./PreIdentify.scss";

const PreEnroll = (props) => {
    const { currentUser, history } = props;
    const {t}=useTranslation()

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if (hasMenu('MENENROLL')) {
        } else {
            props.history.push("/");
        }
    }, []);

    return (
        <>
            <div className="page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <Link to="#">Patient</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="#">Authentification</Link>
                    </li>
                </ol>
            </div>
            <div className="authincation h-100 p-meddle">
                <div className="container h-100" style={{ position: 'fixed' }}>


                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-6">

                            < div className="authincation-content" >
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form row">
                                            <h3 className="text-center mb-5">
                                                {t("patient.identification-patient")}
                                            </h3>

                                            <div>

                                                <div className="row justify-content-center">
                                                    <Button className='mr-2' variant='outline-primary btn-lg' onClick={e => history.push("/identification")} style={{ width: '90%' }}>
                                                        <span className='btn-icon-left text-primary' style={{ background: 'none' }}>
                                                            <i className='fa fa-camera' />
                                                        </span>
                                                        <span style={{ textTransform: 'uppercase' }}>{t("patient.have-insured-number")}</span>
                                                    </Button>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <Button className='mr-2' variant='outline-primary btn-lg' onClick={e => history.push("/patient-enroll")} style={{ width: '90%' }}>
                                                        <span className='btn-icon-left text-primary' style={{ background: 'none' }}>
                                                            <i className='fa fa-id-card' />
                                                        </span>
                                                        <span style={{ textTransform: 'uppercase' }}>{t("patient.not-insured-number")}</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </div >

        </>
    )
};
const mapStateToProps = (state) => {
    return {
        currentUser: currentUser(state)
    };
};
export default withRouter(connect(mapStateToProps)(PreEnroll));