import React from 'react'
import logoActiva from "../../../../images/logoa1.png"
import PieChart from '../chart/PieChart';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import HorizontalBarChart from '../chart/HorizontalBarChart';
import HorizontalBarChartNew from '../chart/HorizontalBarChartNew';
import PieChartNew from '../chart/PieChartNew';



export default function ExportRapport(props) {

    const { t } = useTranslation();
    const { dataSeries, dataLabels, typeRapport, data,
        ref, clientIds, dateMin, dateMax, clientName, nomRapport, headerGroup, dataAmbuLabels, dataAmbuSeries
        , dataHospiSeries, statsDatas, dataHospiLabels, dt, type } = props;

    console.log(dataHospiLabels);
    console.log(dataHospiSeries);
    console.log(dataAmbuSeries);

    function formatDate(dateString) {
        const date = new Date(dateString); // Convertit en objet Date
        const day = String(date.getUTCDate()).padStart(2, "0"); // Jour avec 2 chiffres
        const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Mois (0-11) + 1 avec 2 chiffres
        const year = String(date.getUTCFullYear()).slice(-2); // Année à 2 chiffres
        return `${day}/${month}/${year}`; // Format final
    }
    console.log(formatDate(dateMin))

    const Ligne = ({ nom, chiffre }) => {
        return (
            <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ fontSize: 13, fontWeight: "bolder", width: "5%", color: 'black', marginRight: "0.1em", }}>-</div>
                <div style={{ width: "40%", fontSize: 13, color: "black", marginLeft: "0.5em", marginRight: "0.5em", }}>{nom}</div>
                <div style={{ fontSize: 13, color: "black", marginLeft: "0.5em", marginRight: "0.5em", }}>:</div>
                <div style={{ fontSize: 13, fontWeight: "bolder", width: "30%", color: 'black', marginRight: "0.5em", }}>{chiffre}</div>
            </div>
        )
    }
    return (
        <div ref={ref} style={{ backgroundColor: "white" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", paddingTop: "3em" }}>
                {/**HEADER */}
                <div style={{ display: "flex", flexDirection: "row", width: '100%', justifyContent: "space-between" }}>

                    <div style={{ width: "40.9%", height: 150, alignContent: 'center', alignItems: "center", }}>
                        <img src={logoActiva} style={{ height: "80%", width: "100%", alignSelf: "center", marginLeft: "3em" }} />
                    </div>

                    <div style={{ width: "40.9%", height: 150, alignContent: "end", alignItems: "end", border: "1px solid black", marginRight: "5em", display: 'flex', justifyContent: "space-between", flexDirection: 'column', borderRadius: 5 }}>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>Siège Social : </span> Immeuble Activa, rue Prince de Galles </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>B.P. : </span>  12970 Douala (Cameroun) </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>Tel. : </span>  (+237) 233.50.12.00 - <span style={{ fontWeight: "bolder" }}>Fax : </span> (+237) 233.43.45.72  </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>Site-Internet : </span>  www.group-activa.com/cameroun </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>Email : </span>  activa.assur@group-activa.com </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>        </span>  reclamations@group-activa.com </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12, marginBottom: 2 }}> <span style={{ fontWeight: "bolder" }}>Call center : </span> 8033 (appel gratuit) </div>
                    </div>
                </div>

                {/**BODY */}
                <div style={{ width: "100%" }}>
                    <div style={{ width: "100%", textAlign: "center", alignContent: "center", alignItems: "center", marginTop: "2em" }}>
                        <span style={{ fontSize: 25, color: "black", fontWeight: "bolder", textDecoration: "underline" }}> RAPPORT {clientName} de {formatDate(dateMin)} à {formatDate(dateMax)} </span>
                    </div>

                    <div style={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "3em", paddingLeft: "2em", paddingRight: "2em", paddingTop: "1em", }}>
                        {nomRapport === 'etatGlobal' && <>
                            <div style={{ width: "53%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                                <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "1.5em", marginBottom: "0.5em", }}>1- {typeRapport}</div>

                                <Ligne nom={t("common.total-amount-benefits")} chiffre={data.totalPrestations + " F CFA"} />
                                <Ligne nom={t("common.total-rejected")} chiffre={data.totalRejete + " F CFA"} />
                                <Ligne nom={t("common.total-paid")} chiffre={data.totalPaye + " F CFA"} />
                                <Ligne nom={t("common.total-num-prov")} chiffre={data.nbrePrestataire} />
                                <Ligne nom={t("common.total-num-vis")} chiffre={data.nbreVisite} />
                            </div>
                        </>}
                        {nomRapport === 'etatConsommation' &&
                            <>
                                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                                    <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "1.5em", marginBottom: "0.5em", }}>1- {typeRapport}</div>
                                    <DataTable
                                        value={statsDatas}
                                        showGridlines
                                        headerColumnGroup={headerGroup}
                                        tableStyle={{ minWidth: '50rem' }}
                                        ref={ref}
                                    >
                                        <Column field="statName"></Column>
                                        <Column field="totalMontant"></Column>
                                        <Column field="totalPourcent"></Column>
                                        <Column field="ambuMontant"></Column>
                                        <Column field="ambuPourcent"></Column>
                                        <Column field="hospiMontant"></Column>
                                        <Column field="hospiPourcent"></Column>
                                    </DataTable>
                                </div>
                            </>}
                        {nomRapport === "visitesPrestataires" &&
                            <>
                                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                                    <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "1.5em", marginBottom: "0.5em", }}>1- {typeRapport}</div>
                                    <DataTable
                                        value={statsDatas}
                                        showGridlines
                                        headerColumnGroup={headerGroup}
                                        tableStyle={{ minWidth: '50rem' }}
                                        ref={ref}
                                    >
                                        <Column field="statName"></Column>
                                        <Column field="totalMontant"></Column>
                                        <Column field="totalPourcent"></Column>
                                        <Column field="ambuMontant"></Column>
                                        <Column field="ambuPourcent"></Column>
                                        <Column field="hospiMontant"></Column>
                                        <Column field="hospiPourcent"></Column>
                                    </DataTable>
                                </div>
                            </>}

                        {nomRapport === "depenseRubrique" &&
                            <>
                                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                                    <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "1.5em", marginBottom: "0.5em", }}>1- {typeRapport}</div>
                                    <DataTable
                                        value={statsDatas}
                                        showGridlines
                                        headerColumnGroup={headerGroup}
                                        tableStyle={{ minWidth: '50rem' }}
                                        ref={ref}
                                    >
                                        <Column field="statName"></Column>
                                        <Column field="totalMontant"></Column>
                                        <Column field="totalPourcent"></Column>
                                        <Column field="ambuMontant"></Column>
                                        <Column field="ambuPourcent"></Column>
                                        <Column field="hospiMontant"></Column>
                                        <Column field="hospiPourcent"></Column>
                                    </DataTable>
                                </div>
                            </>}

                        {nomRapport === "topPathologies" &&
                            <>
                                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                                    <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "0.5em", marginBottom: "1.5em", }}>1- {typeRapport}</div>
                                    <DataTable
                                        value={statsDatas}
                                        // style={{marginTop:"5em"}}
                                        showGridlines

                                        headerColumnGroup={headerGroup}
                                        tableStyle={{ minWidth: '50rem', width: '100%', }}
                                        // header={()=> {return(<div style={{marginTop:"5em", height:25}}></div>)}}
                                        ref={dt}
                                    >
                                        <Column field="statName"></Column>
                                        <Column field="totalPrix"></Column>
                                        <Column field="totalMontant"></Column>
                                        <Column field="totalPourcent"></Column>
                                        <Column field="ambuPrix"></Column>
                                        <Column field="ambuMontant"></Column>
                                        <Column field="ambuPourcent"></Column>
                                        <Column field="hospiPrix"></Column>
                                        <Column field="hospiMontant"></Column>
                                        <Column field="hospiPourcent"></Column>
                                    </DataTable>
                                </div>
                            </>}

                        {nomRapport === "topSpecialistes" &&
                            <>
                                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                                    <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "0.5em", marginBottom: "1.5em", }}>1- {typeRapport}</div>
                                    <DataTable
                                        value={statsDatas}
                                        showGridlines
                                        headerColumnGroup={headerGroup}
                                        tableStyle={{ minWidth: '50rem' }}
                                        ref={dt}
                                    >
                                        <Column field="statName"></Column>
                                        <Column field="totalMontant"></Column>
                                        <Column field="totalPourcent"></Column>
                                        <Column field="ambuMontant"></Column>
                                        <Column field="ambuPourcent"></Column>
                                        <Column field="hospiMontant"></Column>
                                        <Column field="hospiPourcent"></Column>
                                    </DataTable>
                                </div>
                            </>}

                        {nomRapport === "topConsommateurs" &&
                            <>
                                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                                    <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "0.5em", marginBottom: "1.5em", }}>1- {typeRapport}</div>
                                    <DataTable
                                        value={statsDatas}
                                        dataKey="id"
                                        showGridlines
                                        headerColumnGroup={headerGroup}
                                        tableStyle={{ minWidth: '50rem' }}


                                        ref={dt}
                                    >
                                        <Column field="statName"></Column>
                                        <Column field="totalMontant"></Column>
                                        <Column field="totalPourcent"></Column>
                                        <Column field="ambuMontant"></Column>
                                        <Column field="ambuPourcent"></Column>
                                        <Column field="hospiMontant"></Column>
                                        <Column field="hospiPourcent"></Column>
                                    </DataTable>
                                </div>
                            </>}
                    </div>

                    {/** GRAPHE */}
                    <div style={{ marginLeft: "2.5em" }}>
                        <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginLeft: "4.8em", marginTop: "1.5em", marginBottom: "0.5em", }}>2- Graphe</div>

                        {nomRapport === 'etatGlobal' && <div style={{ width: "95%", paddingLeft: "5.8em", }}>
                            <PieChartNew series={dataSeries} pieWidth="700" labels={dataLabels} legendPosition="left" />
                        </div>}

                        {nomRapport === 'etatConsommation' && <div style={{ width: "95%", paddingLeft: "5.8em", }}>
                            <PieChartNew series={dataSeries} pieWidth="700" labels={dataLabels} legendPosition="left" />
                        </div>}

                        {
                            nomRapport === "visitesPrestataires" &&
                            <div style={{width:"100%", alignContent:"center", marginLeft:50 }}>
                                <div style={{alignSelf:"center",  marginLeft:50}} >
                                    <div className='col-15'>
                                        <HorizontalBarChartNew dt={dt} pieWidth="700" ref={ref} type="" title={t("common.outpatient-atte")} series={dataAmbuSeries} labels={dataAmbuLabels} height="150" legendPosition="left" />
                                    </div>
                                    <div className='col-15'>
                                        <HorizontalBarChartNew dt={dt} pieWidth="700" ref={ref} type="" title={t("common.admission-hospi")} series={dataHospiSeries} labels={dataHospiLabels} height="150" legendPosition="left" />
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            nomRapport === "depenseRubrique" &&
                            <div style={{width:"100%", alignContent:"center", marginLeft:50 }}>
                                <div style={{alignSelf:"center",  marginLeft:50}} >
                                    <div className='col-15'>
                                        <PieChartNew dt={dt} pieWidth="700" ref={ref} type="" title="Rubriques en Ambulatoire" series={dataAmbuSeries} labels={dataAmbuLabels} height="150" legendPosition="left" />
                                    </div>
                                    <div className='col-15'>
                                        <PieChartNew dt={dt} pieWidth="700" ref={ref} type="" title="Rubriques en Hospitalisation" series={dataHospiSeries} labels={dataHospiLabels} height="150" legendPosition="left" />
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            nomRapport === "topPathologies" &&
                            <div style={{width:"100%", alignContent:"center", marginLeft:50 }}>
                                <div style={{alignSelf:"center",  marginLeft:50}} >
                                    <div className='col-15'>
                                        <PieChartNew dt={dt} pieWidth="700" type='' title={t("common.section-outpatient")} series={dataAmbuSeries} labels={dataAmbuLabels} height="150" legendPosition="left" />
                                    </div>
                                    <div className='col-15'>
                                        <PieChartNew dt={dt} title={t("common.section-hospi")} type='' series={dataHospiSeries} pieWidth="700" labels={dataHospiLabels} legendPosition="left" />
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            nomRapport === "topSpecialistes" &&
                            <div style={{width:"100%", alignContent:"center", marginLeft:50 }}>
                                <div style={{alignSelf:"center",  marginLeft:50}} >
                                    <div className='col-15'>
                                        <HorizontalBarChartNew dt={dt} pieWidth="700" type='' title={t("common.outpatient-atte")} series={dataAmbuSeries} labels={dataAmbuLabels} height="150" legendPosition="left" />
                                    </div>
                                    <div className='col-15'>
                                        <HorizontalBarChartNew dt={dt} title={t("common.admission-hospi")} type='' series={dataHospiSeries} pieWidth="700" labels={dataHospiLabels} legendPosition="left" />
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            nomRapport === "topConsommateurs" &&
                            <div style={{width:"100%", alignContent:"center", marginLeft:50 }}>
                                <div style={{alignSelf:"center",  marginLeft:50}} >
                                    <div className='col-25'>
                                        <HorizontalBarChartNew dt={dt} pieWidth="700" type={type} title={t("common.outpatient-atte")} series={dataAmbuSeries} labels={dataAmbuLabels} height="450" legendPosition="left" />
                                    </div>
                                    <div className='col-15'>
                                        <HorizontalBarChartNew dt={dt} type={type} title={t("common.hospi-cons")} series={dataHospiSeries} pieWidth="700" labels={dataHospiLabels} legendPosition="left" />
                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                </div>

            </div>
        </div>
    )
}
