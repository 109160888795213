import React from "react";

import ReactApexChart from "react-apexcharts";

const PieChart = (props) => {
    const {series, pieWidth, labels, legendPosition} = props;

    const options = {

        chart: {
            width: pieWidth,
            type: 'pie',
        },
        legend: {
            position: legendPosition
        },
        
        labels: labels,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: legendPosition
                }
            }
        }]
    };

    return (
        <div className="row justify-content-center" style={{ display: "flex", gap: "300px" }}>
  <div id="chart">
    <ReactApexChart options={options} series={series} type="pie" width={pieWidth} />
  </div>
  <div id="html-dist"></div>
</div>
    );
}

export default PieChart;