import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap"; // Assuming Bootstrap is used
import { connect, useDispatch, useSelector } from 'react-redux';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Images

import { useTranslation } from "react-i18next";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../charts/apexcharts/Bar3"
import "./synchronisation.css"
// import actions
import { getSynchronisationContractAction,getSynchronisationListContractAction,getSynchronisationDetailContractAction } from '../../../store/actions/ContratActions';
/// Scroll

// Inline styles for the icon and the animation keyframes
const styles = {
   notificationIcon: {
     fill: 'yellow',
     transition: 'transform 0.3s ease-in-out'
   },
   // We create a keyframes style object if using a CSS-in-JS solution,
   // otherwise, you need to add these keyframes to your CSS file.
   '@keyframes pulse': {
     '0%': { transform: 'scale(1)' },
     '50%': { transform: 'scale(1.2)' },
     '100%': { transform: 'scale(1)' }
   },
   animated: {
     animation: 'pulse 1s infinite'
   }
   
 };

const Synchronisation = (props) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [showModal, setShowModal] = useState(false);
   const [selectedData, setSelectedData] = useState([]);
 
   // Function to open the modal and set the selected row data
   const handleShowModal = (data) => {
      // get data from server
      dispatch(getSynchronisationDetailContractAction(data.sessionId));
   };
   useEffect(() => {
      if (props.dataSynchroDetail) {
         setSelectedData(props.dataSynchroDetail);
         downloadLogData();
      }
   }, [props.dataSynchroDetail]);
   const [data, setData] = useState({ comparisons: [], latestSyncLog: [] });
   useEffect(() => {
      setSelectedData([]);
      dispatch(getSynchronisationContractAction());
      dispatch(getSynchronisationListContractAction());
   }, []);


   const downloadLogData = () => {
      // Pretty-print the log data
      selectedData.dataJson = JSON.stringify(selectedData.dataJson, null, 2);

      const formattedData = JSON.stringify(selectedData, null, 2);

      const blob = new Blob([formattedData], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      console.log(formattedData)
      // Convert the createdAt date to a readable format (YYYY-MM-DD)
      //const createdAt = new Date(selectedData.createdAt);
      //const dateString = createdAt.toISOString().split('T')[0];
      
      // Build the file name with log id and the date
      //const fileName = `log_${selectedData.id}_${dateString}.json`;
      const fileName = `log.json`;
    
      // Create a temporary <a> element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      
      // Clean up by removing the element and revoking the URL
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };
    
   useEffect(() => {
      // Fetch the comparison data when the component mounts.
      const data = {
         "comparisons": props.dataMonitoring,
         "latestSyncLog": props.dataSynchroList
      };
      console.log(props.dataSynchroList)   
      const fetchComparisonData = async () => {
         setData(data);
      };

      fetchComparisonData();
   }, [props.dataMonitoring,props.dataSynchroList]);
   const { comparisons, latestSyncLog } = data;
   // Extract comparison data for each table.
   const beneficiaires = comparisons.find((item) => item.table === 'Beneficiaires');
   const polices = comparisons.find((item) => item.table === 'Polices');
   const clients = comparisons.find((item) => item.table === 'Clients');

   // A helper function to format the difference text.
   const renderDifference = (diff) => {
      // Show a plus if Oracle has more records; minus if SQL Server has more.
      const sign = diff > 0 ? '+' : '';
      return (
         <div className="difference-text" style={{ fontSize: '20px', fontWeight: '600', marginTop: '5px' }}>
            Difference: {sign}{diff}
         </div>
      );
   };
   const difference = polices ? polices.orass - polices.acare : 0;
  const animate = difference !== 0;
   return (
      <div>
         <div className="form-head d-flex mt-5 mb-md-4 align-items-start">
            <div className="mr-auto d-none d-lg-block">
               <h3 className="text-black font-w600" >{t('synchro.title')}</h3>
            </div>
            <div className="input-group search-area ml-auto d-inline-flex">
               <h3 className="text-black font-w600" ><Link variant="primary" className="btn btn-primary btn-sm" to="/metric/police-diff"> Liste polices</Link>
               </h3>
            </div>
         </div>

         <div className="row">

            {/* Card for Polices */}
            <div className="col-xl-4 col-lg-6 col-sm-12 mb-4">
               <div className="card gradient-bx text-white bg-danger rounded">
                  <div className="card-body">
                     <div className="media align-items-center">
                        <div className="media-body">
                           <h4 className="mb-2 text-white">{t('common.polices')}</h4>
                           <div className="d-flex align-items-baseline">
                              <div className="mr-3">
                                 <div className="fs-20">ACARE: <strong>{polices ? polices.acare : 0}</strong></div>
                                 <div className="fs-20">ORASS: <strong>{polices ? polices.orass : 0}</strong></div>
                              </div>
                              {renderDifference(polices ? polices.acare - polices.orass : 0)}
                           </div>
                        </div>
                        {polices && (polices.orass - polices.acare !== 0) && (
                        <span>
                           <Link to="/metric/police-diff">
                              <svg
                              width={34}
                              height={34}
                              viewBox="0 0 24 24"
                              fill="yellow"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                 ...styles.notificationIcon,
                                 ...(animate ? { animation: 'pulse 1s infinite' } : {})
                              }}
                              >
                              <path
                                 d="M12 2C10.343 2 9 3.343 9 5v1.757C7.718 7.42 7 8.663 7 10v5l-2 2v1h14v-1l-2-2v-5c0-1.337-.718-2.58-2-3.243V5c0-1.657-1.343-3-3-3zM12 22c1.104 0 2-.896 2-2H10c0 1.104.896 2 2 2z"
                                 fill="yellow"
                              />
                              </svg>
                           </Link>
                        </span>
                        )}


                     </div>
                  </div>
               </div>
            </div>

            {/* Card for Beneficiaires */}
            <div className="col-xl-4 col-lg-6 col-sm-12 mb-4">
               <div className="card gradient-bx text-white bg-success rounded">
                  <div className="card-body">
                     <div className="media align-items-center">
                        <div className="media-body">
                           <h4 className="mb-2 text-white">{t('common.beneficiaires')}</h4>
                           <div className="d-flex align-items-baseline">
                              <div className="mr-3">
                                 <div className="fs-20">ACARE: <strong>{beneficiaires ? beneficiaires.acare : 0}</strong></div>
                                 <div className="fs-20">ORASS: <strong>{beneficiaires ? beneficiaires.orass : 0}</strong></div>
                              </div>
                              {renderDifference(beneficiaires ? beneficiaires.acare - beneficiaires.orass : 0)}
                           </div>
                        </div>
                        {beneficiaires && (beneficiaires.orass - beneficiaires.acare !== 0) && (
                        <span>
                           {/* Notification (bell) icon with conditional animation */}
                           <Link to="/metric/police-diff">
                           <svg
                              width={34}
                              height={34}
                              viewBox="0 0 24 24"
                              fill="yellow"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                              ...styles.notificationIcon,
                              ...(animate ? { animation: 'pulse 1s infinite' } : {})
                              }}
                           >
                              <path
                              d="M12 2C10.343 2 9 3.343 9 5v1.757C7.718 7.42 7 8.663 7 10v5l-2 2v1h14v-1l-2-2v-5c0-1.337-.718-2.58-2-3.243V5c0-1.657-1.343-3-3-3zM12 22c1.104 0 2-.896 2-2H10c0 1.104.896 2 2 2z"
                              fill="yellow"
                              />
                           </svg>
                           </Link>
                        </span>
                        )}
                     </div>
                  </div>
               </div>
            </div>

            {/* Card for Clients */}
            <div className="col-xl-4 col-lg-6 col-sm-12 mb-4">
               <div className="card gradient-bx text-white bg-info rounded">
                  <div className="card-body">
                     <div className="media align-items-center">
                        <div className="media-body">
                           <h4 className="mb-2 text-white">{t('common.clients')}</h4>
                           <div className="d-flex align-items-baseline">
                              <div className="mr-3">
                                 <div className="fs-20">ACARE: <strong>{clients ? clients.acare : 0}</strong></div>
                                 <div className="fs-20">ORASS: <strong>{clients ? clients.orass : 0}</strong></div>
                              </div>
                              {renderDifference(clients ? clients.acare - clients.orass : 0)}
                           </div>
                        </div>
                        {clients && (clients.orass - clients.acare !== 0) && (
                        <span>
                           {/* Notification (bell) icon with conditional animation */}
                           
                           {/* <svg
                              width={34}
                              height={34}
                              viewBox="0 0 24 24"
                              fill="yellow"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                              ...styles.notificationIcon,
                              ...(animate ? { animation: 'pulse 1s infinite' } : {})
                              }}
                           >
                              <path
                              d="M12 2C10.343 2 9 3.343 9 5v1.757C7.718 7.42 7 8.663 7 10v5l-2 2v1h14v-1l-2-2v-5c0-1.337-.718-2.58-2-3.243V5c0-1.657-1.343-3-3-3zM12 22c1.104 0 2-.896 2-2H10c0 1.104.896 2 2 2z"
                              fill="yellow"
                              />
                           </svg> */}
                        </span>
                        )}
                     </div>
                  </div>
               </div>
            </div>


            <div className="col-xl-12">
               <div className="card ">
                  <div className="card-header border-0 pb-0">
                     <h3 className="fs-20 mb-0 text-black">
                        {t('synchro.Last-synchro')}
                     </h3>
                     <div className="input-group search-area ">
                        <input
                           type="text"
                           className="form-control"
                           placeholder={t('dashboard.search')}
                        />
                        <div className="input-group-append">
                           <button type="button" className="input-group-text">
                              <i className="flaticon-381-search-2" />
                           </button>
                        </div>
                     </div>
                  </div>
                  <div className="card-body">
                     <div className="assigned-doctor owl-carousel">
                        <table
                           id="example5"
                           className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                           role="grid"
                           aria-describedby="example5_info"
                        >
                           <tr role="row">
                              <td >{t('synchro.last-sync-time')}</td>
                              <td >{t('synchro.table-name')}</td>
                              <td >{t('synchro.message')}</td>
                              <td >{t('synchro.action')}</td>
                           </tr>
                           {latestSyncLog?.map((data, i) => (
                              <tr className="items" role="row">
                                 <td className="text-center">
                                    {data.lastSyncTime} <i className="las la-star" />
                                 </td>
                                 <td>
                                    <span className="text-primary mb-2 d-block">
                                       {data.tableName}
                                    </span>
                                 </td>
                                 
                                 <td>
                                    <span className="text-primary mb-2 d-block">
                                       {data.message}
                                    </span>
                                 </td>
                                 <td>
                                 <button className="btn btn-primary btn-sm" onClick={() => handleShowModal(data)}>
                      Detail
                    </button>
                                 </td>
                              </tr>
                           ))}
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => {
   return {
      dataMonitoring: state.contrat.dataMonitoring,
      dataSynchroList: state.contrat.dataSynchroList,
      dataSynchroDetail: state.contrat.dataSynchroDetail,
      errorMessage: state.contrat.errorMessage,
      successMessage: state.contrat.successMessage,
      showLoading: state.contrat.showLoading,
   };
};

export default connect(mapStateToProps)(Synchronisation);
