import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { addProviderSpecialities } from '../../../../store/actions/ProviderActions';
import { Button, Modal } from "react-bootstrap";
import SelectSearch from "react-select-search";
import '../../Clients/Styles/selectStyle.css';
import { useTranslation } from "react-i18next";

export const SpecialitiesModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { providerRef, specialities, manageSpecModal, history } = props;
    const [specialites, setSpecialites] = useState(providerRef.specialites.map(spec => {return spec.specialiteId}));
    const [error, setError] = useState(null);

    

    const saveProviderSpecialities = () => {
        if(specialites.length === 0) {
            setError(t("common.must-choose-spe"));
            return;
        }

        const data = [{specialiteId: specialites, providerId: providerRef.id}];

        dispatch(addProviderSpecialities(data, history));
        manageSpecModal(false, true);

    }

    return (
            <>
                    <Modal.Header>
                        <Modal.Title>{providerRef.specialites.length > 0 ? t("provider.modif-speciality") : t("provider.creation-speciality")}</Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageSpecModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        { error && <small className="text-danger">{{error}}</small> }
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-12 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.choose-speciality")}</strong>
                                </label>
                                <SelectSearch
                                    className="select-search"
                                    options={specialities?.map((opt)=>{return {name:opt.nameFr, value:opt.id}})}
                                    //filterOptions={handleCityFilter}
                                    value={specialites}
                                    name="Specialite"
                                    placeholder={t("provider.choose-speciality")}
                                    required
                                    search
                                    //multiple
                                    onChange={setSpecialites}
                                />
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={() => manageSpecModal(false, false)}
                        >
                           {t('common.cancel')}
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn btn-primary"
                            disabled={specialites.length === 0}
                            onClick={() => saveProviderSpecialities()}
                        >
                           {t('common.save')}
                        </Button>
                    </Modal.Footer>
            </>
    )
};

export default SpecialitiesModal;