import React from 'react';
import { useTranslation } from 'react-i18next';

const RapportDepense = ({ data }) => {
    const {t}= useTranslation()
    const handlePrint = () => {
        window.print();
      };
      const celluleStyle = {
        width: '100px', // Définissez la largeur souhaitée
      };
      const celluleStyleWidth50 = {
        width: '50px', // Définissez la largeur souhaitée
      };
      const celluleActeStyle = {
        width: '120px' // Définissez la largeur souhaitée
      };
      const tableBorder = {
            border: '1px #000' /* Augmenter la taille de la bordure */
      }
    return (
        <div className='print-section'>
                  {/* ... */}
                  <style>
  {`
              table {
                width: 100%;
                /*border-collapse: collapse;*/
                table-layout: fixed;
              }
  
              .header-text {
                padding: 0.5em;
                font-size: 12px;
                border: 1px solid #e4e8ed;
              }
  
              th {
                background-color: #f0f0f0;
              }
  `}
</style>

            {/* <h2>Informations générales sur le souscripteur</h2> */}
            <div className='row print-section'>
                <ul className='col-6'>
                    <li><strong>{t('dashboard.souscipt-name')} :</strong> {data.souscripteur}</li>
                    <li><strong>{t("common.insurer")} :</strong> {data.assureur}</li>
                    <li><strong>{t("common.internal-num")} :</strong> {data.numeroInterne}</li>
                    <li><strong>{t("common.numpolice")} :</strong> {data.numeroPolice}</li>
                </ul>
                <ul className='col-6'>
                    <li><strong>{t('common.startdate')} :</strong> {data.dateDebut}</li>
                    <li><strong>{t('common.enddate')} :</strong> {data.dateFin}</li>
                </ul>
            </div>
            <table className='table  table-striped print-section'>
                        <tbody style={tableBorder}>
                            <tr>
                                <th className='header-text' style={celluleStyle}>{t("common.date-care")}</th>
                                <th className='header-text' style={celluleActeStyle}>{t('provider.acte')}</th>
                                <th className='header-text' style={celluleStyle}>{t("common.amount-claimed")}</th>
                                <th className='header-text' style={celluleStyle}>{t("common.reimb-basis")}</th>
                                <th className='header-text' style={celluleStyleWidth50}>TM (%)</th>
                                <th className='header-text' style={celluleStyleWidth50}>TM</th>
                                <th className='header-text' style={celluleStyle}>{t("common.amount-refunded")}</th>
                                <th className='header-text'style={celluleStyle}>{t("common.amount-no-refunded")}</th>
                            </tr>
                        </tbody>
                    </table>
            {data?.assurers?.map((assurer, index) => (
                <div key={index}>

                    <table className='table print-section'>
                        <tbody>
                            <tr >
                                <td><strong>{t("common.assure-name")} :</strong> {assurer.nomAssurer}</td>
                                <td><strong>{t("common.num-assure")} :</strong> {assurer.numeroAssurer}</td>
                                <td><strong>{t("patient.beneficiary-name")} :</strong> {assurer.nomBeneficiaire}</td>
                                <td><strong>{t("patient.beneficiary-num")} :</strong> {assurer.numeroBeneficiaire}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className='table table-bordered table-striped print-section'>

                        <tbody>
                            {assurer.prestations.map((prestation, index) => (
                                <tr key={index}>
                                    <td style={celluleStyle}>{prestation.dateSoins}</td>
                                    <td style={celluleActeStyle}>{prestation.acte}</td>
                                    <td style={celluleStyle}>{prestation.montantPrestation}</td>
                                    <td style={celluleStyle}>{prestation.baseRemboursement}</td>
                                    <td style={celluleStyleWidth50}>{prestation.tmPoucentage}</td>
                                    <td style={celluleStyleWidth50}>{prestation.tmMontant}</td>
                                    <td style={celluleStyle}>{prestation.montantPayer}</td>
                                    <td style={celluleStyle}>{prestation.montantNonPayer}</td>
                                </tr>
                            ))}
                                <tr>
                                    <td style={celluleStyle}></td>
                                    <td style={celluleActeStyle}>S/Total {t("common.beneficiary")}</td>
                                    <td style={celluleStyle}>{assurer.total.montantPrestation}</td>
                                    <td style={celluleStyle}>{assurer.total.baseRemboursement}</td>
                                    <td style={celluleStyleWidth50}>{assurer.total.tmPoucentage}</td>
                                    <td style={celluleStyleWidth50}>{assurer.total.tmMontant}</td>
                                    <td style={celluleStyle}>{assurer.total.montantPayer}</td>
                                    <td style={celluleStyle}>{assurer.total.montantNonPayer}</td>
                                </tr>
                        </tbody>

                    </table>

                </div>
            ))}
            <table className='table table-bordered table-striped .table-fixed print-section'>
                        <tbody>
                        <tr>
                                    <td style={celluleStyle}> </td>
                                    <td style={celluleActeStyle}>{t('common.total')} {t('common.family')}</td>
                                    <td style={celluleStyle}>{data?.total?.montantPrestation}</td>
                                    <td style={celluleStyle}>{data?.total?.baseRemboursement}</td>
                                    <td style={celluleStyleWidth50}>{data?.total?.tmPoucentage}</td>
                                    <td style={celluleStyleWidth50}>{data?.total?.tmMontant}</td>
                                    <td style={celluleStyle}>{data?.total?.montantPayer}</td>
                                    <td style={celluleStyle}>{data?.total?.montantNonPayer}</td>
                                </tr>
                        </tbody>
                    </table>
                    <button onClick={handlePrint} className='btn btn-primary btn-sm'> {t('common.print')}</button>
        </div>
    );
};

export default RapportDepense;
