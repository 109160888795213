import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useDispatch } from 'react-redux';
import { getSinistresAction, getSinistresByPeriodAction, startLoader } from "../../../store/actions/SinistreAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Spinner } from "react-bootstrap";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from "primereact/tooltip";
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import Select from 'react-select';
import{withRouter} from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import DetailsModal from "./DetailsModal";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { classNames } from 'primereact/utils';

export const Sinistres = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const dataTableRef = useRef();
    const [showDetailsTab, setShowDetailsTab] = useState(false);
    const [dataDetails, setDataDetails] = useState(null);
    const [dataDetailsText, setDataDetailsText] = useState('');

    const [searchMode, setSearchMode] = useState(false);
    var date = new Date();
    const [periodeDebut, setPeriodeDebut] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [periodeFin, setPeriodeFin] = useState(new Date());

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numSinistre: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            numPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            numeroClient: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            numeroBeneficiaire: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            montantEvaluation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            montantRegle: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            montantRecours: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            numeroQuittance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            isRecours: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dateDeclaration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateSurvenance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        }
    });


    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numSinistre: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            numPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            numeroClient: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            numeroBeneficiaire: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            montantEvaluation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            montantRegle: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            montantRecours: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            numeroQuittance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            isRecours: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dateDeclaration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateSurvenance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        dispatch(startLoader());
        dispatch(getSinistresAction(1, 10, value));
        return;
    };

    const setColumns = () => {
        return [
            {name: 'numSinistre', headerName: "Numéro sinistre", filterPlaceholder: "Filtrer par numéro de sinistre", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'numPolice', headerName: "Numéro police", filterPlaceholder: "Filtrer par numéro de police", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'numeroClient', headerName: "Numéro client", filterPlaceholder: "Filtrer par numéro de client", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'montantEvaluation', headerName: "Montant Evaluation", dataType: 'numeric', filterPlaceholder: "Filtrer par montant d'évaluation", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'montantRegle', headerName: "Montant Réglé", dataType: 'numeric', filterPlaceholder: "Filtrer par montant réglé", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'montantRecours', headerName: "Montant Recours", dataType: 'numeric', filterPlaceholder: "Filtrer par montant recours", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'numeroQuittance', headerName: "Numéro quitance", filterPlaceholder: "Filtrer par numéro de quitance", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'isRecours', headerName: 'Avec recours', dataType: 'boolean', bodyClassName:"text-center", filterPlaceholder: "Filtrer par recours", style: { minWidth: '6rem' }, filter: true, bodyTemplate: statusRecoursBodyTemplate, filterElement: statusRecoursFilterTemplate},
            {name: 'dateDeclaration', filterField: 'dateDeclaration', dataType: 'date', headerName: "Date de déclaration", filterPlaceholder: "Filtrer par date de déclaration", style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateEffBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
            {name: 'dateSurvenance', filterField: 'dateSurvenance', dataType: 'date', headerName: "Date de survenance", filterPlaceholder: "Filtrer par date de survenance", style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateExpBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
        ];
    };

    const statusRecoursBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.isRecours, 'text-red-500 pi-times-circle': !rowData.isRecours })}></i>;
    };

    const statusRecoursFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    Avec Recours
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>

            <Tooltip target=".btn-danger" position="top" />
                {hasMenu('MENMONITORING') && canDo('MENMONITORING', 'VIEWMONITORINGDETAIL') && <button className="btn btn-danger shadow btn-xs sharp mr-2" data-pr-tooltip={t("common.view")}
                    onClick={(e) => {
                        setDataDetails(rowData);
                        setDataDetailsText('Infos sinistre '+rowData.numSinistre);
                        setShowDetailsTab(true);
                        return;
                    }}
                >
                    <i className="fa fa-eye"></i>
                </button>}
        </div>
    }

    const manageDetailsModal = () => {
        setShowDetailsTab(false);
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateEffBodyTemplate = (rowData) => {
        return formatDate(rowData.dateDeclaration);
    };

    const dateExpBodyTemplate = (rowData) => {
        return formatDate(rowData.dateSurvenance);
    };


    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const formatDateReq = (value) => {
        return value.toISOString().split('T')[0];
    }

    const onGoToPage = (event) => {
        setLazyState(event)
        return;
    }

    const onSortData = (event) => {
        setContratLazyState(event)
        return;
    }

    useEffect(() => {
        if(lazyState.page) {
            dispatch(startLoader());
            dispatch(getSinistresAction(lazyState.page + 1, lazyState.rows)); 
        }else if(lazyState.sortField && lazyState.sortOrder) {
            // Get the filtered data from the client side.
            const sortedData = props.allContrats.data.sort((a, b) => {
                return lazyState.sortOrder === 1 ? a[lazyState.sortField] - b[lazyState.sortField] : b[lazyState.sortField] - a[lazyState.sortField];
            });
            // Update the data table with the filtered data.
            //dataTableRef.current?.updateData(filteredData);
        }
    }, [lazyState]);

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }
    
    useEffect(() => {
        if(hasMenu('MENMONITORING') && canDo('MENMONITORING', 'LISTMONITORING')){
            initFilters();
            dispatch(startLoader());
            dispatch(getSinistresAction(1, 10));
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    

    const getCurrentData = () => {
        return props.sinistres.data ? props.sinistres.data.map(sinistre => {
            sinistre.dateDeclaration = new Date(sinistre.dateDeclaration);
            sinistre.dateSurvenance = new Date(sinistre.dateSurvenance);
            sinistre.recoursText = sinistre.isRecours ? "Oui" : "Non";
            return sinistre;
        }) : [];
    }

    const getExportColumns = () => {
        return [
            { title: "Numéro sinistre", dataKey: 'numSinistre' },
            { title: "Numéro police", dataKey: 'numPolice' },
            { title: "Numéro client", dataKey: 'numeroClient' },
            { title: "Numéro bénéficiaire", dataKey: 'numeroBeneficiaire' },
            { title: "Montant évaluation", dataKey: 'montantEvaluation' },
            { title: "Montant réglé", dataKey: 'montantRegle' },
            { title: "Montant Recours", dataKey: 'montantRecours' },
            { title: "Avec Recours", dataKey: 'recoursText' },
            { title: "Date de déclaration", dataKey: 'dateDeclaration' },
            { title: "Date de survenance", dataKey: 'dateSurvenance' },
        ];
    }

    const getCurrentLazyState = () => {
        return lazyState;
    }

    const getCurrentTotalDataState = () => {
        return props.sinistres?.totalPages;
    }
    
    const getCurrentFilters = () => {
        return filters;
    }

    const filterSinistres = (e) => {
        e.preventDefault();
        setSearchMode(true);
        dispatch(startLoader());
        dispatch(getSinistresByPeriodAction(formatDateReq(periodeDebut), formatDateReq(periodeFin)))
    }

    const cancelSearch = (e) => {
        e.preventDefault();
        setSearchMode(false);
        setPeriodeDebut(new Date());
        setPeriodeFin(new Date());
    }

    return (
        <Fragment>

            <form className="row justify-content-end" style={{
                alignItems: "center",
                padding: "10px",
                backgroundColor: "white",
                margin: 0
            }} onSubmit={filterSinistres}>
                <div className="col-lg-3 mb-2">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Date minimum</strong>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="dd/MM/yyyy"
                                disableFuture
                                value={periodeDebut}
                                onChange={setPeriodeDebut}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="col-lg-3 mb-2">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Date maximum</strong>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="dd/MM/yyyy"
                                disableFuture
                                value={periodeFin}
                                onChange={setPeriodeFin}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="col-lg-1 mb-2">
                    { props.showLoading && 
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                    }
                    { !props.showLoading && <>
                        <button type='submit' className='btn btn-primary'>
                            <i className='la la-search'></i>
                        </button>
                    </>}
                </div>
                <div className="col-lg-1 mb-2">
                    { !props.showLoading && <>
                        <button type='button' onClick={e => cancelSearch(e)} className='btn btn-danger'>
                            <i className='la la-close'></i>
                        </button>
                    </>}
                </div>
            </form>

            <div className="row">
                <div data-testid='data-table-base' className="col-xl-12">
                    {searchMode && <DataTableBase 
                            data={props.sinistresByPeriode ? props.sinistresByPeriode.map(sinistre => {
                                sinistre.dateDeclaration = new Date(sinistre.dateDeclaration);
                                sinistre.dateSurvenance = new Date(sinistre.dateSurvenance);
                                sinistre.recoursText = sinistre.isRecours ? "Oui" : "Non";
                                return sinistre;
                            }) : []}
                            emptyMessage="Aucun sinistre trouvé" 
                            filters={getCurrentFilters()}
                            globalFiltersFields={['numSinistre', 'numPolice', 'numeroClient']}
                            header={renderHeader()}
                            columns={setColumns()}
                            exportColumns={getExportColumns()}
                            rows={10} 
                            showExport={true}
                            exportFilename={"sinistres"}
                            loading={props.showLoading}
                    />}
                    {!searchMode && <DataTableBase 
                        data={getCurrentData()}
                        emptyMessage="Aucun sinistre trouvé"
                        filters={getCurrentFilters}
                        header={renderHeader()}
                        columns={setColumns()}
                        exportColumns={getExportColumns()}
                        rows={10} 
                        showExport={true}
                        exportFilename="sinistres"
                        loading={props.showLoading}
                        lazy={true}
                        dataSet={getCurrentLazyState()}
                        totalRecords={getCurrentTotalDataState()}
                        onGoToPage={onGoToPage}
                        onSortData={onSortData}
                        removableSort={true}
                    />}
                    
           
                    <Modal
                        className="fade bd-example-modal-lg"
                        show={showDetailsTab}
                        size="lg"
                    >
                        <DetailsModal 
                            showModal={showDetailsTab}
                            data={dataDetails}
                            headerText={dataDetailsText}
                            manageDetailsModal={manageDetailsModal}
                        />
                    </Modal>
                </div>
            </div>

        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        sinistres: state.sinistre.sinistres,
        sinistresByPeriode: state.sinistre.sinistresByPeriode,
        showLoading: state.sinistre.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(Sinistres));