import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { listParamIdentAction, 
    startLoader, 
    mapParamIdenttAction, 
    updateParamIdentMappingAction, 
    deleteParamIdentAction 
} from '../../../store/actions/ParamerterAction';
import swal from 'sweetalert';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from '../DataTables/DataTableBase';
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";


const ParametrageIdentification = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { acte: '', code: '' };
    const [id, setId] = useState('');
    const [status, setStatus] = useState(false);
    const [typeIdent, setTypeIdent] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    //Modal box
    const [addCard, setAddCard] = useState(false);

    const [filters, setFilters] = useState(null);
    const [updateMode, setUpdateMode] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const identTypes = [
        {label: t('common.facial-ident'), value: "reconnaissance-faciale"},
        {label: t('common.num-assu'), value: "numero-assure"},
        {label: t('layout.carte-sante'), value: "carte-sante"},
    ]

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            identificationType: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'id', headerName: "Id", style: { minWidth: '12rem' }, filter: false, bodyTemplate: null},
            {name: 'identificationType', headerName: t('common.ident-type'), filterPlaceholder: t('provider.code-act-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'status', headerName: t('common.status'), dataType: 'boolean',  filterPlaceholder: t('common.status-filter-text'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBordBodyTemplate, filterElement: statusBordFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const statusBordFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    {t('facturation.closed')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const statusBordBodyTemplate = (rowData) => {
        return <Tag value={rowData.isClose ? t('facturation.closed') :  t('facturation.oppened')} severity={getSeverity(rowData.isClose)} />;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getSeverity = (status) => {
        switch (status) {
            case null:
                return 'danger';
            
            case 'non payé':
                return 'danger';

            case 'payé':
                return 'success';

            case true:
                return 'success';

            case false:
                return 'warning';

            case t('facturation.closed'):
                return 'success';

            case 'ouvert':
                return 'warning';

            default:
                return 'warning';
        }
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAM') && canDo('MENPARAM', 'UPDATESTANDACT') && <Link href="#" to="#" onClick={e => updateData(rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>
            </Link>}
            {hasMenu('MENPARAM') && canDo('MENPARAM', 'DELETESTANDACT') && <Link href="#" to="#" title="Close"
                onClick={() =>
                    swal({
                        title: t('client.delete-standart-act')+rowData.acteName+" ?",
                        text: t('client.delete-message'),
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            dispatch(startLoader());
                            dispatch(deleteParamIdentAction(rowData.id));
                        }
                    })
                    }>
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    }

    const updateData = (data) => {
        setId(data.id);
        setTypeIdent(data.identificationType);
        setStatus(data.status);
        setAddCard(true);
        setUpdateMode(true);
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        dispatch(startLoader());
        initFilters();
        dispatch(listParamIdentAction());    
    }, []);


    useEffect(() => {
        setUpdateMode(false);
        setTypeIdent("");
        setStatus(false);
        setAddCard(false);  
    }, [props.globalActs]);
    
    function onLettreCleSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (typeIdent === '') {
            errorObj.typeIdent = t('common.acte-error');
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
        dispatch(startLoader());
        

        if(updateMode) {
            let obj = { id, typeIdent, status };
            dispatch(updateParamIdentMappingAction(obj));
        }else{
            let obj = { typeIdent, status };
            dispatch(mapParamIdenttAction(obj));
        }
    }


    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAM') && canDo('MENPARAM', 'CREATESTANDACT') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddCard(true)}
                    >
                        + {t('common.new-ident-param')}
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" show={addCard} onHide={setAddCard} >
            <div className="">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">{t('common.create-ident-param')}</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={onLettreCleSave}>
                                <div className='form-group col-12'>
                                    <label className='mb-1'>{t('common.ident-type')}</label>
                                    <Select
                                        className="select-search"
                                        value={identTypes.filter(elt => elt.value === typeIdent)}
                                        placeholder={t("consultation.choose-type")}
                                        onChange={(val) => setTypeIdent(val.value)}
                                        disabled={props.showLoading}
                                        required={props.showLoading}
                                        options={identTypes}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                                
                                <div className='form-group col-12'>
                                    <label className='mb-1'>{t('common.status')}</label>
                                    <Select
                                        className="select-search"
                                        value={{label: "Activé", value: true}}
                                        options={[{label: "Activé", value: true}, {label: "Désactivé", value: false}]}
                                        placeholder={t('common.choose')}
                                        search
                                        isRequired
                                        onChange={(val) => {
                                            setStatus(val.value);
                                        }}
                                    />
                                </div>
                                <hr />
                                <div className='form-group align-items-center justify-content-center'>
                                    { props.showLoading && 
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            <span className="visually-hidden">{t('common.await-text')}</span>
                                        </Button> }
                                    { !props.showLoading &&
                                        <div className='col-4'>
                                            <button type='submit' className='btn btn-primary'>
                                               {t('common.save')}
                                            </button>
                                        </div> }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
            <DataTableBase 
                data={props.identParams}
                emptyMessage={t('common.no-standart-act')} 
                filters={filters}
                globalFiltersFields={['id', 'identificationType', 'status']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'ID', dataKey: 'id' },
                    { title: t('common.ident-type'), dataKey: 'identificationType' },
                    { title: t('common.status'), dataKey: 'status' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"paramétrage_identification"}
                loading={props.showLoading}
                lazy={false}
            />

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        identParams: state.parameters.identParams,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.parameters.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(ParametrageIdentification);