import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const provicerServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND,
  headers: headers 
});

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

//export const requestProviderAuthToken = () => provicerServiceRequest.get('/provider/requestToken');

export const registerProvider = (data) => provicerServiceRequest.post('/Parameter/provider', data);

export const getAllProviders = () => provicerServiceRequest.get('/Parameter/providers');

export const getProvider = (id) => provicerServiceRequest.get('/Parameter/provider/'+id);

export const updateProviderInfos = (providerId, data) => provicerServiceRequest.put('/Parameter/provider/'+providerId, data);

export const suspendProvider = (providerId) => provicerServiceRequest.delete('/Parameter/provider/'+providerId);

export const getActsFamilies = () => provicerServiceRequest.get('/Parameter/Acte/type-actes');

export const getProviderType = () => provicerServiceRequest.get('/Parameter/provider/types');

export const getProviderSpecialities = () => provicerServiceRequest.get('/Parameter/provider/specialites');

export const registerProviderSpecialities = (data) => provicerServiceRequest.post('/Parameter/provider/specialities', data);

export const getAllTarifs = () => provicerServiceRequest.get('/Parameter/tarifs');

export const setProviderTarification = (data) => provicerServiceRequest.post('/Parameter/tarif/provider/affect', data);

export const setProviderUpdatedTarification = (tarifProviderId, data) => provicerServiceRequest.put('/Parameter/tarif/provider/'+tarifProviderId, data);

export const setProviderNewTarification = (data) => provicerServiceRequest.post('/Parameter/tarif/provider', data);

export const createProviderType = (data) => provicerServiceRequest.post('/Parameter/provider/type', data);

export const updateProviderType = (id, data) => provicerServiceRequest.put('/Parameter/provider/type/'+id, data);

export const createProviderSpeciality = (data) => provicerServiceRequest.post('/Parameter/speciality', data);

export const updateProviderSpeciality = (id, data) => provicerServiceRequest.put('/Parameter/provider/specialites/'+id, data);

export const createLettreCle = (data) => provicerServiceRequest.post('/Parameter/tarif/lettre-cle', data);

export const updateLettreCle = (id, data) => provicerServiceRequest.put('/Parameter/tarif/lettre-cle/'+id, data);

export const createTypeActe = (data) => provicerServiceRequest.post('/Parameter/acte/type', data);

export const updateTypeActe = (id, data) => provicerServiceRequest.put('/Parameter/acte/type-actes/'+id, data);

export const createFamilleActe = (data) => provicerServiceRequest.post('/Parameter/acte/famille', data);

export const updateFamilleActe = (id, data) => provicerServiceRequest.put('/Parameter/acte/famille-actes/'+id, data);

export const createActe = (data) => provicerServiceRequest.post('/Parameter/acte', data);

export const updateActe = (id, data) => provicerServiceRequest.put('/Parameter/acte/'+id, data);

export const createTarif = (data) => provicerServiceRequest.post('/Parameter/tarif', data);

export const createTarifMedicament = (data) => provicerServiceRequest.post('/Parameter/Tarif/pharmacie', data);

export const updateTarif = (id, data) => provicerServiceRequest.put('/Parameter/tarif/'+id, data);

export const updateTarifMedicament = (id, data) => provicerServiceRequest.put('/Parameter/Tarif/pharmacie/'+id, data);

export const createMedicament = (data) => consultationServiceRequest.post('/Pharmacie/medicament/create', data);

export const updateMedicament = (data) => consultationServiceRequest.put('/Pharmacie/medicament/update', data);

export const createParametreOptique = (data) => provicerServiceRequest.post('/Parameter/dictionaire', data);

export const updateParametreOptique = (id, data) => provicerServiceRequest.put('/Parameter/dictionnaire/'+id, data);

export const updateOpticPrizeGrid = (data) => consultationServiceRequest.post('/Optic/tarif/optic', data);

export const createMonture = (data) => provicerServiceRequest.post('/Parameter/monture/create', data);

export const updateMonture = (id, data) => provicerServiceRequest.put('/Parameter/monture/'+id, data);

export const getLettreCle = () => provicerServiceRequest.get('/Parameter/tarif/lettre-cles');

export const getTarifs = () => provicerServiceRequest.get('/Parameter/tarifs');

export const getTarifsMedicaments = () => provicerServiceRequest.get('/Parameter/Tarif/tarif-pharmacie/all');

export const getTypeActe = () => provicerServiceRequest.get('/Parameter/acte/type-actes');

export const getFamilleActe = () => provicerServiceRequest.get('/Parameter/acte/famille-actes');

export const getActes = () => provicerServiceRequest.get('/Parameter/acte');

export const getActesPages = (page, max) => provicerServiceRequest.get('/parameter/Acte/all-actes?PageNumber='+page+'&PageSize='+max);

export const getMedicamentsPages = (page, max) => consultationServiceRequest.get('/Pharmacie/medicaments?PageNumber='+page+'&PageSize='+max);

export const getTypeActeurs = () => provicerServiceRequest.get('/Parameter/acte/acteurs');

export const getGaranties = () => provicerServiceRequest.get('/Parameter/Acte/garanties');

export const getParametreOptique = (type) => provicerServiceRequest.get('/Parameter/dictionaires/'+type);

export const getOpticPrizeGrid = () => consultationServiceRequest.get('/tarif');

export const getMonture = () => provicerServiceRequest.get('/Parameter/monture');

export const registerProviderBank = (data) => provicerServiceRequest.put('/Parameter/provider/payment-account', data);

export const registerProviderMobileMoney = (data) => provicerServiceRequest.post('/Parameter/provider/mobile-payment', data);

export const searchActes = (keyword) => provicerServiceRequest.get('/Parameter/Acte/actes/search?keyword='+keyword);

export const deleteParam = (data) => provicerServiceRequest.post('/Parameter/delete-data', data);

export const deleteParametreOptique = (id) => provicerServiceRequest.delete('/Parameter/dictionaire/'+id);

export const removeTarif = (providerId, tarifId) => provicerServiceRequest.delete('/parameter/Tarif/tarif/'+providerId+'/'+tarifId);

export const removeTarifMedicament = (tarifId) => provicerServiceRequest.delete('/parameter/Tarif/tarif-pharmacie/assigned/'+tarifId);

export const deleteTarifMedicament = (tarifId) => provicerServiceRequest.delete('/parameter/Tarif/tarif-pharmacie/'+tarifId);

export const assignTarifMedicament = (data) => provicerServiceRequest.post('/Parameter/Tarif/tarif-pharmacie/assign', data);

export const getProviderTarifs = (id) => provicerServiceRequest.get('/Parameter/Tarif/tarif/provider/'+id);

export const getProviderTarifsMedicaments = (id) => provicerServiceRequest.get('/parameter/Tarif/tarif-pharmacie/provider/'+id);

export const getTypesActeControls = () => provicerServiceRequest.get('/Parameter/Acte/parameter/control-type/list');

export const createTypesActeControls = (data) => provicerServiceRequest.post('/Parameter/Acte/parameter/control-type', data);

export const deleteTypesActeControls = (id) => provicerServiceRequest.delete('/Parameter/Acte/parameter/control-type/'+id);

export const getActeControls = () => provicerServiceRequest.get('/Parameter/Acte/control-actes-mapping');

export const createActeControls = (data) => provicerServiceRequest.post('/Parameter/Acte/parameter/control-acte-mapping', data);

export const updateActeControls = (id, data) => provicerServiceRequest.put('/Parameter/Acte/parameter/control-acte-mapping/'+id, data);

export const deleteActeControls = (id) => provicerServiceRequest.delete('/Parameter/Acte/parameter/control-acte-mapping'+id);

export const searchActesConsult = (keyword, providerId, type) => provicerServiceRequest.get('/parameter/acte/tarif', {
  params: {
    type,
    providerId,
    keyword
  }
});

export const importProviderTypeFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/provider-type", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importProviderSpecialityFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/provider-speciality", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importLettreCleFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/lettre-cle", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importActeFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/actes", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importMedicamentFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return consultationServiceRequest.post("/Dictionnaire/import/medicaments", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importTarifFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/tarif", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importFamilleActeFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/famille-type-acte", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importTypeActeFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/type-acte", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importPaysFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/country", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importVilleFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/city", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importActiviteFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/activity", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importProfessionFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/profession", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importExclusionFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/exclusion", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const importProvincesFile = (file) => {
  let formData = new FormData();

  formData.append("", file);

  return provicerServiceRequest.post("/Parameter/import/dictionnaire", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

