import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { useReactToPrint } from "react-to-print";
import { Button } from 'primereact/button';
import { Row } from 'primereact/row';
import HorizontalBarChart from '../chart/HorizontalBarChart';
import './EtatsGlobaux.css';
import { usePDF, Margin } from 'react-to-pdf';
import generatePDF from 'react-to-pdf';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ExportRapport from './ExportRapport';
import HorizontalBarChartNew from '../chart/HorizontalBarChartNew';


const EtatsVisitePrestataire = (props) => {
    const { t } = useTranslation();
    const { data, showLoading, clientIds, dateMin, dateMax, clientName } = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media print {
          .p-datatable th, .p-datatable td {
            padding: 8px !important;
            font-size: 10px !important;
          }
        }
      `,
        // Ajoute une entête sur chaque page du PDF
        onBeforeGetContent: () => {
            const doc = document.querySelector('.p-datatable');
            if (doc) {
                // Applique des sauts de page personnalisés et autres styles
                let pageBreaks = document.querySelectorAll('.p-datatable-pagebreak');
                pageBreaks.forEach((breakElement) => breakElement.remove()); // Nettoie les sauts de page existants
                let rows = doc.querySelectorAll('.p-datatable-row');
                for (let i = 15; i < rows.length; i += 15) {
                    let pageBreak = document.createElement('div');
                    pageBreak.classList.add('p-datatable-pagebreak');
                    rows[i].parentNode.insertBefore(pageBreak, rows[i]);
                }
            }
        },
    });
    const dt = useRef(null);

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataAmbuSeries, setDataAmbuSeries] = useState([]);
    const [dataHospiSeries, setDataHospiSeries] = useState([]);
    const [dataAmbuLabels, setDataAmbuLabels] = useState([]);
    const [dataHospiLabels, setDataHospiLabels] = useState([]);
    const [graph, setGraph] = useState(false);

    const [dataAmbuSerie2, setDataAmbuSerie2] = useState([]);
    const [dataHospiSerie2, setDataHospiSerie2] = useState([]);


    const [dataExcel, setDataExcel] = useState([])

    useEffect(() => {
        if (data.length > 0) {
            setStatsDatas(data.map(key => {
                return {
                    statName: key.providerName,
                    totalMontant: key["total"]["nbreVisites"],
                    totalPourcent: parseFloat(key["total"]["taux"]),
                    ambuMontant: key["ambulatoire"]["nbreVisites"],
                    ambuPourcent: parseFloat(key["ambulatoire"]["taux"]),
                    hospiMontant: key["hospitalier"]["nbreVisites"],
                    hospiPourcent: parseFloat(key["hospitalier"]["taux"]),

                }
            }))

            let prestsAmbu = data.filter(elt => elt.ambulatoire.nbreVisites > 0).sort((a, b) => b.ambulatoire.nbreVisites - a.ambulatoire.nbreVisites);
            let prestsHospi = data.filter(elt => elt.hospitalier.nbreVisites > 0).sort((a, b) => b.hospitalier.nbreVisites - a.hospitalier.nbreVisites);

            setDataAmbuSeries([{
                data: prestsAmbu.map(key => {
                    return key["ambulatoire"]["nbreVisites"]
                })
            }]);

            setDataHospiSeries([{
                data: prestsHospi.map(key => {
                    return key["hospitalier"]["nbreVisites"]
                })
            }]);
            setDataAmbuLabels(prestsAmbu.map(key => key.providerName));
            setDataHospiLabels(prestsHospi.map(key => key.providerName));

            setDataHospiSerie2(
            prestsHospi.map(key => {
                    return key["hospitalier"]["nbreVisites"]
                })
            );

            setDataAmbuSerie2(
            prestsAmbu.map(key => {
                    return key["ambulatoire"]["nbreVisites"]
                })
            );
        }
    }, [data]);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header={t("dashboard.providers")} rowSpan={2} />
                <Column header="Total" colSpan={2} />
                <Column header={t("common.ambulatory")} colSpan={2} />
                <Column header={t("common.hospital")} colSpan={2} />
            </Row>
            <Row>
                <Column header={t("common.num")} field="totalMontant" />
                <Column header={t("common.rate")} field="totalPourcen" />
                <Column header={t("common.num")} field="ambuMontant" />
                <Column header={t("common.rate")} field="ambuPourcen" />
                <Column header={t("common.num")} field="hospiMontant" />
                <Column header={t("common.rate")} field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );




    // Fonction pour exporter en Excel
    const transformData = (data) => {
        return data.map(item => ({
            prestataire: item.statName,
            Ambulance_Montant: item.ambuMontant,
            Ambulance_Pourcentage: item.ambuPourcent,
            Hospitalisation_Montant: item.hospiMontant,
            Hospitalisation_Pourcentage: item.hospiPourcent,
            Total_Montant: item.totalMontant,
            Total_Pourcentage: item.totalPourcent
        }));
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {

            const worksheet = xlsx.utils.json_to_sheet(transformData(statsDatas), { skipHeader: false });
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, 'Top_Specialistes_Visités');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    //PDF
    const { toPDF, targetRef } = usePDF({ filename: 'Top_Specialistes_Visités.pdf', page: { margin: Margin.SMALL } })

    //fichier csv
    const exportToCSVe = (datacsv) => {
        const csvRows = [];
        const headers = [
            t("dashboard.providers"),
            t("common.total-num-vis"),
            'Total ' + t("common.rate"),
            t("common.ambulatory") + t("common.num"),
            t("common.ambulatory") + t("common.rate"),
            t("common.hospital") + t("common.num"),
            t("common.hospital") + t("common.rate"),
        ];
        csvRows.push(headers.join(','));

        datacsv.forEach(item => {
            const row = [
                item.providerName,
                item.total.nbreVisites,
                item.total.taux,
                item.ambulatoire.nbreVisites,
                item.ambulatoire.taux,
                item.hospitalier.nbreVisites,
                item.hospitalier.taux
            ];
            csvRows.push(row.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Top_Specialistes_Visités.csv');
        a.click();
    };

    return (
        <>
            {Object.keys(data).length === 0 && <span>
                {t("common.no-data-display")}
            </span>
            }
            {Object.keys(data).length > 0 && <div className='results-section'>
                <div className='row justify-content-center my-4'>
                    <div className='form-group mb-0'>
                        <label className='radio-inline mr-5'>
                            <input type='radio' name='graph' value="hideGraph" checked={!graph} onChange={e => { setGraph(!e.target.checked) }} /> Données
                        </label>
                        <label className='radio-inline'>
                            <input type='radio' name='graph' value="showGraph" checked={graph} onChange={e => { setGraph(e.target.checked) }} /> Graphique
                        </label>
                    </div>
                </div>
                {!graph &&
                    <>
                        <div ref={targetRef}>
                            <DataTable
                                value={statsDatas}
                                showGridlines
                                headerColumnGroup={headerGroup}
                                tableStyle={{ minWidth: '50rem' }}
                                ref={dt}
                            >
                                <Column field="statName"></Column>
                                <Column field="totalMontant"></Column>
                                <Column field="totalPourcent"></Column>
                                <Column field="ambuMontant"></Column>
                                <Column field="ambuPourcent"></Column>
                                <Column field="hospiMontant"></Column>
                                <Column field="hospiPourcent"></Column>
                            </DataTable>
                        </div>
                        <div className="flex align-items-center justify-content-end gap-2">
                            <Button type="button" icon="pi pi-file" rounded onClick={() => exportToCSVe(data)} tooltip="Export CSV" tooltipOptions={{ position: 'top' }} />
                            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} tooltip="Export XLS" tooltipOptions={{ position: 'top' }} />
                            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => handlePrint()} tooltip="Export PDF" tooltipOptions={{ position: 'top' }} />
                        </div>
                    </>
                }
                {graph &&
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{t("common.outpatient-atte")}</h5>
                            <HorizontalBarChart series={dataAmbuSeries} labels={dataAmbuLabels} height="150" />
                        </div>
                        <div className='col-6'>
                            <h5>{t("common.admission-hospi")}</h5>
                            <HorizontalBarChart series={dataHospiSeries} labels={dataHospiLabels} height="150" />
                        </div>
                    </div>
                } 

                <div style={{ marginTop: 100, display: "none" }}>
                    <div style={{ height: "100%", minWidth: '50rem', }} ref={componentRef}>
                        <ExportRapport
                            clientName={clientName}
                            dateMin={dateMin} dateMax={dateMax} nomRapport='topSpecialistes' clientIds={clientIds}
                            data={data} typeRapport="TOP DES SPECIALISTES FREQUENTES" dataSeries=""
                            dataLabels="" ref={componentRef}
                            headerGroup={headerGroup} 
                            statsDatas={statsDatas}
                            dataAmbuSeries={dataAmbuSerie2} 
                            dataAmbuLabels={dataAmbuLabels} 
                            dataHospiSeries={dataHospiSerie2}
                            dataHospiLabels={dataHospiLabels}
                            dt={dt}
                        />
                    </div>
                </div>
            </div>}
        </>
    )
};
export default EtatsVisitePrestataire;