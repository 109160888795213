import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { getRemboursementAction, requestRemboursementAction, getMotifsExclusionAction, startLoader, sendRembPaymentAction } from "../../../../store/actions/FacturationAction";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { format, min } from 'date-fns';
import {
    Dropdown
} from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { render } from "@testing-library/react";
import { Button, Modal } from "react-bootstrap";
import { formatDate } from "@fullcalendar/core";
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";

class RemboursementTemplate extends React.Component {

    state = {
        currentKey: ''
    }


    statusColor = () => {
        return "text-secondary"
    };



    allTotals = (remb) => {
        console.log("fact.benefLis");
        console.log(this.props.theRemb.ligneAssures);
        return {
            exclusions: (remb.montantExclu ? remb.montantExclu : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.montantExclu ? subElt.montantExclu : 0), 0),
            baseRemb: (remb.baseRemboursement ? remb.baseRemboursement : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.baseRemboursement ? subElt.baseRemboursement : 0), 0),
            tm: (remb.tm ? remb.tm : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.tm ? subElt.tm : 0), 0),
            totalRemb: (remb.montantRemb ? remb.montantRemb : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.montantRemb ? subElt.montantRemb : 0), 0),
        }
    }



    render() {
        return (<div style={{ height: "100%" }} className="row">
            <div style={{ height: "100%" }} className="col-lg-12">
                {/*<div className="card-header">
                            {" "}
                            <strong>{this.props.facture.name}</strong>{" "}
                            <span className="float-right">
                            <strong>{this.props.t('common.status')}:</strong> <span className="danger">{this.props.facture.statusPaiement === STATUSES.pending ? this.props.t('statuses.'+this.props.facture.statusPaiement) : this.props.facture.statusPaiement}</span>
                            </span>{" "}
                        </div>
                        <div className="card-body">
                            <h3 className="justify-self-center text-center my-5 p-2 border boder-segondary">Bordereau de remboursement</h3>
                        </div>*/}
                {this.props.theRemb.ligneAssures?.map(fact => {
                    return fact.benefList.map(benef => {
                        return benef.remboursements.map(remb => {
                            return (
                                <div style={{ width: "100%", height: "100%" }} className="card mt-3">
                                    <div className="card-body">

                                        <div style={{ border: " 1px solid black", width: '100%', display: "flex", flexDirection: "column", borderRadius: 5, height: 300, }} >
                                            <label style={{ width: "20%", fontSize: 10, color: "black", alignSelf: "flex-end", marginTop: 5 }}>Edité le {this.props.getFormattedDate}</label>
                                            <label style={{ fontSize: 27, color: 'black', fontWeight: "bold", textAlign: "center", border: " 1px solid black", borderRadius: 5, height: "20%", width: "50%", alignSelf: "center", marginBottom: "1em" }}>Bordereau de Remboursement</label>
                                            <div style={{ height: "80%", width: "100%", display: "flex", flexDirection: "column" }}>

                                                <div style={{ width: "100%", height: "30%", display: "flex", flexDirection: "column", marginBottom: 10 }}>
                                                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black" }}>
                                                        <label style={{ width: "12%", }}> Souscripteur </label>
                                                        <label style={{ width: 1 }}> : </label>
                                                        <label style={{ width: "30%", marginLeft: "2em" }}> {this.props.facture.souscripteurName} </label>
                                                    </div>

                                                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black" }}>
                                                        <label style={{ width: "12%", }}> N° interne </label>
                                                        <label style={{ width: 1 }}> : </label>
                                                        <label style={{ width: "30%", marginLeft: "2em" }}>  {this.props.facture.codeAssu} </label>
                                                    </div>

                                                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black", marginBottom: 20 }}>
                                                        <label style={{ width: "12%", }}> N° Police </label>
                                                        <label style={{ width: 1 }}> : </label>
                                                        <label style={{ width: "30%", marginLeft: "2em" }}> {this.props.facture.numeroPolice} </label>
                                                    </div>
                                                </div>

                                                <div style={{ width: "100%", height: "60%", display: "flex", flexDirection: "row", marginTop: "1.7em", justifyContent: "space-between" }}>
                                                    <div style={{ width: "65%", height: "100%", display: "flex", flexDirection: "column", }}>

                                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black", width: "100%", }}>
                                                            <label style={{ width: "19.2%", }}> Assuré </label>
                                                            <label style={{ width: 1 }}> : </label>
                                                            <div style={{ width: "75%", marginLeft: "2em", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                                                <div style={{ width: "10em", border: "1px solid black", marginRight: "0.5em", textAlign: "center", fontWeight: "bold", height: "2em" }}>{fact.assurePrincId}</div>
                                                                <div>{fact.assurePrincName}</div>
                                                            </div>
                                                        </div>

                                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black", width: "100%", marginTop: "0.1em" }}>
                                                            <label style={{ width: "19.2%", }}> Bénéficiaire </label>
                                                            <label style={{ width: 1 }}> : </label>
                                                            <div style={{ width: "75%", marginLeft: "2em", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                                                <div style={{ width: "10em", border: "1px solid black", marginRight: "0.5em", textAlign: "center", fontWeight: "bold", height: "2em" }}>{benef.assureId}</div>
                                                                <div>{benef.assureName}</div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div style={{ width: "35%", height: "100%", display: "flex", flexDirection: "column", }}>

                                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black", width: "100%", }}>
                                                            <label style={{ width: "45%", }}> N° Remboursement </label>
                                                            <label style={{ width: 1, marginLeft: "0.5em", marginRight: "0.5em" }}> : </label>
                                                            <div style={{ width: "58%", marginLeft: "0em" }}> <div style={{ width: "10em", border: "1px solid black", marginRight: "0.5em", textAlign: "center", fontWeight: "bold", height: "2em" }}>{remb.numRemboursement}</div></div>
                                                        </div>

                                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black", width: "100%", }}>
                                                            <label style={{ width: "45%", }}> Date </label>
                                                            <label style={{ width: 1, marginLeft: "0.5em", marginRight: "0.5em" }}> : </label>
                                                            <div style={{ width: "58%", marginLeft: "0em" }}> <div style={{ width: "10em", border: "1px solid black", marginRight: "0.5em", textAlign: "center", fontWeight: "bold", height: "2em" }}>{format(new Date(this.props.facture.dateFacturation), 'dd/MM/yyyy')}</div></div>
                                                        </div>



                                                    </div>
                                                    <div style={{ width: "5%", height: "100%", display: "flex", flexDirection: "column", }}></div>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="table-responsive">
                                            <table style={{ width: "100%", color: "black", marginTop: 35 }} /*className="table table-striped"*/>
                                                <thead>
                                                    <tr style={{ border: "1px solid black", height: 50 }} >
                                                        <th style={{ border: "1px solid black", }} className="right">
                                                            <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                <small>Date</small>
                                                                <small>Soins</small>
                                                            </div>
                                                        </th>
                                                        <th style={{ border: "1px solid black", }} className="right">
                                                            <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                <small>N°</small>
                                                                <small>Prestation</small>
                                                            </div>
                                                        </th>
                                                        <th style={{ border: "1px solid black", }} className="right">
                                                            <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                <small>Nomenclature </small>
                                                                <small> </small>
                                                            </div>
                                                        </th>
                                                        <th style={{ border: "1px solid black", padding: "5px"}} className="right">
                                                            <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                <small>Qté </small>
                                                                <small> </small>
                                                            </div>
                                                        </th>

                                                        <th style={{ border: "1px solid black", padding: "5px"}} className="right">
                                                            <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                <small>Montant </small>
                                                                <small>Réclamé </small>
                                                            </div>
                                                        </th>
                                                        <th style={{ border: "1px solid black", padding: "5px"}} className="right">
                                                            <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                <small>Montant </small>
                                                                <small>Exclu </small>
                                                            </div>
                                                        </th>
                                                        <th style={{ border: "1px solid black", padding: "5px"}} className="right">
                                                            <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                <small>Base de  </small>
                                                                <small>Remboursement </small>
                                                            </div>
                                                        </th>
                                                        <th style={{ border: "1px solid black", padding: "5px"}} className="right">
                                                            <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                <small>Ticket </small>
                                                                <small>Modérateur </small>
                                                            </div>
                                                        </th>
                                                        {/*<th style={{ border: "1px solid black", }} className="right">
                                                            <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                <small> </small>
                                                                <small> </small>
                                                            </div>
                                                        </th>*/}
                                                        <th style={{ border: "1px solid black", padding: "5px"}} className="right">
                                                            <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                <small>Montant </small>
                                                                <small>Remboursé </small>
                                                            </div>
                                                        </th>
                                                        {/*<th style={{border: "1px solid black",}} className="right"><small style={{ fontWeight: "bold", }} >{this.props.t('common.label')} Exclusion</small></th>*/}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ border: "1px solid black", }} className={remb.motifRejet ? 'bg-warning' : ''}>
                                                        <td style={{ border: "1px solid black", alignSelf: "center", alignContent: "center" }} scope="row" className="center">
                                                            <div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}>{format(remb.date, 'dd/MM/yyyy')}</div>
                                                        </td>
                                                        <td style={{ border: "1px solid black", }} scope="row" className="left strong">
                                                            <div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}>
                                                                {remb.numeroPrestation}
                                                            </div>
                                                        </td>
                                                        <td style={{ border: "1px solid black", }} className="left strong">
                                                            <div style={{ alignSelf: "start", alignContent: "start", textAlign: "start", marginLeft: 5 }}>{remb.categorieName}</div>
                                                        </td>
                                                        <td style={{ border: "1px solid black", }} className="right">
                                                            <div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}>{remb.quantite}</div></td>
                                                        <td style={{ border: "1px solid black", }} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{remb.montant}</div></td>
                                                        <td style={{ border: "1px solid black", }} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{remb.montantExclu}</div></td>
                                                        <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{remb.baseRemboursement}</div></td>
                                                        <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{remb.tm}</div></td>
                                                        {/*<td style={{ border: "1px solid black", }} className="center"><small>   </small></td>*/}
                                                        <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{remb.montantRemb}</div></td>
                                                        {/*<td style={{border: "1px solid black",}} className="right"><small>{remb.motifExclusion}</small></td>*/}
                                                    </tr>
                                                    {remb.details.map((detail, i) => {
                                                        return <tr style={{ border: "1px solid black", }} className={detail.motifRejet ? 'bg-warning' : ''}>
                                                            <td style={{ border: "1px solid black", padding: "5px"}} className="center">
                                                                <div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}>{format(detail.date, 'dd/MM/yyyy')}</div></td>
                                                            <td style={{ border: "1px solid black", padding: "5px"}} className="left strong">
                                                                <div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}>
                                                                    {detail.numeroPrestation}
                                                                </div>
                                                            </td>
                                                            <td style={{ border: "1px solid black", }} className="left strong"><div style={{ alignSelf: "start", alignContent: "start", textAlign: "start", marginLeft: 5 }}>{detail.categorieName}</div></td>
                                                            <td style={{ border: "1px solid black", }} className="right"><div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}>{detail.quantite}</div></td>
                                                            <td style={{ border: "1px solid black", }} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.montant}</div></td>
                                                            <td style={{ border: "1px solid black", }} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.montantExclu}</div></td>
                                                            <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.baseRemboursement}</div></td>
                                                            <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.tm}</div></td>
                                                            {/*<td style={{ border: "1px solid black", }} className="center"><small style={{ color: "white" }}>00</small></td>*/}
                                                            <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.montantRemb}</div></td>
                                                            {/*<td style={{border: "1px solid black",}} className="right"><small>{detail.motifExclusion}</small></td>*/}
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        {
                                            this.props.isMotifExclusion &&
                                            <div style={{ marginTop: 10, marginRight: 1 }} className="row">
                                                <div className="col-lg-6 col-sm-6">
                                                    {/**libelle */}
                                                    <div style={{ marginTop: 20 }} className="table-responsive">
                                                        <table style={{ width: "99%", color: "black", }} /*className="table table-striped"*/>
                                                            <thead>
                                                                <tr style={{ border: "1px solid black", height: 50 }} >
                                                                    <th style={{ border: "1px solid black", padding: "0px 5px"}} scope="col" >
                                                                        <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                            <small>N° Prestation</small>
                                                                            <small></small>
                                                                        </div>
                                                                    </th>
                                                                    <th style={{ border: "1px solid black", padding: "0px 5px"}} scope="col">
                                                                        <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                            <small>Code</small>
                                                                            <small>Exclusion</small>
                                                                        </div>
                                                                    </th>
                                                                    <th style={{ border: "1px solid black", padding: "0px 5px"}} className="right">
                                                                        <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                            <small>Libellé</small>
                                                                            <small></small>
                                                                        </div>
                                                                    </th>
                                                                    <th style={{ border: "1px solid black", padding: "0px 5px"}} className="right">
                                                                        <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                            <small>Qté Exclue</small>
                                                                            <small></small>
                                                                        </div>
                                                                    </th>
                                                                    <th style={{ border: "1px solid black", padding: "0px 5px"}} className="right">
                                                                        <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                                            <small>Montant Exclue</small>
                                                                            <small></small>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>


                                                               <tr style={{ border: "1px solid black", }} className={''}>
                                                                            <td style={{ border: "1px solid black", padding: "0px 5px"}} className="left strong">
                                                                                <div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}>
                                                                                    {remb.numeroPrestation}
                                                                                </div>
                                                                            </td>

                                                                            <td style={{ border: "1px solid black", padding: "0px 5px"}} className="left strong"><div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}></div></td>

                                                                            <td style={{ border: "1px solid black", padding: "0px 5px"}} className="right"><div style={{ alignSelf: "start", alignContent: "start", textAlign: "start", marginLeft: 5 }}>{remb.motifExclusion}</div></td>

                                                                            <td style={{ border: "1px solid black", padding: "0px 5px"}} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{remb.quantite}</div></td>

                                                                            <td style={{ border: "1px solid black", padding: "0px 5px"}} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{remb.montantExclu}</div></td>
                                                                        </tr>

                                                                {remb.details.map((detail, i) => {
                                                                    if (detail.motifExclusion?.length > 0) {
                                                                        return <tr style={{ border: "1px solid black", }} className={detail.motifRejet ? 'bg-warning' : ''}>
                                                                            <td style={{ border: "1px solid black", padding: "0px 5px"}} className="left strong">
                                                                                <div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}>
                                                                                    {detail.numeroPrestation}
                                                                                </div>
                                                                            </td>

                                                                            <td style={{ border: "1px solid black", padding: "0px 5px"}} className="left strong"><div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}></div></td>

                                                                            <td style={{ border: "1px solid black", padding: "0px 5px"}} className="right"><div style={{ alignSelf: "start", alignContent: "start", textAlign: "start", marginLeft: 5 }}>{detail.motifExclusion}</div></td>

                                                                            <td style={{ border: "1px solid black", padding: "0px 5px"}} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.quantite}</div></td>

                                                                            <td style={{ border: "1px solid black", padding: "0px 5px"}} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.montantExclu}</div></td>
                                                                        </tr>
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/** libelle fin */}
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", margin: 0, padding: 0 }} className="col-lg-6 col-sm-6">
                                                    <div style={{ width: "33%", height: "100%", alignSelf: "end" }}></div>
                                                    <div style={{ width: "10%", height: "100%", alignSelf: "end" }}></div>
                                                    <div style={{ width: "53%", height: "100%", alignSelf: "end", display: "flex", flexDirection: "column" }}>
                                                        <div style={{ width: "100%", display: "flex", justifyContent: "end", flexDirection: "row" }}>
                                                            <div style={{ width: "40%", color: 'black', }}>Total Réclamé</div>
                                                            <div style={{ width: "2%", color: 'black' }}>:</div>
                                                            <div style={{ width: "40%", border: "1px solid black", height: 30, color: 'black', }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{benef.montantReclame}</div> </div>
                                                        </div>

                                                        <div style={{ width: "100%", display: "flex", justifyContent: "end", flexDirection: "row" }}>
                                                            <div style={{ width: "40%", color: 'black', }}>Montant Exclu</div>
                                                            <div style={{ width: "2%", color: 'black' }}>:</div>
                                                            <div style={{ width: "40%", border: "1px solid black", height: 30, color: 'black', }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{this.allTotals(remb).exclusions}</div> </div>
                                                        </div>

                                                        <div style={{ width: "100%", display: "flex", justifyContent: "end", flexDirection: "row" }}>
                                                            <div style={{ width: "40%", color: 'black' }}>Base Remb</div>
                                                            <div style={{ width: "2%", color: 'black' }}>:</div>
                                                            <div style={{ width: "40%", border: "1px solid black", height: 30, color: 'black', }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{this.allTotals(remb).baseRemb}</div> </div>
                                                        </div>

                                                        <div style={{ width: "100%", display: "flex", justifyContent: "end", flexDirection: "row" }}>
                                                            <div style={{ width: "50%", color: 'black' }}>Ticket Modérateur</div>
                                                            <div style={{ width: "2%", color: 'black' }}>:</div>
                                                            <div style={{ width: "40%", border: "1px solid black", height: 30, color: 'black', }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{this.allTotals(remb).tm}</div> </div>
                                                        </div>

                                                        <div style={{ width: "100%", display: "flex", justifyContent: "end", flexDirection: "row" }}>
                                                            <div style={{ width: "50%", color: 'black' }}>Total Remboursé</div>
                                                            <div style={{ width: "2%", color: 'black' }}>:</div>
                                                            <div style={{ width: "40%", border: "1px solid black", height: 30, color: 'black', }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{this.allTotals(remb).totalRemb}</div> </div>
                                                        </div>
                                                    </div>


                                                    {/*<table className="table table-clear">
                                                    <tbody style={{ color: 'black' }}>
                                                        <tr>
                                                            <td style={{ width: "80%" }} >
                                                                <div style={{ backgroundColor: "red", display: "flex", flexDirection: "row", justifyContent: "end" }}>
                                                                    <div style={{ width: "40%" }}> </div>
                                                                    <div style={{ width: "50%" }}>{this.props.t('common.total')} Réclamé</div>
                                                                    <div style={{ width: "20%" }}>:</div>
                                                                </div>

                                                            </td>
                                                            <td style={{ border: "1px solid black", }} className="right">{benef.montantReclame}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "50%" }}>
                                                                <strong>Exclusions : </strong>
                                                            </td>
                                                            <td style={{ border: "1px solid black", }} className="right">{this.allTotals(remb).exclusions}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "50%" }}>
                                                                <strong>Base Remb. : </strong>
                                                            </td>
                                                            <td style={{ border: "1px solid black", }} className="right">{this.allTotals(remb).baseRemb}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "50%" }}>
                                                                <strong>{this.props.t('common.tm')} : </strong>
                                                            </td>
                                                            <td style={{ border: "1px solid black", }} className="right">{this.allTotals(remb).tm}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "50%" }}>
                                                                <strong>{this.props.t('common.total')} Remboursé : </strong>
                                                            </td>
                                                            <td style={{ border: "1px solid black", }} className="right">
                                                                <strong>{this.allTotals(remb).totalRemb}</strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>*/}
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <div style={{ pageBreakAfter: 'always' }} />
                                </div>
                            )
                        })
                    })
                })
                }
            </div>
        </div>
        );
    };

}


// global remboursement

class RemboursementTemplateGlobal extends React.Component {

    state = {
        currentKey: '',
        total: 0,
        totalMontantReclame: 0,
    }



    statusColor = () => {
        return "text-secondary"
    };



    allTotals = (remb) => {
        return {
            exclusions: (remb.montantExclu ? remb.montantExclu : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.montantExclu ? subElt.montantExclu : 0), 0),
            baseRemb: (remb.baseRemboursement ? remb.baseRemboursement : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.baseRemboursement ? subElt.baseRemboursement : 0), 0),
            tm: (remb.tm ? remb.tm : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.tm ? subElt.tm : 0), 0),
            totalRemb: (remb.montantRemb ? remb.montantRemb : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.montantRemb ? subElt.montantRemb : 0), 0),
        }
    }

    calculateTotalMontantExclu = (data) => {
        return data.benefList.reduce((totalSum, benef) => {
            const benefSum = benef.remboursements.reduce((rembSum, remb) => {
                const rembTotal = (remb.montantExclu ? remb.montantExclu : 0) +
                    remb.details.reduce((subSum, subElt) => {
                        return subSum + (subElt.montantExclu ? subElt.montantExclu : 0);
                    }, 0);
                return rembSum + rembTotal;
            }, 0);
            return totalSum + benefSum;
        }, 0);
    }

    soustraire = (a, b) => {
        return a - b;
    }

    diviser = (a, b) => {
        return (b / a) * 100;
    }

    // Méthode pour calculer le total de montantReclame
    calculerTotalMontantReclame = () => {
        const total = this.props.theRemb.ligneAssures?.reduce((acc, detail) => acc + (detail.montantReclame || 0), 0);
        this.setState({ totalMontantReclame: total });
    };

    componentDidMount() {
        // Calcul automatique au montage du composant
        this.calculerTotalMontantReclame();
    }



    render() {
        return (<div style={{ height: "100%" }} className="row">
            <div style={{ height: "100%" }} className="col-lg-12">
                {/*<div className="card-header">
                            {" "}
                            <strong>{this.props.facture.name}</strong>{" "}
                            <span className="float-right">
                            <strong>{this.props.t('common.status')}:</strong> <span className="danger">{this.props.facture.statusPaiement === STATUSES.pending ? this.props.t('statuses.'+this.props.facture.statusPaiement) : this.props.facture.statusPaiement}</span>
                            </span>{" "}
                        </div>
                        <div className="card-body">
                            <h3 className="justify-self-center text-center my-5 p-2 border boder-segondary">Bordereau de remboursement</h3>
                        </div>*/}


                <div style={{ width: "100%", height: "100%" }} className="card mt-3">
                    <div className="card-body">

                        <div style={{ border: " 1px solid black", width: '100%', display: "flex", flexDirection: "column", borderRadius: 5, height: 280, }} >
                            <label style={{ width: "20%", fontSize: 10, color: "black", alignSelf: "flex-end", marginTop: 5 }}>Edité le {this.props.getFormattedDate}</label>
                            <label style={{ fontSize: 17, color: 'black', fontWeight: "bold", textAlign: "center", border: " 1px solid black", borderRadius: 5, height: "20%", width: "50%", alignSelf: "center", marginBottom: "1em" }}>ETATS RECAPITULATIFS DES DEPENSES PAR FAMILLE MONTANTS RECLAMES/REMBOURSES</label>
                            <div style={{ height: "80%", width: "100%", display: "flex", flexDirection: "row" }}>

                                <div style={{ width: "100%", height: "30%", display: "flex", flexDirection: "column", marginBottom: 10 }}>
                                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black" }}>
                                        <label style={{ width: "32%", }}> {this.props.t('dashboard.souscipt-name')} </label>
                                        <label style={{ width: 1 }}> : </label>
                                        <label style={{ width: "30%", marginLeft: "2em" }}> {this.props.facture.souscripteurName} </label>
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black" }}>
                                        <label style={{ width: "32%", }}> Numéro interne </label>
                                        <label style={{ width: 1 }}> : </label>
                                        <label style={{ width: "30%", marginLeft: "2em" }}>  {this.props.facture.codeAssu} </label>
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black" }}>
                                        <label style={{ width: "32%", }}> {this.props.t('patient.police-number')} </label>
                                        <label style={{ width: 1 }}> : </label>
                                        <label style={{ width: "30%", marginLeft: "2em" }}> {this.props.facture.numeroPolice} </label>
                                    </div>
                                </div>

                                <div style={{ width: "100%", height: "60%", display: "flex", flexDirection: "column", marginTop: "0.1em", justifyContent: "space-between", }}>
                                    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", }}>
                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black", width: "100%", }}>
                                            <label style={{ width: "40%", }}> Entre le </label>
                                            <label style={{ width: 1, marginLeft: "0.5em", marginRight: "0.5em" }}> : </label>
                                            <div style={{ width: "58%", marginLeft: "0em" }}> <div style={{ width: "10em", border: "1px solid black", marginRight: "0.5em", textAlign: "center", fontWeight: "bold", height: "2em" }}>{this.props.facture.dateFacturation?.slice(0, 10)}</div></div>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black", width: "100%", }}>
                                            <label style={{ width: "40%", }}> Et le </label>
                                            <label style={{ width: 1, marginLeft: "0.5em", marginRight: "0.5em" }}> : </label>
                                            <div style={{ width: "58%", marginLeft: "0em" }}> <div style={{ width: "10em", border: "1px solid black", marginRight: "0.5em", textAlign: "center", fontWeight: "bold", height: "2em" }}>{this.props.facture.dateFacturation?.slice(0, 10)}</div></div>
                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black", width: "100%", }}>
                                            <label style={{ width: "40%", }}> Montant minimum remboursé </label>
                                            <label style={{ width: 1, marginLeft: "0.5em", marginRight: "0.5em" }}> : </label>
                                            <div style={{ width: "58%", marginLeft: "0em" }}> <div style={{ width: "10em", marginRight: "0.5em", textAlign: "center", fontWeight: "bold", height: "2em" }}>0.00</div></div>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1.5em", color: "black", width: "100%", }}>
                                            <label style={{ width: "40%", }}> Dépenses comptabilisées par rapport aux dates de </label>
                                            <label style={{ width: 1, marginLeft: "0.5em", marginRight: "0.5em" }}> : </label>
                                            <div style={{ width: "58%", marginLeft: "0em" }}> <div style={{ width: "10em", marginRight: "0.5em", textAlign: "center", fontWeight: "bold", height: "2em" }}>REGLEMENT</div></div>
                                        </div>

                                    </div>

                                    <div style={{ width: "5%", height: "100%", display: "flex", flexDirection: "column", }}></div>
                                </div>

                            </div>

                        </div>

                        <div className="table-responsive">
                            <table style={{ width: "99%", color: "black", marginTop: 50 }} >
                                <thead>
                                    <tr style={{ border: "1px solid black", }}>
                                        <th style={{ border: "1px solid black", padding: "5px"}} className="center"><small style={{ fontWeight: "bold" }}>Matricule</small></th>
                                        <th style={{ border: "1px solid black", padding: "5px"}}><small style={{ fontWeight: "bold" }}>Famille</small></th>
                                        <th style={{ border: "1px solid black", padding: "5px"}} className="right"><small style={{ fontWeight: "bold" }}>Effectif Traité</small></th>
                                        <th style={{ border: "1px solid black", padding: "5px"}} className="right"><small style={{ fontWeight: "bold" }}>Montants Réclamés</small></th>
                                        <th style={{ border: "1px solid black", padding: "5px"}} className="right"><small style={{ fontWeight: "bold" }}>Montants Remboursés</small></th>
                                        <th style={{ border: "1px solid black", padding: "5px"}} className="right"><small style={{ fontWeight: "bold" }}>Montants Non Remboursés</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.theRemb.ligneAssures?.map((detail, i) => (
                                        <tr style={{ border: "1px solid black", }} key={i}>
                                            <td style={{ border: "1px solid black", }} className="left strong"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.assurePrincId}</div></td>
                                            <td style={{ border: "1px solid black", }} className="left strong"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.assurePrincName}</div></td>
                                            <td style={{ border: "1px solid black", }} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.benefList?.length}</div></td>
                                            <td style={{ border: "1px solid black", }} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{detail.montantReclame}</div></td>
                                            <td style={{ border: "1px solid black", }} className="center">
                                                <div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>
                                                    {this.soustraire(detail.montantReclame, this.calculateTotalMontantExclu(detail))}
                                                </div>
                                            </td>
                                            <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{this.calculateTotalMontantExclu(detail)}</div></td>
                                        </tr>
                                    ))}

                                    {/* Ligne des totaux */}
                                    <tr style={{ fontWeight: "bold" }}>

                                        <td colSpan="2" > <p style={{ marginLeft: "50%" }}>TOTAL POLICE :</p></td>
                                        <td style={{ border: "1px solid black", }} className="right">
                                            <div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>
                                                {this.props.theRemb.ligneAssures?.reduce((acc, detail) => acc + (detail.benefList?.length || 0), 0)}
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid black", }} className="right">
                                            <div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>
                                                {this.props.theRemb.ligneAssures?.reduce((acc, detail) => acc + (detail.montantReclame || 0), 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid black", }} className="right">
                                            <div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>
                                                {this.props.theRemb.ligneAssures?.reduce((acc, detail) => acc +
                                                    this.soustraire(detail.montantReclame, this.calculateTotalMontantExclu(detail)), 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid black", }} className="right">
                                            <div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>
                                                {this.props.theRemb.ligneAssures?.reduce((acc, detail) => acc + (this.calculateTotalMontantExclu(detail) || 0), 0).toFixed(2)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style={{ fontWeight: "bold" }}>

                                        <td colSpan="3" > </td>

                                        <td style={{ border: "1px solid black", }} className="right">
                                            <div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>
                                                100%
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid black", }} className="right">
                                            <div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>
                                               {
                                                ((this.props.theRemb.ligneAssures?.reduce((acc, detail) => acc +
                                                this.soustraire(detail.montantReclame, this.calculateTotalMontantExclu(detail)), 0) / 
                                                this.props.theRemb.ligneAssures?.reduce((acc, detail) => acc + (detail.montantReclame || 0), 0)
                                                ) * 100).toFixed(2)
                                               } %
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid black", }} className="right">
                                            <div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>
                                               {
                                                ((this.props.theRemb.ligneAssures?.reduce((acc, detail) => acc + (this.calculateTotalMontantExclu(detail) || 0), 0) / 
                                                this.props.theRemb.ligneAssures?.reduce((acc, detail) => acc + (detail.montantReclame || 0), 0)
                                                ) * 100).toFixed(2)
                                               } %
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div style={{ pageBreakAfter: 'always' }} />
                </div>



            </div>
        </div>
        );
    };

}

// fin global remboursement 



const RemboursementDetails = (props) => {
    const { t, values } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { remboursement, currentUser, successMessage, errorMessage, history } = props;
    console.log('remboursement');
    console.log(remboursement);
    const componentRef = useRef();
    const componentRefGlo = useRef();
    const getPageMargin = () => {
        return `@page {margin: ${5}  ${15} ${5} ${25} !important } `
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: getPageMargin(),

    })
    const handlePrintGlo = useReactToPrint({
        content: () => componentRefGlo.current,
        pageStyle: getPageMargin(),

    })
    function getFormattedDate() {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}h${minutes}`;
    }




    const [theRemb, setTheRemb] = useState({});
    const [isMotifExclusion, setIsMotifExclusion] = useState(false);

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if (!theMenu) {
            return false;
        } else {
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }

    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENREMBOURS') && canDo('MENREMBOURS', 'VIEWREMBOURSDETAIL')){
            dispatch(startLoader());
            dispatch(getRemboursementAction(id));
            dispatch(getMotifsExclusionAction());
        } else {
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        dispatch(startLoader());
        dispatch(getRemboursementAction(id));
    }, [successMessage, errorMessage]);
    useEffect(() => {
        if(Object.keys(remboursement).length > 0){
            let datas = {
                souscripteurId: remboursement.souscripteurId,
                souscripteurName: remboursement.souscripteurName,
            }

            let rembDetails = remboursement.factureRemboursementDetails;

            let res = [];

            let visitedIds = [];

            let myRes = rembDetails.map(remb => {
                let obj = {};
                if (!visitedIds.includes(remb.assurerPrincipalId)) {
                    obj.assurePrincId = remb.assurerPrincipalId;
                    obj.assurePrincName = remb.assurerPrincipalName;
                    obj.montantReclame = remb.montantReclame;
                    let benefs = rembDetails.filter(elt => {return elt.assurerPrincipalId === remb.assurerPrincipalId});
                    
                    let visitedBenefIds = [];
                    obj.benefList = benefs.map(benef => {
                        let res = {};
                        if (!visitedBenefIds.includes(benef.assurerId)) {
                            res.assureId = benef.assurerId;
                            res.assureName = benef.assurerName;
                            res.montantReclame = benef.montantReclame;
                            let presta = rembDetails.filter(elt => {return elt.assurerId === benef.assurerId});
                            
                            res.remboursements  = presta.map(prest => {
                                let resP = {};
                                if (prest.motifExclu) { setIsMotifExclusion(true) }

                              //  if (prest.type && prest.type.toLowerCase().includes("consultation")) {

                                if(prest.type && prest.type.toLowerCase().includes("consultation")) {
                                    
                                    resP.numRemboursement = prest.consultationCode;
                                    resP.prestatairePrescriptId = prest.prescripteurId;
                                    resP.prestatairePrescriptName = prest.prescripteurName;
                                    resP.prestataireExecId = prest.executeurId;
                                    resP.prestataireExecName = prest.executeurName;
                                    resP.categorieId = prest.categorieId;
                                    resP.numeroPrestation = Math.floor(Math.random() * (999999999 - 0 + 1)) + 0;
                                    resP.categorieName = prest.categorieName;
                                    resP.montant = prest.montantPrestationReclame;
                                    resP.medecin = prest.prescripteurName;
                                    resP.montantExclu = prest.montantExclu;
                                    resP.motifExclusion = prest.motifExclu;
                                    resP.baseRemboursement = prest.factureRejets.reduce((sum, elt) => sum + elt.baseRemb, 0);
                                    resP.montantRemb = prest.montantPrestationReclame - prest.montantExclu;
                                    resP.tm = prest.factureRejets.reduce((sum, elt) => sum + elt.tm, 0);
                                    resP.quantite = prest.quantite;
                                    resP.date = new Date(prest.dateExecution);
                                    resP.actes = prest.factureRejets;
                                    resP.files = [prest.files];
                                    let detPrest = rembDetails.filter(elt => {return (elt.consultationCode === prest.consultationCode && !elt.type)})
                                    
                                    resP.details = detPrest.map(detP => {
                                        if (detP.motifExclu) { setIsMotifExclusion(true) }
                                        return {
                                            prestataireExecId: detP.executeurId,
                                            prestataireExecName: detP.executeurName,
                                            categorieId: detP.categorieId,
                                            numeroPrestation: Math.floor(Math.random() * (999999999 - 0 + 1)) + 0,
                                            categorieName: detP.categorieName,
                                            montant: detP.montantPrestationReclame,
                                            date: new Date(detP.dateExecution),
                                            montantExclu: detP.montantExclu,
                                            motifExclusion: detP.motifExclu,
                                            baseRemboursement: detP.factureRejets.reduce((sum, elt) => sum + elt.baseRemb, 0),
                                            montantRemb: detP.montantPrestationReclame - detP.montantExclu,
                                            tm: detP.factureRejets.reduce((sum, elt) => sum + elt.tm, 0),
                                            actes: detP.factureRejets,
                                            quantite: detP.quantite,
                                            files: [detP.files]
                                        }
                                    });
                                }
                                return resP;
                            });
                            res.remboursements = res.remboursements.filter(elt => { return Object.keys(elt).length > 0 });
                            visitedBenefIds.push(benef.assurerId);
                        }
                        return res;
                    });
                    obj.benefList = obj.benefList.filter(elt => { return Object.keys(elt).length > 0 });
                    visitedIds.push(remb.assurerPrincipalId);
                }
                return obj;
            });

            datas.ligneAssures = myRes.filter(elt => {return Object.keys(elt).length > 0});
            
            setTheRemb(datas);
        }
    }, [remboursement]);

    /*useEffect(() => {
        dispatch(getRemboursementAction(id));
        dispatch(getMotifsExclusionAction());
    }, [successMessage, errorMessage]);*/

    const sendForPayment = () => {
        dispatch(startLoader());
        dispatch(sendRembPaymentAction(id));
    }

    return (
        <Fragment>
            {props.showLoading &&
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>}

            {!props.showLoading &&
                <>
                    <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                        <div className="mr-auto d-none d-lg-block">
                            <button
                                onClick={e => handlePrint()}
                                className="btn btn-primary btn-rounded mr-3"
                            >
                                {t('common.print')}
                            </button>

                            <button
                                onClick={e => handlePrintGlo()}
                                className="btn btn-primary btn-rounded mr-3"
                            >
                                {t('common.print-global')}
                            </button>

                            {remboursement.statusPaiement !== STATUSES.enCoursPaiement && remboursement.statusPaiement !== STATUSES.nonPaye && <button
                                onClick={e => sendForPayment()}
                                className="btn btn-success btn-rounded mr-3"
                            >
                                {t('common.validate')} pour paiement
                            </button>}

                            {/*{ remboursement.statusPaiement === STATUSES.pending && <button
                                className="btn btn-secondary btn-rounded mr-3"
                            >
                               {t('client.submit')} pour validation
                            </button>}*/}
                        </div>
                    </div>
                    <div style={{ width: "100%", padding: 5, height: "1000%" }}>
                        <RemboursementTemplate isMotifExclusion={isMotifExclusion} getFormattedDate={getFormattedDate()} theRemb={theRemb} t={t} facture={remboursement} ref={componentRef} dispatch={dispatch} />
                    </div>

                    <div style={{ width: "100%", padding: 5, height: "1000%", display: "none" }}>
                        <RemboursementTemplateGlobal isMotifExclusion={isMotifExclusion} getFormattedDate={getFormattedDate()} theRemb={theRemb} t={t} facture={remboursement} ref={componentRefGlo} dispatch={dispatch} />
                    </div>
                </>
            }
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        motifExclusion: state.facturation.motifExclusion,
        remboursement: state.facturation.remboursement,
        successMessage: state.facturation.successMessage,
        errorMessage: state.facturation.errorMessage,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(RemboursementDetails);
