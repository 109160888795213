import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react'
import { usePDF, Margin, Resolution } from 'react-to-pdf';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import "../../../css/exportFacture.css"
import { useReactToPrint } from 'react-to-print';
import num2words from "num2words";

export default function ExportFacture({ data, currentUser, facture, id }) {
    const { toPDF, targetRef } = usePDF({
        filename: "carte_de_" + "DOMBOU",
        page: {
            margin: { top: 10, bottom: 10, left: 15, right: 15 },
        }
    })
    console.log("data")
    console.log(data)
    console.log("data")

    const [number, setNumber] = useState("");
    const [words, setWords] = useState("");

    const convertToWords = (num) => {
        let number = num+'';
        if (!isNaN(number) && number !== "") {
            return (num2words(number, { lang: "fr" })); // Convertir en français
        } else {
            return 0;
        }
    };

    const componentRef = useRef();
    const getPageMargin = () => {
        return `
        @page {
        margin: ${1}  ${1} ${1} ${1} !important;
        
        }
        @media print {
                body {
                    width: 10vh;
                    height: 10vw;
                    -webkit-print-color-adjust: exact;
                    transform: rotate(90deg);
                    transform-origin: center;
                    transform: translate(-15%, -100%) rotate(90deg);
                    position: absolute;
                    top: 100%;
                    left:50%;
                    bottom:100%:
                    right:0%;
                }
               
        `
    }

    const getPageMargine = () => {
        return `
            @page {
                size: landscape;
                margin: 0;
            }
            @media print {
                body, html {
                    width: 100vw;
                    height: 100vh;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                table {
                    width: 100vh;
                    height: 100vw;
                    transform: rotate(90deg);
                    transform-origin: center;
                    border-collapse: collapse;
                }
                th, td {
                    padding: 10px;
                    text-align: center;
                }
            }
        `;
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: getPageMargin(),

    })

    const data1 = [
        { id: 1, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 18, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 19, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 20, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 21, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 22, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 2, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 3, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 4, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 5, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 6, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 7, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 8, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 9, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 10, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 11, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 12, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 13, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 14, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 15, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 151, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 152, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 153, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 154, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 155, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 156, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 157, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 158, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        // Ajoutez d'autres données ici
        { id: 221, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 21, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 388, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 499, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 588, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 678, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 78, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 87, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 992, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 107, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 1185, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 122, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 134, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 147, dt: '2024-12-11', police: 'P123', souscripteur: 'John Doe DEUDJEU KPUMASSI BASILE F', adherent: 'ohn Doe DEUDJEU KPUMASSI BASILE F', numeroBenef: '0098745632102361', beneficiaire: 'ohn Doe DEUDJEU KPUMASSI BASILE F', bon: 'Yes', actes: 'Acte 1', autres: 'Info', frais: 100, tm: 10, aPayer: 90, retenue: 10 },
        { id: 157, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 1571, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 1572, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 1532, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 1547, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 1558, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 1567, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 1577, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        { id: 1587, dt: '2024-12-10', police: 'P456', souscripteur: 'Alice Blue', adherent: 'Bob White', numeroBenef: '002', beneficiaire: 'Laura Green', bon: 'No', actes: 'Acte 2', autres: 'Details', frais: 200, tm: 20, aPayer: 180, retenue: 20 },
        // Ajoutez d'autres données ici
    ];
    /** position: absolute;
                        top: 50%;
                        left:50%; */
    // Split data into chunks for pagination
    // Split data into chunks for pagination
    const rowsPerPage = 100000; // Adjust based on desired rows per page
    const pageCount = Math.ceil(data.length / rowsPerPage);

    const chunkData = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    // Ajouter un id auto-incrémenté à chaque ligne
    const dataWithId = data.map((item, index) => ({ id: index + 1, ...item }));


    const pages = chunkData(dataWithId, rowsPerPage);

  

    // Calculer les totaux
    const totalActes = dataWithId.reduce((sum, row) => sum + parseInt(row.actes), 0);
    const totalAutres = dataWithId.reduce((sum, row) => sum + parseInt(row.autres), 0);
    const totalFrais = dataWithId.reduce((sum, row) => sum + parseInt(row.frais), 0);
    const totalTm = dataWithId.reduce((sum, row) => sum + parseInt(row.tm), 0);
    const totalAPayer = dataWithId.reduce((sum, row) => sum + parseInt(row.aPayer), 0);
    const totalRetenue = dataWithId.reduce((sum, row) => sum + parseInt(row.retenue), 0);
    const totalRetenuee = dataWithId.reduce((sum, row) => sum + parseInt(row.totalMontantRejecte), 0);
    const soustraction = (a,b) => {
        return a-b;
    };
    const Header = () => {
        return (
            <div style={{ width: "99%", height: "100%", display: "flex", flexDirection: "column", transform: "rotate(0deg)", }}>
                <div style={{ width: "100%", height: '2em', display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <label onClick={toPDF} style={{ fontWeight: "bolder", margin: 3, fontSize: 12, color: "black" }}>ACTIVA CAMEROUN</label>
                    <label style={{ fontWeight: "bolder", margin: 3, fontSize: 12, color: "black" }}>BORDEREAU DE DECOMPTE N° {id} ={'>'} PRESTATAIRE {facture.prestataire} </label>
                </div>

                <div style={{ width: "100%", height: '2em', display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 5 }}>
                    <label style={{ margin: 3, fontSize: 10, color: "black" }}>Edité par l'assureur le {facture.periode}</label>
                    <label style={{ margin: 3, fontSize: 10, color: "black" }}>Vérificateur : {currentUser.username} </label>
                    <label style={{ margin: 3, fontSize: 10, color: "black" }}>Attente de paiement </label>
                    <label style={{ margin: 3, fontSize: 10, color: "black" }}>PERIODE : {facture.periode} </label>
                    <label style={{ margin: 3, fontSize: 10, color: "black" }}>Demande Rgt :  </label>
                </div>
            </div>
        )
    }

    const DataTableNew = ({ data }) => {
        // Split data into chunks for pagination
        // Split data into chunks for pagination
        const rowsPerPage = 15; // Adjust based on desired rows per page
        const pageCount = Math.ceil(data.length / rowsPerPage);

        const chunkData = (array, size) => {
            const result = [];
            for (let i = 0; i < array.length; i += size) {
                result.push(array.slice(i, i + size));
            }
            return result;
        };

        const pages = chunkData(data, rowsPerPage);


        return (
            <>
                {pages.map((pageData, pageIndex) => (
                    <div key={pageIndex} style={{ marginBottom: '40px' }}>
                        <DataTable
                            // value={data}
                            tableStyle={{ marginLeft: 10, marginRight: 10, marginTop: 5, marginBottom: 5 }}
                            value={pageData}
                            responsiveLayout="scroll"
                            className="p-datatable-sm"
                            header={`Page ${pageIndex + 1} of ${pageCount}`}
                        >
                            <Column field="id" header="ID" />
                            <Column field="dt" header="Dt" />
                            <Column field="police" header="Police" />
                            <Column field="souscripteur" header="Souscripteur" />
                            <Column field="adherent" header="Adhérent" />
                            <Column field="numeroBenef" header="N° Bénéf" />
                            <Column field="beneficiaire" header="Bénéficiaire" />
                            <Column field="bon" header="Bon" />
                            <Column field="actes" header="Actes" />
                            <Column field="autres" header="Autres" />
                            <Column field="frais" header="Frais" />
                            <Column field="tm" header="TM" />
                            <Column field="aPayer" header="A payer" />
                            <Column field="retenue" header="Retenue" />


                        </DataTable>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '10px',
                                fontSize: '12px',
                                position: 'relative',
                            }}
                        >
                            Page {pageIndex + 1} of {pageCount}
                        </div>
                        {pageIndex < pageCount - 1 && <div style={{ pageBreakAfter: 'always' }} />}
                    </div>
                ))}



            </>

        )
    }

   

    useEffect(() => handlePrint(), [])

    return (
        <div onClick={handlePrint} style={{ width: 2400, height: 3508, display: "flex", flexDirection: "column", paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, }}>
            {/*  <div style={{ width: "100%", height: '2em', display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <label onClick={toPDF} style={{ fontWeight: "bolder", margin: 3, fontSize: 12, color: "black" }}>ACTIVA CAMEROUN</label>
                <label style={{ fontWeight: "bolder", margin: 3, fontSize: 12, color: "black" }}>ACTIVA CAMEROUN</label>
            </div>

            <div style={{ width: "100%", height: '2em', display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 5 }}>
                <label style={{ margin: 3, fontSize: 10, color: "black" }}>Edité par l'assureur le 16/09/2022</label>
                <label style={{ margin: 3, fontSize: 10, color: "black" }}>Vérificateur : MONNEY DEIDO EPESSE </label>
                <label style={{ margin: 3, fontSize: 10, color: "black" }}>Attente de paiement </label>
                <label style={{ margin: 3, fontSize: 10, color: "black" }}>PERIODE : 08(AOUT)-2022 </label>
                <label style={{ margin: 3, fontSize: 10, color: "black" }}>Demande Rgt : 2832 ={">"} Lot : 202208L </label>
            </div>
*/}
            { /*
  <div key={pageIndex} style={{ marginBottom: 20, }}>
                            <DataTable
                                // value={data}
                                tableStyle={{}}
                                value={pageData}
                                responsiveLayout="scroll"
                                className="p-datatable-sm"
                                footerColumnGroup={footerGroup}
                                //header={<Header />}
                                scrollable={false}
                                onScroll={false}
                            >
                                <Column field="id" header="N°" />
                                <Column field="dt" header="Dt" />
                                <Column field="police" header="Police" />
                                <Column field="souscripteur" header="Souscripteur" />
                                <Column field="adherent" header="Adhérent" />
                                <Column field="numeroBenef" header="N° Bénéf" />
                                <Column field="beneficiaire" header="Bénéficiaire" />
                                <Column field="bon" header="Bon" />
                                <Column field="actes" header="Actes" />
                                <Column field="autres" header="Autres" />
                                <Column field="frais" header="Frais" />
                                <Column field="tm" header="TM" />
                                <Column field="aPayer" header="A payer" />
                                <Column field="retenue" header="Retenue" />
                            </DataTable>
                        </div>
                        */}
            <div ref={componentRef} style={{ height: "100%", width: 1400, }} >
                <body style={{ transform: "rotate(0deg)", width: "100%", height: "100%", marginTop: 0, }}>
                    <Header />
                    {pages.map((pageData, pageIndex) => (
                        <div>
                          
                            {pageData.map((pageDat, key) => (

                                <div className="table-responsive">

                                    <table style={{ width: "100%", color: "black", marginTop: 5, }} /*className="table table-striped"*/>

                                        <thead>
                                            <tr style={{ border: "1px solid black", height: 50 }} >
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>N°</small>
                                                        <small></small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>Dt</small>
                                                        <small></small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>Police </small>
                                                        <small> </small>
                                                    </div>
                                                </th>


                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>Souscripteur </small>
                                                        <small> </small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>Adhérent </small>
                                                        <small></small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>N°</small>
                                                        <small>Bénéf </small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>Bénéficiaire </small>
                                                        <small> </small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small> Bon </small>
                                                        <small>  </small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>Actes </small>
                                                        <small> </small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>Autres </small>
                                                        <small> </small>
                                                    </div>
                                                </th>

                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>Frais </small>
                                                        <small> </small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>TM </small>
                                                        <small> </small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>A payer </small>
                                                        <small> </small>
                                                    </div>
                                                </th>
                                                <th style={{ border: "1px solid black", }} className="right">
                                                    <div style={{ flexWrap: "wrap", display: "flex", flexDirection: "column", alignSelf: "center", alignContent: "center" }} >
                                                        <small>Retenu </small>
                                                        <small> </small>
                                                    </div>
                                                </th>
                                                {/*<th style={{border: "1px solid black",}} className="right"><small style={{ fontWeight: "bold", }} >{this.props.t('common.label')} Exclusion</small></th>*/}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr style={{ border: "1px solid black", }} className={''}>
                                                <td style={{ border: "1px solid black", }} className="center">
                                                    <div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}>{pageDat.id}</div></td>
                                                <td style={{ border: "1px solid black", }} className="left strong">
                                                    <div style={{ alignSelf: "center", alignContent: "center", textAlign: "center" }}>
                                                        {pageDat.dt}
                                                    </div>
                                                </td>
                                                <td style={{ border: "1px solid black", }} className="left strong"><div style={{ alignSelf: "start", alignContent: "start", textAlign: "start", marginLeft: 5 }}>{pageDat.police}</div></td>
                                                <td style={{ border: "1px solid black", }} className="right"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.souscripteur}</div></td>
                                                <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.adherent}</div></td>
                                                <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.numeroBenef}</div></td>
                                                <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.beneficiaire}</div></td>



                                                <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.bon}</div></td>
                                                <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.actes}</div></td>
                                                <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.autres}</div></td>
                                                <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.frais}</div></td>
                                                <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.tm}</div></td>
                                                <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.aPayer}</div></td>
                                                <td style={{ border: "1px solid black", }} className="center"><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{pageDat.retenue}</div></td>
                                                {/*<td style={{border: "1px solid black",}} className="right"><small>{detail.motifExclusion}</small></td>*/}
                                            </tr>

                                            <tr style={{ border: "1px solid black", fontWeight: "bold" }}>
                                                <td colSpan={8} style={{ border: "1px solid black", textAlign: "center" }}>TOTAL</td>
                                                <td style={{ border: "1px solid black", textAlign: "right", marginRight: 5  }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{totalActes}</div></td>
                                                <td style={{ border: "1px solid black", textAlign: "right", marginRight: 5  }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{totalAutres}</div></td>
                                                <td style={{ border: "1px solid black", textAlign: "right", marginRight: 5  }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{totalFrais}</div></td>
                                                <td style={{ border: "1px solid black", textAlign: "right", marginRight: 5  }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{totalTm}</div></td>
                                                <td style={{ border: "1px solid black", textAlign: "right", marginRight: 5  }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{totalAPayer}</div></td>
                                                <td style={{ border: "1px solid black", textAlign: "right", marginRight: 5  }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{totalRetenue}</div></td>
                                            </tr>

                                            <tr style={{ border: "1px solid black", fontWeight: "bold" }}>
                                                <td colSpan={13} style={{ border: "1px solid black", textAlign: "center" }}>MOYENNE PAR MALADE :</td>
                                                <td style={{ border: "1px solid black", textAlign: "right", marginRight: 5  }}><div style={{ alignSelf: "end", alignContent: "end", textAlign: "end", marginRight: 5 }}>{totalRetenue / data?.length}</div></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            ))
                            }
                        </div>

                    ))}



                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 5 }}>
                        <label style={{ fontWeight: "bold", textAlign: "center", alignSelf: "center", height: "1em", width: "100%", height: '2em', marginBottom: 5, color: "black", fontSize: 12 }}>Arrété le présent décompte à la somme de : </label>
                        <label style={{ textAlign: "center", alignSelf: "center", height: "1em", background: "lightgray", width: "100%", height: '2em', marginBottom: 5, color: "black", fontSize: 12 }}>{convertToWords(totalAPayer)}  </label>
                        <label style={{ textAlign: "center", alignSelf: "center", height: "1em", width: "100%", height: '2em', marginBottom: 5, color: "black", fontSize: 12 }}>Fait à _____________, le {facture.periode}</label>
                        <label style={{ textAlign: "center", alignSelf: "center", height: "1em", width: "11%", height: '2em', marginBottom: 5, marginTop: 25, color: "black", fontSize: 12,borderBottom: '1px solid #000000', borderColor:'black', }}>Pour ACTIVA CAMEROUN</label>
                    </div>

                </body>
            </div>

            <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 5 }}>
                <label style={{ fontWeight: "bold", textAlign: "center", alignSelf: "center", height: "1em", width: "100%", height: '2em', marginBottom: 5, color: "black", fontSize: 12 }}>Arrété le présent décompte à la somme de : </label>
                <label style={{ textAlign: "center", alignSelf: "center", height: "1em", background: "lightgray", width: "100%", height: '2em', marginBottom: 5, color: "black", fontSize: 12 }}>{convertToWords(totalAPayer)}  </label>
                <label style={{ textAlign: "center", alignSelf: "center", height: "1em", width: "100%", height: '2em', marginBottom: 5, color: "black", fontSize: 12 }}>Fait à_____________, le {facture.periode}</label>
                <label style={{ textAlign: "center", alignSelf: "center", height: "1em", width: "100%", height: '2em', marginBottom: 5, marginTop: 25, color: "black", fontSize: 12, borderBottom: '1px solid #000000', borderColor:'black', borderWidth:20 }}>Pour ACTIVA CAMEROUN</label>
            </div>
        </div >
    )
}


