import React, { useRef } from "react";
import { Button } from 'primereact/button';
import { jsPDF } from 'jspdf';
import EtatsGlobaux from "./EtatsGlobaux";
import { useReactToPrint } from "react-to-print";
import EtatsConsommations from "./EtatsConsommations";
import EtatsVisites from "./EtatsVisites";
import EtatsTopConsommations from "./EtatsTopConsommations";
import EtatsDepenseRubrique from "./EtatsDepenseRubrique";
import EtatsTopPathologies from "./EtatTopPathologies";
import EtatsVisitePrestataire from "./EtatsVisistePrestataire";
import { usePDF } from 'react-to-pdf';
import { useTranslation } from "react-i18next";


export const ExportData = (props) => {
    //showExport && data.length > 0 
    const { data,typeRapport,showLoading,componentRef, nomRapport } = props;
    const { toPDF, targetRef } = usePDF({ filename: 'Etats_Détaillés _des_Consommations.pdf' })
    const {t}= useTranslation()

    const comRef = useRef()

    const exportToCSVe = () => {
        const ulElement = document.querySelector('ul');
        
        // Créer un objet CSV avec les données
        const csvContent = Array.from(ulElement.querySelectorAll('li'))
          .map(li => li.textContent.trim())
          .join('\n');
      
        // Créer un élément de lien pour télécharger le fichier CSV
        const link = document.createElement('a');
        link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
        link.download = 'donnees.csv';
      
        // Ajouter l'élément de lien au DOM et cliquer dessus
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

     
    
      const generateCSVEtatGlobaux = () => {
        const headers =  [t("common.total-amount-benefits"), t("common.total-rejected"), t("common.total-paid"),t("common.total-num-prov"), t("common.total-num-vis") ];
        const tableEtatGlobaux =[[data.totalPrestations,data.totalRejete,data.totalPaye, data.nbrePrestataire, data.nbreVisite],  ]
        const rows = Object.entries(tableEtatGlobaux).map(([key, value]) => [key, value]);
    
        const csvContent = [
            headers,
            ...tableEtatGlobaux,
          ]
            .map(row => row.join(','))
            .join('\n');
      
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', 'data.csv');
          link.style.visibility = 'hidden';
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      };

    

      const handlePrint = useReactToPrint({
        content: () => comRef.current,
      });

     

 


    return (
      <>
      {
        typeRapport==='etatGlobal' ?
        <>
        {
            Object.keys(data).length > 0 &&
                <div className="flex align-items-center justify-content-end gap-2">
                    <Button type="button" icon="pi pi-file" rounded onClick={() => generateCSVEtatGlobaux()} tooltip="Export CSV" tooltipOptions={{ position: 'top' }} />
                    <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={() => console.log('XLS')} tooltip="Export XLS" tooltipOptions={{ position: 'top' }} />
                    <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => handlePrint()} tooltip="Export PDF" tooltipOptions={{ position: 'top' }} />
                </div>
        }
        {typeRapport==='etatGlobal' &&    <div ref={comRef}><EtatsGlobaux data={data} showLoading={showLoading} /></div>}
        </>
        :
        <>
        </>
      }
        </>

    )
}

