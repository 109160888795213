import React, { useState, useEffect,useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import './EtatsGlobaux.css';
import { usePDF, Margin } from 'react-to-pdf';
import { Button } from 'primereact/button';
import { useReactToPrint } from 'react-to-print';
import PieChartNew from '../../chart/PieChartNew';
import HorizontalBarChartNew from '../../chart/HorizontalBarChartNew';

const EtatsTopConsommationsNew = (props) => {
    const { t } = useTranslation();
    const { data, showLoading, clientIds, dateMin, dateMax, clientName, type, ref } = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media print {
          .p-datatable th, .p-datatable td {
            padding: 8px !important;
            font-size: 10px !important;
          }
        }
      `,
      // Ajoute une entête sur chaque page du PDF
      onBeforeGetContent: () => {
        const doc = document.querySelector('.p-datatable');
        if (doc) {
          // Applique des sauts de page personnalisés et autres styles
          let pageBreaks = document.querySelectorAll('.p-datatable-pagebreak');
          pageBreaks.forEach((breakElement) => breakElement.remove()); // Nettoie les sauts de page existants
          let rows = doc.querySelectorAll('.p-datatable-row');
          for (let i = 15; i < rows.length; i += 15) {
            let pageBreak = document.createElement('div');
            pageBreak.classList.add('p-datatable-pagebreak');
            rows[i].parentNode.insertBefore(pageBreak, rows[i]);
          }
        }
      },
    });
    const dt = useRef(null);

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataAmbuSeries, setDataAmbuSeries] = useState([]);
    const [dataHospiSeries, setDataHospiSeries] = useState([]);
    const [dataAmbuLabels, setDataAmbuLabels] = useState([]);
    const [dataHospiLabels, setDataHospiLabels] = useState([]);
    const [graph, setGraph] = useState(false);
    const [bolPDF, setBolPDF] = useState(true);
    const [expandedRows, setExpandedRows] = useState(null);

    const [dataAmbuSerie2, setDataAmbuSerie2] = useState([]);
    const [dataHospiSerie2, setDataHospiSerie2] = useState([]);

    useEffect(() => {
        if(data.length > 0){
            console.log(data);
            setStatsDatas(data.map((key, i) => {
                return {
                    id: i,
                    statName: key.name,
                    totalMontant: key["total"]["montant"],
                    totalPourcent: parseFloat(key["total"]["taux"]),
                    ambuMontant: key["ambulatoire"]["montant"],
                    ambuPourcent: parseFloat(key["ambulatoire"]["taux"]),
                    hospiMontant: key["hospitalier"]["montant"],
                    hospiPourcent: parseFloat(key["hospitalier"]["taux"]),
                    children: key.children?.map((child, j) => {
                        return {
                            id: j,
                            statName: child.name,
                            totalMontant: child["total"]["montant"],
                            totalPourcent: parseFloat(child["total"]["taux"]),
                            ambuMontant: child["ambulatoire"]["montant"],
                            ambuPourcent: parseFloat(child["ambulatoire"]["taux"]),
                            hospiMontant: child["hospitalier"]["montant"],
                            hospiPourcent: parseFloat(child["hospitalier"]["taux"])
                        }
                    })
                }
            })) 

            let prestsAmbu = data.filter(elt => elt.ambulatoire.montant > 0);
            let prestsHospi = data.filter(elt => elt.hospitalier.montant > 0);
            
            setDataAmbuSeries([{
                data: prestsAmbu.map(key => {
                    return key["ambulatoire"]["montant"]
                })
            }]);
            
            setDataHospiSeries([{
                data: prestsHospi.map(key => {
                    return key["hospitalier"]["montant"]
                })
            }]);
            setDataAmbuLabels(prestsAmbu.map(key => key.name));
            setDataHospiLabels(prestsHospi.map(key => key.name));

            setDataHospiSerie2(
                prestsHospi.map(key => {
                        return key["hospitalier"]["montant"]
                    })
                );
    
                setDataAmbuSerie2(
                prestsAmbu.map(key => {
                        return  key["ambulatoire"]["montant"]
                    })
                );
        }
    }, [data]);

    const rowExpansionTemplate = (data) => {
        const childHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header={t("dashboard.beneficiary")} rowSpan={2}/>
                    <Column header="Total" colSpan={2} />
                    <Column header={ t("common.ambulatory")} colSpan={2} />
                    <Column header={t("common.hospital")} colSpan={2} />
                </Row>
                <Row>
                    <Column header={t("facturation.amount")} field="totalMontant" />
                    <Column header={t("common.rate")} field="totalPourcen" />
                    <Column header={t("facturation.amount")} field="ambuMontant" />
                    <Column header={t("common.rate")} field="ambuPourcen" />
                    <Column header={t("facturation.amount")} field="hospiMontant" />
                    <Column header={t("common.rate")} field="hospiPourcen" />
                </Row>
            </ColumnGroup>
        );
        
        return (
            <div>
                <DataTable 
                    dataKey="id"
                    showGridlines 
                    headerColumnGroup={childHeaderGroup}
                    value={data.children}
                >
                    <Column field="statName"></Column>
                    <Column field="totalMontant"></Column>
                    <Column field="totalPourcent"></Column>
                    <Column field="ambuMontant"></Column>
                    <Column field="ambuPourcent"></Column>
                    <Column field="hospiMontant"></Column>
                    <Column field="hospiPourcent"></Column>
                </DataTable>
            </div>
        );
    };

    const headerGroup = (
        <ColumnGroup>
            <Row>
                {/*<Column header={type} rowSpan={2} colSpan={type === "Famille" && bolPDF ? 2 : 1} />*/}
                <Column header={type} rowSpan={2} colSpan={type === "Famille" && bolPDF ? 1 : 2} />
                <Column header="Total" colSpan={2} />
                <Column header="Ambulatoire" colSpan={2} />
                <Column header="Hospitalier" colSpan={2} />
            </Row>
            <Row>
                <Column header={t("facturation.amount")} field="totalMontant" />
                <Column header="Taux en %" field="totalPourcen" />
                <Column header={t("facturation.amount")} field="ambuMontant" />
                <Column header="Taux en %" field="ambuPourcen" />
                <Column header={t("facturation.amount")} field="hospiMontant" />
                <Column header="Taux en %" field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );
    const headerGroupExport = (
        <ColumnGroup>
            <Row>
                <Column header={type} rowSpan={2} colSpan={1} />
                <Column header="Total" colSpan={2} />
                <Column header="Ambulatoire" colSpan={2} />
                <Column header="Hospitalier" colSpan={2} />
            </Row>
            <Row>
                <Column header={t("facturation.amount")} field="totalMontant" />
                <Column header="Taux en %" field="totalPourcen" />
                <Column header={t("facturation.amount")} field="ambuMontant" />
                <Column header="Taux en %" field="ambuPourcen" />
                <Column header={t("facturation.amount")} field="hospiMontant" />
                <Column header="Taux en %" field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );





    return (
        <>
            {Object.keys(data).length === 0 && <span>
                { t("common.no-data-display")}
            </span>
            }
            {Object.keys(data).length > 0 && 
            <>
              <div style={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "3em", paddingLeft: "2em", paddingRight: "2em", paddingTop: "1em", }}>
                        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                            <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "1.5em", marginBottom: "0.5em", }}>7- Etat Top Consommation</div>
                            <DataTable
                                value={statsDatas}
                                dataKey="id"
                                showGridlines
                                headerColumnGroup={headerGroup}
                                tableStyle={{ minWidth: '50rem' }}
                                ref={ref}                           
                            >
                                <Column field="statName"></Column>
                                <Column field="totalMontant"></Column>
                                <Column field="totalPourcent"></Column>
                                <Column field="ambuMontant"></Column>
                                <Column field="ambuPourcent"></Column>
                                <Column field="hospiMontant"></Column>
                                <Column field="hospiPourcent"></Column>
                            </DataTable>
                        </div>
                    </div>
                    <div style={{ marginLeft:  "2.5em", }}>
                        <div style={{ width: "100%", alignContent: "center", marginLeft: 50 }}>
                            <div style={{ alignSelf: "center", marginLeft: 20 }} >
                                <div className='col-15'>
                                    <HorizontalBarChartNew dt={dt} ref={ref} pieWidth="700" type={type} title={t("common.outpatient-atte")} series={dataAmbuSerie2} labels={dataAmbuLabels} height="150" legendPosition="left" />
                                </div>
                                <div className='col-15'>
                                    <HorizontalBarChartNew dt={dt} ref={ref} type={type} title={t("common.hospi-cons")} series={dataHospiSerie2} pieWidth="700" labels={dataHospiLabels} legendPosition="left" />
                                </div>
                            </div>
                        </div>
                    </div>
            </>
            }
        </>
    )
}
export default EtatsTopConsommationsNew;