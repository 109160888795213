import React, { useState } from 'react'
import { connect } from 'react-redux'
/// React router dom
import {Switch, Route, Redirect } from 'react-router-dom'
/// Css
import './index.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'
import 'primeicons/primeicons.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'   // theme
import 'primereact/resources/primereact.css'                                 // icons
import 'primeflex/primeflex.css'

/// Dashboard
import Home from "./components/Dashboard/Home";
import PatientDetails from "./components/Patient/PatientDetails";
import PatientIdentify from "./components/Patient/Identify";
import PreIdentify from './components/Patient/PreIdentify';
import NumAssuIdentify from './components/Patient/NumAssuIdentify';
import AddConsultation from "./components/Consultation/AddConsultation";
import Administration from "./components/Administration/Administration";
import ClientRegistration from './components/Clients/ClientForm/ClientRegistration';
import ClientGroupRegistration from './components/Clients/ClientForm/ClientGroupRegistration';
import ClientsList from './components/Clients/ClientList/ClientsList';
import ClientSearch from './components/Clients/ClientSearch';
import ProviderList from './components/Providers/ProviderList/ProviderList';
import ProviderForm from './components/Providers/ProviderForm/ProviderForm';
import TarificationForm from './components/Providers/ProviderForm/TarificationForm';
import ProviderView from './components/Providers/ProviderView/ProviderView';
import ParametragePrestataire from './components/Providers/Parameter/Index';
import UserProfil from './components/UserProfil/UserProfil';
import NotificationsList from './components/Notifications/NotificationsList';
import AccordsList from './components/AccordPrealable/AccordPrealableList/AccordsList';
import AccordPreaView from './components/AccordPrealable/AccordPreaView/AccordPreaView';
import ConsultationList from './components/ConsultationList/ConsultationList';
import DetailsConsultation from './components/ConsultationViews/DetailsConsultation';
import DossierMedialPane from './components/DossierMedical/DossierMedialPane';
import FacturationDetails from './components/Facturation/FacturationDetails';
import FacturationList from './components/Facturation/FacturationList';
import RemboursementPane from './components/Remboursement/RemboursementForm/RemboursementPane';
import RemboursementRequest from './components/Remboursement/RemboursementForm/RemboursementRequest';
import RemboursementList from './components/Remboursement/RemboursementView/RemboursementList';
import RemboursementDetails from './components/Remboursement/RemboursementView/RemboursementDetails';
import RemboursementForm from './components/Remboursement/RemboursementForm/RemboursementForm';
import ProformasList from './components/AccordPrealable/ProformaList/ProformasList';
import ProformaView from './components/AccordPrealable/ProformaView/ProformaView';
import AnalyseConsommation from './components/Rapports/AnalyseConsommation';
import RapportsGlobal from './components/Rapports/RapportsPane';
import Monitoring from './components/Monitoring/Monitoring';
import HistoriqueAvenants from './components/Monitoring/HistoriqueAvenants';
import HistoriqueIdentification from './components/Monitoring/HistoriqueIdentification';
import PrestationList from './components/Prestations/PrestationList';
import PrestationDetails from './components/Prestations/PrestationDetails';
import PrestationForm from './components/Prestations/PrestationForm';
import CodesValidation from './components/Monitoring/CodesValidation';
import Synchronisation from './components/Monitoring/Synchronisation';
import EtatsPanel from './components/Rapports/Souscripteur/EtatsPanel';
import GeneralParameters from './components/GeneralParameters';
import PreEnroll from './components/Patient/PreEnroll';
import PartialEnroll from './components/Patient/PartialEnroll';
import ProformaKineView from './components/AccordPrealable/ProformaView/ProformaKineView';
import ProformasKineList from './components/AccordPrealable/ProformaList/ProformasKineList';
import Historique from './components/DemandesAvenant/Historique';
import Sinistres from './components/Sinistres/Sinistres';
import SinistresDeclares from './components/Sinistres/SinistresDeclares';
import Recours from './components/Sinistres/Recours';
import Evaluations from './components/Sinistres/Evaluations';
import Reglements from './components/Sinistres/Reglements';
import AssuresSearch from './components/Clients/AssuresSearch';
import FacturesPrestataireList from './components/Facturation/FacturesPrestataireList';
import FacturePrestataireDetails from './components/Facturation/FacturePrestataireDetails';
import PoliceDashboard from './components/Monitoring/DetailPolice';
import MissingDataSynchro from './components/Monitoring/MissingDataSyncro';
import MonitoringDashboard from './components/Monitoring/TrackPoliceTotalBene';

/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import LockScreen from './pages/LockScreen'
import Profile from './pages/Profile'
import Error403 from './pages/Error403'
import Help from './pages/Help'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import { currentUser } from '../store/selectors/CurrentUserSelector'
import CardClient from './components/Clients/CardClient'
import ExportFacture from './components/Facturation/ExportFacture'

import Chatbot from './pages/Chatbot';


const Markup = (props) => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
	  { url: "patient-details", component: PatientDetails },
    { url: "patient-identify", component: PatientIdentify },
    { url: "patient-preidentify", component: PreEnroll },
    { url: "patient-enroll", component: PartialEnroll },
    { url: "identification", component: PreIdentify },
    { url: "identification-assure", component: NumAssuIdentify },
    { url: "consultation", component: AddConsultation },
    { url: 'client-register/:id/:updateMode', component: ClientRegistration },
    { url: 'client-group-register', component: ClientGroupRegistration },
    { url: 'client-list', component: ClientsList },
    { url: 'client-search', component: ClientSearch },
    { url: 'assure-search', component: AssuresSearch },
    { url: 'client-card/:id', component: CardClient },
    /// Prestataires
    { url: 'provider-list', component: ProviderList },
    { url: 'provider-form', component: ProviderForm },
    { url: 'tarification-form/:id', component: TarificationForm },
    { url: 'provider-update/:id', component: ProviderForm },
    { url: 'provider-view/:id', component: ProviderView },
    { url: 'provider-setting', component: ParametragePrestataire },
    /// pages
    { url: 'page-register', component: Registration },
    { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },
    { url: 'parametrage', component: Administration },
    { url: 'profile', component: Profile },
    { url: 'help-center', component: Help },
    // user profile
    { url: 'user-profil', component: UserProfil },
    
    /// Accords Préalables
    { url: 'accords-list', component: AccordsList },
    { url: 'demande-accord/:id/:idConsult', component: AccordPreaView },
    
    /// Notifications
    { url: 'notifications/:category', component: NotificationsList },

    /// Consultations
    { url: 'consultation-list', component: ConsultationList },
    { url: 'consultation-details/:id', component: DetailsConsultation },

    //Dossier Médical
    { url: 'dossier-medical/:id', component: DossierMedialPane },

    //Facturation
    { url: 'facturation', component: FacturationList },
    {/* url: 'facturation', component: ExportFacture  */},
    { url: 'facturation-details/:id', component: FacturationDetails },
    { url: 'factures-prestataires', component: FacturesPrestataireList },
    { url: 'details-factures-prestataires/:code', component: FacturePrestataireDetails },

    //Remboursement
    { url: 'remboursement-form', component: RemboursementPane },
    { url: 'demande-remboursement', component: RemboursementRequest },
    { url: 'demande-remboursement-update/:id', component: RemboursementRequest },
    { url: 'remboursement-list', component: RemboursementList },
    { url: 'remboursement-update/:id', component: RemboursementForm },
    { url: 'remboursement-details/:id', component: RemboursementDetails },

    //Proformas Optique
    { url: 'proformas', component: ProformasList },
    { url: 'proforma-details/:id/:providerId', component: ProformaView },
    //Proformas Kine
    { url: 'proformas-kine', component: ProformasKineList },
    { url: 'proforma-kine-details/:id/:providerId', component: ProformaKineView },
    // Rapport
    { url: 'rapport-consommation', component: AnalyseConsommation},
    { url: 'rapport-global', component: RapportsGlobal},
    { url: 'etats-prestataires', component: EtatsPanel},
    //Monitoring
    { url: 'monitoring', component: Monitoring},
    { url: 'historiques-avenants/:keyword/:currentData', component: HistoriqueAvenants},
    { url: 'codes-validation', component: CodesValidation},
    { url: 'historique-identifications', component: HistoriqueIdentification},
    { url: 'synchronisation', component: Synchronisation},
    { url: 'metric/police/:numPolice', component: PoliceDashboard},
    { url: 'metric/missing-data', component: MissingDataSynchro},
    { url: 'metric/police-diff', component: MonitoringDashboard},
    //Prestation
    { url: 'prestations', component: PrestationList},
    //{ url: 'prestation/:id', component: PrestationDetails},
    { url: 'prestation/:id', component: PrestationForm},
    //Error pages
    { url: 'access-denied', component: Error403 },
    //General Parameters
    { url: 'parametre-standarts', component: GeneralParameters},
    //Avenants
    {url: 'demandes-avenant', component: Historique},
    //Sinistres
    {url: 'sinistres', component: Sinistres},
    {url: 'sinistres-declares', component: SinistresDeclares},
    {url: 'recours', component: Recours},
    {url: 'evaluations', component: Evaluations},
    {url: 'reglements', component: Reglements},
  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                    key={i}
                    exact
                    
                    path={`/${data.url}`}
                    //element={ props.currentUser && props.currentUser.isFirstLogin ? <Redirect replace to="/profile#profile-settings" /> : <data.component /> }
                    //component={data.component}
                    >
                      { props.currentUser && props.currentUser.isFirstLogin && !data.url.includes('profile') ? 
                        <Redirect to="/profile#profile-settings" /> : 
                        <data.component /> 
                      }
                    </Route>
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
            <Chatbot />
          </div>
         <ScrollToTop />
       </>
  )
}
const mapStateToProps = (state) => {
    return {
       currentUser: currentUser(state)
    };
};
export default connect(mapStateToProps)(Markup);
