import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const clientServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CLIENT_BACKEND,
  headers: headers
});

const facialServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_FACIAL,
  headers: headers
}); 

const ContratServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONTRACT_BACKEND ,
  headers: headers
});


/*export const getClientServiceRequestToken = (config) => clientServiceRequest.post('/activaorassapitest/api/Authentication/Authenticate', {
  "nomutil":"CMR-AM",
  "userkey":"161ebd7d45089b3446ee4e0d86dbcf92"
}, config);

export const activateClient = (jsonParams, config) => clientServiceRequest.get(
  '/activaorassapitest/api/Request?jsonParametre='+jsonParams+'&nomProcedure=REQ_DETAIL_ASSU_AMP_PARAM&identifiant=CMR-AM',
  config
);*/

//export const listClients = (value) => clientServiceRequest.get('Client/search/'+value);

export const listClients = (nomBenef, numPolice, numSouscrip, dateMin, dateMax) => ContratServiceRequest.get('/Contrat/search',{
  params: {
    beneficiaire: nomBenef,
    police: numPolice,
    souscripteur: numSouscrip,
    dateMin: dateMin,
    dateMax: dateMax,
  }
});

export const updateClient = (clientId, clientData) => clientServiceRequest.put('Client/'+clientId, clientData);

export const createClient = (clientData) => clientServiceRequest.post('Client', clientData);

export const partialEnroll = (clientData) => clientServiceRequest.post('Client/lazi-enrollment', clientData);


export const authenticateClient = (clientId) => {
    return clientServiceRequest.post('/identify-client', {
     'client_id': clientId
    });
};

export const registerClientFace = (formData, config) => facialServiceRequest.post('/face/register', formData, config);

export const identifyClientFace = (formData, config) => facialServiceRequest.post('/face/register', formData, config);

export const checkClientRegistered = (config) => facialServiceRequest.post('/face/check-registry', null, config);

export const getClient = (id) => clientServiceRequest.get('Client/'+id);
//export const getClient = (id) => clientServiceRequest.get('Client/'+id+'?providerId=157');

export const getClientById = (id) => clientServiceRequest.get('Client/get/'+id);
// Historique identification
export const getHistoIdentifications = (providerId, date) => clientServiceRequest.get('/otp/provider/list?providerId='+providerId+'&date='+date);

export const searchClients = (nomBenef, numPolice) => clientServiceRequest.get('/Client/search',{
  params: {
    beneficiaire: nomBenef,
    police: numPolice
  }
});