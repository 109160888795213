// ##### Importation des modules reacts #####
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { Button, Spinner } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

// ##### Importation Components #####
import { loadingToggleAction,loginAction,
} from '../../store/actions/AuthActions';
import Lang from '../layouts/nav/lang';
import logo from '../../images/logo.png'
import logotext from '../../images/logo-text.png'
import logoActiva from '../../images/logo-activa.png'
import "./Login.css"

function Login (props) {
	let errorsObj = { email: '', password: '' }; 
	// ##### Déclaration des hookers #####
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

	// ##### Déclaration des hookers #####// Message d'erreur en cas d'echec de connexion


    function onLogin(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
			return ;
		}
		dispatch(loadingToggleAction(true));	
        dispatch(loginAction(email, password, props.history));
    }

  return (
  
		<div className="login-form-bx">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-6 col-md-7 box-skew d-flex">
						<div className="authincation-content">
							<Link to="/dashboard" className="login-logo">
								<div className='mb-3'><img src={logoActiva} alt=""  width={"300px"} className="logo-icon mr-2"/></div> 
								<img src={logotext} alt="" className="logo-text ml-1"/>
							</Link>
							<div className="mb-4">
								<h3 className="mb-1 font-w600">{t('dashboard.welcome')}</h3>
								<p className="">{t('dashboard.login-message')}</p>
							</div>
							{props.errorMessage && (
								<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
									{props.errorMessage}
								</div>
							)}
							{props.successMessage && (
								<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
									{props.successMessage}
								</div>
							)}
							<form onSubmit={onLogin}>
								<div className="form-group">
									<label className="mb-2 ">
										<strong className="">{t('common.username')}</strong>
									</label>
									<input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} autocomplete="off" />
									{/* {errors.email && <div className="text-danger fs-12">{errors.email}</div>} */}
								</div>
								<div className="form-group">
									<label className="mb-2 "><strong className="">{t('layout.password')}</strong></label>
									<input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} autocomplete="off" />
									{/* {errors.password && <div className="text-danger fs-12">{errors.password}</div>} */}
								</div>
								<div className="form-row d-flex justify-content-between mt-4 mb-2">
									<div className="form-group">
										<div className="custom-control custom-checkbox ml-1 ">
											<input type="checkbox" className="form-check-input" id="basic_checkbox_1"/>
											<label className="form-check-label" htmlFor="basic_checkbox_1">{t('layout.prefs')}</label>
										</div>
									</div>
								</div>
								<div className="text-center">
									
									{ props.showLoading && 
										<Button variant="primary" disabled>
											<Spinner
												as="span"
												animation="grow"
												size="sm"
												role="status"
												aria-hidden="true"
											/>{' '}
											<span className="visually-hidden">{t('common.await-text')}</span>
										</Button> }
									{ !props.showLoading &&
										<button type="submit" className="btn btn-primary btn-block">{t('layout.password')}</button>
									}
									
								</div>
								<div className="text-center mt-4">
									<Link className='text-primary' to='/help-center'>
									{t('layout.help-center')}
									</Link>
								</div>
							</form>
							<div className='my-2'>
								<Lang />
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-5 d-flex box-skew1">

					</div>
				</div>
			</div>
		</div>
		
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);