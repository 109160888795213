// MonComposant.js

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "./CardClient.css"
import activalogo from "../../../images/logoactivab.png"
import activalogoe from "../../../images/activalogo3.png"
import userIcon from "../../../images/user2.png"
import { usePDF, Margin } from 'react-to-pdf';
import { QRCodeCanvas } from "qrcode.react"
import { useParams, withRouter } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { getClientDataAction, getClientAction } from '../../../store/actions/ClientAuthActions';

const CardClient = (props) => {
    const { toPDF, targetRef } = usePDF({ filename: "carte_de_" + props.client?.firstName, page: { margin: Margin.SMALL } })
    const { id, } = useParams();
    const { history, client } = props;
    const {t} = useTranslation()

    const dispatch = useDispatch();
    const userData = {
        numero_de_police: client?.contrat?.beneficiaire?.numeroPolice,
        beneficiaire: client?.contrat?.beneficiaire?.numBeneficiaire,
        nom: client?.firstName,
        prenom: client?.lastName,
       
    }
    const userJson = JSON.stringify(userData, null, 2)

    useEffect(() => {
        dispatch(getClientAction(1769, history));
    }, [])

    const TELECHARGER = () => {
      history.push('/assure-search');
        toPDF()
    }

    console.log(client)

    return (
        <div>
            <div ref={targetRef}>
                <Recto t={t} client={client} />
                <Verso t={t} client={client} userJson={userJson} />
            </div>

            <button
                style={{
                    position: "absolute",
                    backgroundColor: '#50C878',
                    width: "150px",
                    height: "50px",
                    right: "10px",
                    bottom: "0px",
                    marginBottom: "20px",
                    borderRadius: 20,
                    color: "white",
                    fontWeight: "bold",
                    //  border: "#24439D 2px solid"
                }}
                onClick={() => TELECHARGER()}
            >TELECHARGER</button>
        </div>
    );
};

//export default CardClient;



const Recto = ({ client, t }) => {
   // const { t } = useTranslation()
    return (
        <div className="row justify-content-center">
            <div className="col-xl-4 col-xxl-6">
                <div style={{ backgroundColor: "white" }} className="card health-card">
                    <div className="card-header" style={{
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: '#24439D',
                        color: "white",
                        display: "flex"
                    }}>
                        <div style={{ fontSize: "25px", flexDirection: "column", display: "flex", alignSelf: "center", width: "100%" }}>
                            <span style={{ fontSize: "20px" }}>{t('common.activa-insurance')}</span>
                            <div><span style={{ color: 'white', fontSize: "10px" }}>N° {t("common.policy")}/ N° {t("common.assure")} : </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.contrat?.beneficiaire?.numeroPolice}/{client?.contrat?.beneficiaire?.numBeneficiaire}</span></div>
                        </div>
                        <img
                            alt=""
                            className="rounded mr-sm-4 mr-0"
                            width={130}
                            style={{
                                position: "absolute",
                                top: "30px",
                                width: "50px",
                                height: "50px",
                                right: "10px",
                                // bottom: "0px",
                                // marginBottom: "20px",
                                //  border: "#24439D 2px solid"
                            }}
                            src={activalogo}
                        />

                    </div>
                    <div className="card-body">
                        <div className="row px-2 pb-2" style={{
                            justifyContent: "center",
                            flexDirection: "row",
                            //  backgroundColor: "green",
                            alignItems: "center",
                            // width: "100%",
                            position: "relative",
                            //backgroundImage: `url(${activalogo})`,
                            backgroundSize: "75%",
                            backgroundPosition: 'center'
                        }}>
                            <div style={{ width: "25%", }}>
                                <div style={{
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    fontSize: "15px",
                                }}>
                                    <div className="col-17"
                                        style={{
                                            // alignItems: "center",
                                            justifyContent: "space-between",
                                            flexDirection: "row",
                                            // position:"relative",
                                            flexWrap: "nowrap",
                                            position: "absolute",
                                            top: "1px",
                                            width: "150px"

                                        }}>

                                    </div>

                                    <img
                                        alt=""
                                        className="rounded mr-sm-4 mr-0"
                                        width={130}
                                        style={{
                                            position: "absolute",
                                            top: "22px",
                                            width: "130px",
                                            height: "130px",
                                            left: "17px",
                                            marginBottom: "20px",
                                            // border: "#24439D 2px solid"
                                        }}
                                        src={client?.clientPhoto}
                                    />
                                </div>
                            </div>
                            <div style={{ width: "72%", }}>
                                <div style={{
                                    // alignItems: "center",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    fontSize: "15px",
                                    display: "flex"
                                }}>
                                    <div style={{ width: "45%", alignItems: "center", justifyContent: "center", }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}><span>  <span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("common.name")}: </span> </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.firstName} </span></div>
                                        <div style={{ display: "flex", flexDirection: "column" }}><span>  <span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("common.firstname")}: </span> </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.lastName}</span></div>
                                        <div style={{ display: "flex", flexDirection: "column" }}><span>  <span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>Email: </span> </span> <span style={{ fontSize: "9px", fontWeight: "bold", }}>{client?.email}</span></div>
                                        <div style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("common.born-on")}: </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.birthdate?.slice(0,10)}</span></div>
                                        <div style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("client.gender")}: </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.sexe == 'M' ? 'Masculin' : 'Feminin'}</span></div>
                                        <div style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("common.job")}: </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.profession}</span></div>
                                    </div>
                                    <div style={{ width: "45%", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("common.matrimony")}: </span><span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.maritalSituation}</span></div>

                                        <div style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>N. {t("consultation.child")} : </span><span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.childNumber}</span> </div>
                                        <div style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("common.phone")} : </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.phoneNumber}</span></div>
                                        <div style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>Whatsapp : </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.whatsappPhone}</span></div>
                                        <div style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("client.country")} : </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.country}</span></div>
                                        <div style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("client.city")} : </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.city}</span></div>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Verso = ({ client,userJson,t }) => {
   // const { t } = useTranslation()
    return (
        <div className="row justify-content-center">
            <div className="col-xl-4 col-xxl-6">
                <div style={{ backgroundColor: "white" }} className="card health-card">
                    <div className="card-header" style={{
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: '#24439D',

                        fontWeight: "bold",
                        color: "white",
                        justifyContent: "space-between"
                    }}>
                        <div style={{ fontSize: "25px", }}>
                            <span>{t('common.activa-insurance')}</span>
                        </div>

                    </div>
                    <div className="card-body" >
                        <img
                            alt=""
                            className="rounded mr-sm-4 mr-0"
                            width={130}
                            style={{
                                position: "absolute",
                                //  top: "70px",
                                width: "130px",
                                height: "130px",
                                right: "35%",
                                bottom: "30%",
                                marginBottom: "20px",
                                //  border: "#24439D 2px solid"
                                filter: 'blur(10px) brightness(90%) contrast(120%)',
                            }}
                            src={activalogoe}
                        />
                        <div className="row px-2 pb-2" style={{
                            justifyContent: "center",
                            flexDirection: "row",
                            //  backgroundColor: "green",
                            alignItems: "center",
                            // width: "100%",
                            position: "relative",
                            // backgroundImage: `url(${activalogo})`,
                            backgroundPosition: 'center',
                            backgroundSize: "75%",
                            // filter:'blur(5px) brightness(90%) contrast(120%)',
                            transform: `translateZ(0)`,
                            fillOpacity: 1,
                            //  zIndex:-1 
                        }}>
                            <div
                                style={{
                                    filter: 'blur(5px) brightness(90%) contrast(120%)',
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    zIndex: -1
                                }}
                            >

                            </div>
                            <div style={{ width: "100%", zIndex: 1, position: "relative" }}>
                                <div style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    fontSize: "15px",
                                    display: "flex",
                                    //zIndex: 1,
                                    //  filter:'blur(0px) brightness(0%) contrast(120%)',
                                }}>
                                    <div style={{ width: "50%", zIndex: 1, position: "relative", top: '0px' }}>
                                        <p style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("common.delivery-date")} : </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.contrat?.police?.dateSouscription?.slice(0,10)}</span></p>
                                        <p style={{ marginTop: "3px" }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}>{t("common.expiration-date")} : </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>{client?.contrat?.police?.dateExpiration?.slice(0,10)}</span></p>
                                        <QRCodeCanvas
                                            value={userJson}
                                            size={256}
                                            level='H'
                                            style={{
                                                width: "80px",
                                                height: "80px",
                                                left: "19px",

                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "50%", alignItems: "center", justifyContent: "flex-end", zIndex: 1, position: "relative", marginLeft: "20px" }}>
                                        <p style={{ marginTop: "3px", zIndex: 1 }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "11px" }}> <i className='lab la-facebook' /> <i className='lab la-twitter' /> <i className='lab la-linkedin-in' style={{ height: "15px", width: "15px" }} /> : </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>Activa Côte d'Ivoire</span></p>
                                        <p style={{ marginTop: "3px", zIndex: 1 }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "13px" }}><i className='lab la-whatsapp' />: </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>699 30 35 82 </span></p>
                                        <p style={{ marginTop: "3px", zIndex: 1 }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "13px" }}><i className='las la-phone' />: </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>676 28 41 41 </span></p>
                                        <p style={{ marginTop: "3px", zIndex: 1 }}><span style={{ color: '#50C878', fontWeight: "bolder", fontSize: "10px" }}><i className='las la-globe' />: </span> <span style={{ fontSize: "12px", fontWeight: "bold", }}>www.group-activa.com/cotedivoire</span></p>
                                    </div>



                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card-footer" style={{
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: '#24439D',

                        fontWeight: "bold",
                        color: "white",
                        justifyContent: "space-between",
                        // borderBottomRightRadius:"10%"
                    }}>
                        <div style={{ fontSize: "10px", }}>
                            <span>{t("common.footer-card")}</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        client: state.clientAuth.client,
    };
};

export default withRouter(connect(mapStateToProps)(CardClient));