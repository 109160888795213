import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { listProvidersAction } from "../../../store/actions/ProviderActions";
import { listFacturesPrestataireAction, deleteFacturePrestataireAction, validateFacturePrestataireAction, uploadFacturePrestataireAction, getUploadedFacturesPrestataireAction, startLoader } from "../../../store/actions/FacturationAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from "primereact/tooltip";
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import{withRouter} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Link } from "react-router-dom";
import { Spinner, Modal } from "react-bootstrap";
import { ProgressSpinner } from 'primereact/progressspinner';
import Select from 'react-select';
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap';
import './FacturesPrestataireList.css';


export const FacturesPrestataireList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef(null);

    const [providerId, setProviderId] = useState("");
    const [providerName, setProviderName] = useState("");

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [uploadFilters, setUploadFilters] = useState(null);
    const [globalUploadFilterValue, setGlobalUploadFilterValue] = useState('');
    const [addCard, setAddCard] = useState(false);
    const [progress, setProgress] = useState(0);

    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            montantTotal: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dateFacturation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            statusPaiement: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            isClose: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            datePaiement: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const initUploadFilters = () => {
        setUploadFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            factureURL: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            providerName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dateFacturation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            providerId: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalUploadFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const renderUploadHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearUploadFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalUploadFilterValue} onChange={onGlobalUploadFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const clearUploadFilter = () => {
        initUploadFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onGlobalUploadFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...uploadFilters };

        _filters['global'].value = value;

        setUploadFilters(_filters);
        setGlobalUploadFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'consultationCode', headerName: t('common.bon-search-placeholder'), filterPlaceholder: t('common.filter-by-code'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'montantTotal', dataType: 'numeric', headerName:  t('common.amount'), filterPlaceholder: t('facturation.filter-by-amount'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'patientName', headerName: t('common.patient'), filterPlaceholder: t('common.filter-by-patient'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'prestataireName', headerName: t('dashboard.provider'), filterPlaceholder: t('facturation.provider-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'confidence', headerName: 'Fiabilité', filterPlaceholder: 'Filtrer par fiabilité', style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: confidenceBodyTemplate},
            {name: 'dateFacture', filterField: 'dateFacture', dataType: 'date', headerName: t('facturation.create-date'), filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateFacturationBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'isValidated', headerName:  'Validé?', dataType: 'boolean', bodyClassName:"text-center", filterPlaceholder:  'Filtrer par statut de validation', style: { minWidth: '6rem' }, filter: true, bodyTemplate: statusHospiBodyTemplate, filterElement: statusHospiFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const setUploadColumns = () => {
        return [
            {name: 'providerId', headerName: 'Id Prestataire', filterPlaceholder: 'Filtrer par id prestataire', style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'providerName', headerName:  'Nom du prestataire', filterPlaceholder: 'Filtrer par prestataire', style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'status', headerName: 'Statut', filterPlaceholder: 'Filtrer par statut', style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'createdAt', filterField: 'dateFacture', dataType: 'date', headerName: 'Uploadé le', filterPlaceholder: 'Filtrer par date', style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateFacturationUploadBodyTemplate, filterElement: dateFilterUploadTemplate},
        ]
    };

    const confidenceBodyTemplate = (rowData) => {
        const stockClassName = classNames('border-circle w-3rem h-3rem inline-flex font-bold justify-content-center align-items-center text-sm', {
            'bg-red-100 text-red-900': rowData.confidence < 50,
            'bg-orange-100 text-orange-900': rowData.confidence >= 50 && rowData.confidence < 100,
            'bg-teal-100 text-teal-900': rowData.confidence === 100
        });

        return <div className={stockClassName}>{rowData.confidence}%</div>;
    };

    const statusHospiBodyTemplate = (rowData) => {
        return <div style={{display: "flex", justifyContent: "start"}}>
            <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.isValidated, 'text-red-500 pi-times-circle': !rowData.isValidated })}></i>
        </div>
    };

    const statusHospiFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    {t('common.with-hospi')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'VIEWADMINFACTURE') && <>
                <Tooltip target=".btn-info" position="top" />
                <button className="btn btn-info shadow btn-xs sharp mr-2"  data-pr-tooltip={t("common.view")}
                    onClick={(e) => viewFacture(rowData.consultationCode)}
                >
                    <i className="fa fa-eye"></i>
                </button>
            </>}
            {hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'VIEWADMINFACTURE') && !rowData.isValidated && <>
                <Tooltip target=".btn-success" position="top" />
                <button className="btn btn-success shadow btn-xs sharp mr-2"  data-pr-tooltip={t("laboratory.validate-button")}
                    onClick={(e) => validerFacture(rowData.consultationCode)}
                >
                    <i className="fa fa-check"></i>
                </button>
            </>}
            {hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'VIEWADMINFACTURE') && !rowData.isValidated && <>
                <Tooltip target=".btn-danger" position="top" />
                <button className="btn btn-danger shadow btn-xs sharp mr-2"  data-pr-tooltip={t("common.delete")}
                    onClick={(e) => deleteFacture(rowData.consultationCode)}
                >
                    <i className="fa fa-trash"></i>
                </button>
            </>}
        </div>
    };

    const viewFacture = (id) => {
        props.history.push('/details-factures-prestataires/'+id);
    }

    const deleteFacture = (id) => {
        dispatch(startLoader());
        dispatch(deleteFacturePrestataireAction(id));
    };

    const validerFacture = (id) => {
        dispatch(startLoader());
        dispatch(validateFacturePrestataireAction(id));
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateFacturationBodyTemplate = (rowData) => {
        return formatDate(rowData.dateFacture);
    };

    const dateFilterUploadTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateFacturationUploadBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    const factureConfidenceLevel = (facture) => {
        let temp = [];
        facture.acteTypeRequest.forEach((garan, i) => {
            garan.acteFactures.forEach((acte, j) => {
                temp.push({
                    ...acte,
                    garantie: garan.garantie,
                    garanAmount: garan.montantTotal,
                    keyData: garan.garantie+ '-' + i + '-' + j
                });
            });
        });
        let totalConfidence = Math.floor(temp.reduce((acc, cur) => acc + cur.confidence, 0)/temp.length);
        return totalConfidence;
    }

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        console.log("Files uploaded", e.files);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 500000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 50 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '60%' }}>
                    {/*<img alt={file.name} role="presentation" src={file.objectURL} width={100} />*/}
                    <i className="pi pi-file-pdf" style={{ fontSize: '2rem', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Glisser les fichiers ici pour les charger
                </span>
            </div>
        );
    };


    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const customUploadHandler = (e) => {
        console.log("Files uploaded", e.files);
        dispatch(startLoader());
        dispatch(uploadFacturePrestataireAction(providerId, providerName, e.files));

        let uploadProgress = 0;
        const interval = setInterval(() => {
            uploadProgress += 10;
            setProgress(uploadProgress);

            if (uploadProgress >= 100) {
                clearInterval(interval);
                e.options.onUploadComplete();
                setProgress(0);
            }
        }, 3000);
    }

    const [tabData, setTabData] = useState([
        {
          name: "Factures uploadées",
          icon: 'file-invoice',
        },
        {
          name: "Factures analysées",
          icon: 'user-clock',
        }
    ]);
    
    useEffect(() => {
        if(!props.showLoading){
            setAddCard(false);
        }
    }, [props.showLoading]);

    useEffect(() => {
        if(hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'LISTADMINFACTURE')){
            console.log("About to call all the consultations");
            dispatch(startLoader());
            dispatch(getUploadedFacturesPrestataireAction());
            dispatch(listFacturesPrestataireAction());
            dispatch(listProvidersAction());
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddCard(true)}
                    >
                     Uploader des factures
                    </Link>
                </div>
            </div>
            <Modal className="modal fade" size="lg" show={addCard} onHide={setAddCard} >
                <div className="">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Charger les factures</h4>
                        </div>
                        <div className='card-body'>
                            <div className='col-12 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('dashboard.provider')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <Select
                                    className="select-search"
                                    value={props.providers.filter(elt => elt.id === providerId).map((opt)=>{return {label:opt.providerName,value:opt.id}})}
                                    placeholder={t('dashboard.choose-provider')}
                                    required
                                    onChange={(val) => {
                                        setProviderId(val.value);
                                        setProviderName(val.label);
                                    }}
                                    options={props.providers.map((opt)=>{return {label:opt.providerName,value:opt.id}})}
                                    style={{
                                    lineHeight: '40px',
                                    color: '#7e7e7e',
                                    paddingLeft: ' 15px',
                                    }}
                                />
                            </div>

                            <div>
                                <Toast ref={toast}></Toast>

                                {props.showLoading && (
                                    <div className="loader-container">
                                        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                                        <p>Uploading...</p>
                                    </div>
                                )}

                                <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                                <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                                <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                                <FileUpload ref={fileUploadRef} name="demo[]" customUpload uploadHandler={customUploadHandler} multiple accept=".pdf" maxFileSize={50000000}
                                    onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                                    headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                    chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} disabled={props.showLoading}
                                    progressBarTemplate={(options) => (
                                        <div
                                            style={{
                                                height: '8px',
                                                width: `${progress}%`,
                                                backgroundColor: '#007ad9',
                                                borderRadius: '4px',
                                                transition: 'width 0.2s ease',
                                            }}
                                        />
                                    )} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='custom-tab-1'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                    <Nav as='ul' className='nav-tabs'>
                        {tabData.map((data, i) => (
                            <Nav.Item as='li' key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()}>
                                    <i className={`la la-${data.icon} mr-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    <Tab.Content className='pt-4'>
                        {tabData.map((data, i) => (
                            <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                {data.name === "Factures analysées" && <DataTableBase 
                                    data={props.facturesPrestataires.map(facture => {
                                        facture.confidence = factureConfidenceLevel(facture);
                                        facture.dateFacture = new Date(facture.dateFacture);
                                        return facture;
                                    })}
                                    emptyMessage={t('facturation.no-bill')} 
                                    filters={filters}
                                    globalFiltersFields={['code', 'montantTotal', 'patientName', 'dateFacture']}
                                    header={renderHeader()}
                                    columns={setColumns()}
                                    rows={10} 
                                    loading={props.showLoading}
                                    size={'small'}
                                    exportFilename={"Factures-prestataire"}
                                    exportColumns={[
                                        {  title: t('common.bon-search-placeholder'), dataKey: 'code' },
                                        { title: t('facturation.amount'), dataKey: 'montantTotal' },
                                        {  title: t('common.patient'), dataKey: 'patientName' },
                                        { title: t('facturation.invoice-date'), dataKey: 'dateFacture' },
                                    ]}
                                    showExport={true}
                                />}
                                {data.name === "Factures uploadées" && <DataTableBase 
                                    data={props.uploadedFacturesPrestataires.map(facture => {
                                        return {
                                            ...facture,
                                            createdAt: new Date(facture.createdAt),
                                            status: t(`statuses.${facture.status}`) || facture.status,
                                    }})}
                                    emptyMessage={t('facturation.no-bill')} 
                                    filters={filters}
                                    globalFiltersFields={['factureURL', 'providerName', 'providerId', 'status', 'createdAt']}
                                    header={renderUploadHeader()}
                                    columns={setUploadColumns()}
                                    rows={10} 
                                    loading={props.showLoading}
                                    size={'small'}
                                    exportFilename={"factures-uploadees"}
                                    exportColumns={[
                                        {  title: 'Id Prestataire', dataKey: 'providerId' },
                                        { title: 'Nom prestataire', dataKey: 'providerName' },
                                        {  title: 'Url Facture', dataKey: 'factureURL' },
                                        {  title: 'Statut', dataKey: 'status' },
                                        { title: 'Créé le', dataKey: 'createdAt' },
                                    ]}
                                    showExport={true}
                                />}
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        providers: state.provider.providers,
        facturesPrestataires: state.facturation.facturesPrestataires,
        uploadedFacturesPrestataires: state.facturation.uploadedFacturesPrestataires,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(FacturesPrestataireList));
