import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import './EtatsGlobaux.css';
import { usePDF, Margin } from 'react-to-pdf';
import { Button } from 'primereact/button';
import { useReactToPrint } from 'react-to-print';
import PieChartNew from '../../chart/PieChartNew';

const EtatsDepenseRubriqueNew = (props) => {
    const { t } = useTranslation();
    const { data, showLoading, clientIds, dateMin, dateMax, clientName } = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media print {
          .p-datatable th, .p-datatable td {
            padding: 8px !important;
            font-size: 10px !important;
          }
        }
      `,
      // Ajoute une entête sur chaque page du PDF
      onBeforeGetContent: () => {
        const doc = document.querySelector('.p-datatable');
        if (doc) {
          // Applique des sauts de page personnalisés et autres styles
          let pageBreaks = document.querySelectorAll('.p-datatable-pagebreak');
          pageBreaks.forEach((breakElement) => breakElement.remove()); // Nettoie les sauts de page existants
          let rows = doc.querySelectorAll('.p-datatable-row');
          for (let i = 15; i < rows.length; i += 15) {
            let pageBreak = document.createElement('div');
            pageBreak.classList.add('p-datatable-pagebreak');
            rows[i].parentNode.insertBefore(pageBreak, rows[i]);
          }
        }
      },
    });

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataAmbuSeries, setDataAmbuSeries] = useState([]);
    const [dataHospiSeries, setDataHospiSeries] = useState([]);
    const [dataAmbuLabels, setDataAmbuLabels] = useState([]);
    const [dataHospiLabels, setDataHospiLabels] = useState([]);
    const [graph, setGraph] = useState(false);

    const [dataAmbuSerie2, setDataAmbuSerie2] = useState([]);
    const [dataHospiSerie2, setDataHospiSerie2] = useState([]);
    console.log("dataAmbuSeries dans etats depenses rubriques")
    console.log(dataAmbuSeries)

    useEffect(() => {
        if (data.length > 0) {
            setStatsDatas(data.map(key => {
                return {
                    statName: key.providerName === 'Hospitalisation' ? 'Chambre' : key.providerName,
                    totalMontant: key["total"]["montant"],
                    totalPourcent: parseFloat(key["total"]["taux"]),
                    ambuMontant: key["ambulatoire"]["montant"],
                    ambuPourcent: parseFloat(key["ambulatoire"]["taux"]),
                    hospiMontant: key["hospitalier"]["montant"],
                    hospiPourcent: parseFloat(key["hospitalier"]["taux"]),

                }
            }))

            let prestsAmbu = data.filter(elt => elt.ambulatoire.montant > 0).sort((a, b) => b.ambulatoire.montant - a.ambulatoire.montant);
            let prestsHospi = data.filter(elt => elt.hospitalier.montant > 0).sort((a, b) => b.hospitalier.montant - a.hospitalier.montant);

            setDataAmbuSeries([{
                data: prestsAmbu.map(key => {
                    return key["ambulatoire"]["montant"]
                })
            }]);

            setDataHospiSeries([{
                data: prestsHospi.map(key => {
                    return key["hospitalier"]["montant"]
                })
            }]);
            setDataAmbuLabels(prestsAmbu.map(key => key.providerName || 'Non spécifié'));
            setDataHospiLabels(prestsHospi.map(key => key.providerName || 'Non spécifié'));

            setDataHospiSerie2(
                prestsHospi.map(key => {
                        return key["hospitalier"]["montant"]
                    })
                );
    
                setDataAmbuSerie2(
                prestsAmbu.map(key => {
                        return key["ambulatoire"]["montant"]
                    })
                );
        }
    }, [data]);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Garantie" rowSpan={2} />
                <Column header="Total" colSpan={2} />
                <Column header="Ambulatoire" colSpan={2} />
                <Column header="Hospitalier" colSpan={2} />
            </Row>
            <Row>
                <Column header="Montant" field="totalMontant" />
                <Column header="Taux en %" field="totalPourcen" />
                <Column header="Montant" field="ambuMontant" />
                <Column header="Taux en %" field="ambuPourcen" />
                <Column header="Montant" field="hospiMontant" />
                <Column header="Taux en %" field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );

    const dt = useRef(null);

 

    return (
        <>
            {Object.keys(data).length === 0 && <span>
                Aucune donnée à afficher, veuiller remplir les filtres afin d'en avoir.
            </span>
            }
            {Object.keys(data).length > 0 && 
            <>
            
            <div style={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "3em", paddingLeft: "2em", paddingRight: "2em", paddingTop: "1em", }}>
                        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                            <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "1.5em", marginBottom: "0.5em", }}>4- Etat Dépense Rubrique</div>
                            <DataTable
                                value={statsDatas}
                                showGridlines
                                headerColumnGroup={headerGroup}
                                tableStyle={{ minWidth: '50rem',  }}
                              
                            >
                                <Column style={{ fontSize:30}} field="statName"></Column>
                                <Column style={{ fontSize:30}}  field="totalMontant"></Column>
                                <Column style={{ fontSize:30}} field="totalPourcent"></Column>
                                <Column style={{ fontSize:30}} field="ambuMontant"></Column>
                                <Column style={{ fontSize:30}} field="ambuPourcent"></Column>
                                <Column style={{ fontSize:30}} field="hospiMontant"></Column>
                                <Column style={{ fontSize:30}} field="hospiPourcent"></Column>
                            </DataTable>
                        </div>

            
                        



                    </div>
                    <div style={{ marginLeft:  "2.5em", }}>
                        <div style={{ width: "100%", alignContent: "center", marginLeft: 50 }}>
                            <div style={{ alignSelf: "center", marginLeft: 20 }} >
                                <div className='col-15'>
                                    <PieChartNew dt={dt} pieWidth="700"  type="" title="Rubriques en Ambulatoire" series={dataAmbuSerie2} labels={dataAmbuLabels} height="150" legendPosition="left" />
                                </div>
                                <div className='col-15'>
                                    <PieChartNew dt={dt} pieWidth="700"  type="" title="Rubriques en Hospitalisation" series={dataHospiSerie2} labels={dataHospiLabels} height="150" legendPosition="left" />
                                </div>
                            </div>
                        </div>
                    </div>
            </>
            }
        </>
    )
}
export default EtatsDepenseRubriqueNew;