import React, {useState, useEffect} from 'react'
import { useDispatch, connect } from 'react-redux';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { createJiraTicketAction, loadingToggleAction } from '../../store/actions/HomeAction';
import { Button, Spinner } from 'react-bootstrap';
import "./Help.css"

const Help = (props) => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('Général');
    const [message, setMessage] = useState('');

    const createTicket = (e) => {
        e.preventDefault();

        let data = {
            contentText: `${message} -- ${name} -- ${email}`,
            summary: `${name} -- ${subject}`,
            priority: "Medium",
            issuetype: "Incident",

        }

        dispatch(loadingToggleAction(true));
        dispatch(createJiraTicketAction(data));
          
    }

    
    useEffect(() => {
        if(props.successMessage.trim().length > 0) {
            setName('');
            setEmail('');
            setSubject('Général');
            setMessage('');
        }
    }, [props.successMessage]);

    return (
        <div className='bg-white p-4'>
            <h3>Centre d'aide</h3>
            <div className="container-help">
                <div className='row' style={{width: "100%"}}>
                    <div className='col-6'>
                        <Accordion activeIndex={0}>
                            <AccordionTab header="Comment se connecter à A-CARE ?">
                                <p className="m-0">
                                    <ul>
                                        <li>
                                            Veuillez vous munir de votre email et mot de passe communiqué par 
                                            votre assureur ou votre service informatique.
                                        </li>
                                        <li>
                                            Renseignez à tour de rôle votre adresse Email dans le champ 
                                            Email puis le mot de passe dans le champ mot de passe
                                        </li>
                                        <li>
                                            Cliquer sur le bouton S’identifier
                                        </li>
                                    </ul>
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment faire quand on a oublié l'adresse mail ?">
                                <p className="m-0">
                                    Contacter le support technique par mail, la fonctionnalité mot de passe perdu n’est pas disponible pour le moment
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment enrôler un assuré ?">
                                <p className="m-0">
                                    Cliquez sur le menu “authentification” et allez dans le sous menu “enrôlement”
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Ma webcam ne s’affiche pas, que faire ?">
                                <p className="m-0">
                                    Vérifiez le paramètre de configuration de votre navigateur, ensuite autorisé la webcam
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment identifier un assuré par la reconnaissance faciale ?">
                                <p className="m-0">
                                    Cliquer sur le bouton identification par reconnaissance faciale 
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment identifier un assuré par un numéro assuré  ?">
                                <p className="m-0">
                                    Cliquer sur le bouton identification par numéro assuré 
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment faire une demande de consultation?">
                                <p className="m-0">
                                    cliquer sur menu, ensuite cliquer sur Consultation 
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment accéder à un dossier médical de l'assuré?">
                                <p className="m-0">
                                    <ul>
                                        <li>
                                            Identifier l’assuré par reconnaissance faciale ou à l’aide de sa carte d’assuré
                                        </li>
                                        <li>
                                            Cliquer en haut à gauche sur Dossier médical
                                        </li>
                                    </ul> 
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment afficher les rendez-vous ?">
                                <p className="m-0">
                                    <ul>
                                        <li>
                                            Cliquer sur menu, ensuite cliquer sur Rendez-vous pour consulter la liste des rendez-vous
                                        </li>
                                    </ul> 
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Numéro de bon pharmacie introuvable!">
                                <p className="m-0">
                                    Vérifiez le SMS envoyé par ACTIVA et insérez le numéro de bon pharmacie 
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Numéro de bon laboratoire introuvable!">
                                <p className="m-0">
                                    Vérifiez le SMS envoyé par ACTIVA et insérez le numéro de bon laboratoire 
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Numéro de bon Optique introuvable!">
                                <p className="m-0">
                                    Vérifiez le SMS envoyé par ACTIVA et insérez le numéro de bon optique 
                                </p>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div className='col-6'>
                        <div className="contact-form">
                            <h2>Créer un ticket</h2>
                            {props.jiraTicketLink && <p>Votre ticket a bien été créé dans le support JIRA. <a href={props.jiraTicketLink.self}>Consulter le ici</a></p>}
                            <form onSubmit={e => createTicket(e)}>
                                <label for="name">Nom:</label>
                                <input type="text" id="name" value={name} onChange={e => setName(e.target.value)} required />

                                <label for="email">Email:</label>
                                <input type="email" id="email" value={email} onChange={e => setEmail(e.target.value)} required />

                                <label for="topic">Sujet:</label>
                                <select id="topic" name="topic" onChange={e => setSubject(e.target.value)} required>
                                    <option selected={subject === "Général"} value="Général">Général</option>
                                    <option selected={subject === "Facturation et paiement"} value="Facturation et paiement">Facturation et paiement</option>
                                    <option selected={subject === "Support Médical"} value="Support Médical">Support Médical</option>
                                    <option selected={subject === "Support Technique"} value="Support Technique">Support Technique</option>
                                    <option selected={subject === "Avis et suggestions"} value="Avis et suggestions">Avis et suggestions</option>
                                </select>

                                <label for="message">Message:</label>
                                <textarea id="message" value={message} onChange={e => setMessage(e.target.value)}  required></textarea>

                                { props.showLoading && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">Un instant...</span>
                                    </Button> }
                                { !props.showLoading &&
                                    <button type="submit" className="btn btn-primary btn-block">Envoyer</button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="phone-numbers">
                <h2>Contactez-nous</h2>
                <p>Pour toute assistance, appellez-nous au:</p>
                <ul>
                    <li> (+243) 0 815 570 000</li>
                </ul>
                <p>
                    Vous pouvez aussi nous contacter via {' '}
                    <a className='text-primary text-underline' href="https://wa.me/2430900000567">WhatsApp</a>
                </p>
            </div>
        </div> 
    )
}
const mapStateToProps = (state) => {
    return {
       successMessage: state.dataDashboard.successMessage,
       errorMessage: state.dataDashboard.errorMessage,
       showLoading: state.dataDashboard.showLoading,
       jiraTicketLink: state.dataDashboard.jiraTicketLink,
    };
};
export default connect(mapStateToProps)(Help);