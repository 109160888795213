import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import './EtatsGlobaux.css';
import { usePDF, Margin } from 'react-to-pdf';
import { Button } from 'primereact/button';
import { useReactToPrint } from 'react-to-print';
import PieChartNew from '../../chart/PieChartNew';
import HorizontalBarChartNew from '../../chart/HorizontalBarChartNew';



const EtatsVisitesNew = (props) => {
    const { t } = useTranslation();
    const { data, showLoading, clientIds, dateMin, dateMax, clientName, ref } = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media print {
          .p-datatable th, .p-datatable td {
            padding: 8px !important;
            font-size: 10px !important;
          }
        }
      `,
      // Ajoute une entête sur chaque page du PDF
      onBeforeGetContent: () => {
        const doc = document.querySelector('.p-datatable');
        if (doc) {
          // Applique des sauts de page personnalisés et autres styles
          let pageBreaks = document.querySelectorAll('.p-datatable-pagebreak');
          pageBreaks.forEach((breakElement) => breakElement.remove()); // Nettoie les sauts de page existants
          let rows = doc.querySelectorAll('.p-datatable-row');
          for (let i = 15; i < rows.length; i += 15) {
            let pageBreak = document.createElement('div');
            pageBreak.classList.add('p-datatable-pagebreak');
            rows[i].parentNode.insertBefore(pageBreak, rows[i]);
          }
        }
      },
    });

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataAmbuSeries, setDataAmbuSeries] = useState([]);
    const [dataAmbuSerie2, setDataAmbuSerie2] = useState([]);
    const [dataHospiSeries, setDataHospiSeries] = useState([]);
    const [dataHospiSerie2, setDataHospiSerie2] = useState([]);
    const [dataAmbuLabels, setDataAmbuLabels] = useState([]);
    const [dataHospiLabels, setDataHospiLabels] = useState([]);
    const [graph, setGraph] = useState(false);

    useEffect(() => {
        if (data.length > 0) {
            setStatsDatas(data.map(key => {
                return {
                    statName: key.providerName,
                    totalMontant: key["total"]["nbreVisites"],
                    totalPourcent: parseFloat(key["total"]["taux"]),
                    ambuMontant: key["ambulatoire"]["nbreVisites"],
                    ambuPourcent: parseFloat(key["ambulatoire"]["taux"]),
                    hospiMontant: key["hospitalier"]["nbreVisites"],
                    hospiPourcent: parseFloat(key["hospitalier"]["taux"]),

                }
            }))

            let prestsAmbu = data.filter(elt => elt.ambulatoire.nbreVisites > 0).sort((a, b) => b.ambulatoire.nbreVisites - a.ambulatoire.nbreVisites);
            let prestsHospi = data.filter(elt => elt.hospitalier.nbreVisites > 0).sort((a, b) => b.hospitalier.nbreVisites - a.hospitalier.nbreVisites);

            setDataAmbuSeries([{
                data: prestsAmbu.map(key => {
                    return key["ambulatoire"]["nbreVisites"]
                })
            }]);

            setDataHospiSeries([{
                data: prestsHospi.map(key => {
                    return key["hospitalier"]["nbreVisites"]
                })
            }]);
            setDataAmbuLabels(prestsAmbu.map(key => key.providerName));
            setDataHospiLabels(prestsHospi.map(key => key.providerName));

            setDataAmbuSerie2(
                 prestsAmbu.map(key => {
                    return key["ambulatoire"]["nbreVisites"]
                })
            );

            setDataHospiSerie2(
                prestsHospi.map(key => {
                    return key["hospitalier"]["nbreVisites"]
                })
            );
        }
    }, [data]);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Prestataire" rowSpan={2} />
                <Column header="Total" colSpan={2} />
                <Column header="Ambulatoire" colSpan={2} />
                <Column header="Hospitalier" colSpan={2} />
            </Row>
            <Row>
                <Column header="Nombre" field="totalMontant" />
                <Column header="Taux en %" field="totalPourcen" />
                <Column header="Nombre" field="ambuMontant" />
                <Column header="Taux en %" field="ambuPourcen" />
                <Column header="Nombre" field="hospiMontant" />
                <Column header="Taux en %" field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );

    const dt = useRef(null);

    // Fonction pour exporter en Excel
    const transformData = (data) => {
        return data.map(item => ({
            prestataire: item.statName,
            Total_Montant: item.totalMontant,
            Total_Pourcentage: item.totalPourcent,
            Ambulance_Montant: item.ambuMontant,
            Ambulance_Pourcentage: item.ambuPourcent,
            Hospitalisation_Montant: item.hospiMontant,
            Hospitalisation_Pourcentage: item.hospiPourcent

        }));
    };

 

    return (
        <>
            {Object.keys(data).length === 0 && <span>
                {t("common.no-data-display")}
            </span>
            }
            {Object.keys(data).length > 0 && 
            <>
             <div style={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "3em", paddingLeft: "2em", paddingRight: "2em", paddingTop: "1em", }}>
                        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                            <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "1.5em", marginBottom: "0.5em", }}>3- Etats visites des prestataires</div>
                            <DataTable
                                value={statsDatas}
                                showGridlines
                                headerColumnGroup={headerGroup}
                                tableStyle={{ minWidth: '50rem' }}
                                ref={ref}
                            >
                                <Column field="statName"></Column>
                                <Column field="totalMontant"></Column>
                                <Column field="totalPourcent"></Column>
                                <Column field="ambuMontant"></Column>
                                <Column field="ambuPourcent"></Column>
                                <Column field="hospiMontant"></Column>
                                <Column field="hospiPourcent"></Column>
                            </DataTable>
                        </div>
                    </div>
                    <div style={{ marginLeft: "2.5em", marginTop:50 }}>
                        <div style={{ width: "100%", alignContent: "center", marginLeft: 50 }}>
                            <div style={{ alignSelf: "center", marginLeft: 20 }} >
                                <div className='col-15'>
                                    <HorizontalBarChartNew dt={dt} pieWidth="700" ref={ref} type="" title={t("common.outpatient-atte")} series={dataAmbuSerie2} labels={dataAmbuLabels} height="150" legendPosition="left" />
                                </div>
                                <div className='col-15'>
                                    <HorizontalBarChartNew dt={dt} pieWidth="700" ref={ref} type="" title={t("common.admission-hospi")} series={dataHospiSerie2} labels={dataHospiLabels} height="150" legendPosition="left" />
                                </div>
                            </div>
                        </div>
                    </div>
            </>
            
            }
        </>
    )
}
export default EtatsVisitesNew;