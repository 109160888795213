import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import './EtatsGlobaux.css';
import { usePDF, Margin } from 'react-to-pdf';
import { Button } from 'primereact/button';
import { useReactToPrint } from 'react-to-print';
import PieChart from '../../chart/PieChart';
import PieChartNew from '../../chart/PieChartNew';

const EtatsConsommationsNew = (props) => {
    const { t } = useTranslation();
    const dt = useRef(null);
    const { data, showLoading, clientIds, dateMin, dateMax, clientName, ref } = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media print {
          .p-datatable th, .p-datatable td {
            padding: 8px !important;
            font-size: 10px !important;
          }
        }
      `,
      // Ajoute une entête sur chaque page du PDF
      onBeforeGetContent: () => {
        const doc = document.querySelector('.p-datatable');
        if (doc) {
          // Applique des sauts de page personnalisés et autres styles
          let pageBreaks = document.querySelectorAll('.p-datatable-pagebreak');
          pageBreaks.forEach((breakElement) => breakElement.remove()); // Nettoie les sauts de page existants
          let rows = doc.querySelectorAll('.p-datatable-row');
          for (let i = 15; i < rows.length; i += 15) {
            let pageBreak = document.createElement('div');
            pageBreak.classList.add('p-datatable-pagebreak');
            rows[i].parentNode.insertBefore(pageBreak, rows[i]);
          }
        }
      },
    });

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataSeries, setDataSeries] = useState([]);
    const [dataLabels, setDataLabels] = useState([]);
    const [graph, setGraph] = useState(false);



    const rowLabels = {
        totalPrestations: "Montant total des prestations",
        totalRejete: "Montant total rejeté",
        totalPaye: "Montant total à payer",
        nbrePrestataire: "Nombre total de prestataires",
        nbreVisite: "Nombre total de visites",
    }

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setStatsDatas(Object.keys(data).map(key => {
                return {
                    statName: rowLabels[key],
                    totalMontant: parseFloat(data[key]["total"]["montant"]),
                    totalPourcent: parseFloat(data[key]["total"]["taux"]),
                    ambuMontant: parseFloat(data[key]["ambulatoire"]["montant"]),
                    ambuPourcent: parseFloat(data[key]["ambulatoire"]["taux"]),
                    hospiMontant: parseFloat(data[key]["hospitalier"]["montant"]),
                    hospiPourcent: parseFloat(data[key]["hospitalier"]["taux"]),

                }
            }));

            setDataSeries([
                parseFloat(data.totalRejete.ambulatoire.montant),
                parseFloat(data.totalRejete.hospitalier.montant),
                parseFloat(data.totalPaye.ambulatoire.montant),
                parseFloat(data.totalPaye.hospitalier.montant)
            ]);
            setDataLabels([
                "Montant Rejeté en ambulatoire",
                "Montant Rejeté en hospitalier",
                "Montant Payé en ambulatoire",
                "Montant Payé en hospitalier",
            ]);
        }
    }, [data]);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="" rowSpan={2} />
                <Column header="Total" colSpan={2} />
                <Column header="Ambulatoire" colSpan={2} />
                <Column header="Hospitalier" colSpan={2} />
            </Row>
            <Row>
                <Column header="Montant/Nbre" field="totalMontant" />
                <Column header="Taux en %" field="totalPourcen" />
                <Column header="Montant/Nbre" field="ambuMontant" />
                <Column header="Taux en %" field="ambuPourcen" />
                <Column header="Montant/Nbre" field="hospiMontant" />
                <Column header="Taux en %" field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );

    // Fonction pour exporter en Excel
    const transformData = (data) => {
        return data.map(item => ({
            _: item.statName,
            Total_Montant: item.totalMontant,
            Total_Pourcentage: item.totalPourcent,
            Ambulance_Montant: item.ambuMontant,
            Ambulance_Pourcentage: item.ambuPourcent,
            Hospitalisation_Montant: item.hospiMontant,
            Hospitalisation_Pourcentage: item.hospiPourcent,

        }));
    };


    const exportExcel = () => {
        import('xlsx').then((xlsx) => {

            const worksheet = xlsx.utils.json_to_sheet(transformData(statsDatas), { skipHeader: false });
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, 'Etats_Détaillés_des_Consommations');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    //fichier csv
    const exportToCSVe = (datacsv) => {
        const csvRows = [];
        const headers = [
            '',
            'Total Nbre Visites',
            'Total Taux',
            'Ambulatoire Nbre Visites',
            'Ambulatoire Taux',
            'Hospitalier Nbre Visites',
            'Hospitalier Taux'
        ];
        csvRows.push(headers.join(','));

        datacsv.forEach(item => {
            const row = [
                item.statName,
                item.totalMontant,
                item.totalPourcent,
                item.ambuMontant,
                item.ambuPourcent,
                item.hospiMontant,
                item.hospiPourcent

            ];
            csvRows.push(row.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Etats_Détaillés_des_Consommations.csv');
        a.click();
    };

    //pdf
    const { toPDF, targetRef } = usePDF({ filename: 'Etats_Détaillés _des_Consommations.pdf', page: { margin: Margin.SMALL } })




    return (
        <>
            {Object.keys(data).length === 0 && <span>
                Aucune donnée à afficher, veuiller remplir les filtres afin d'en avoir.
            </span>
            }
            {Object.keys(data).length > 0 && 
            <>
             <div style={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "3em", paddingLeft: "2em", paddingRight: "2em", paddingTop: "1em", }}>
                        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                            <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "1.5em", marginBottom: "0.5em", }}>2- ETAT CONSOMMATION</div>
                            <DataTable
                                value={statsDatas}
                                showGridlines
                                headerColumnGroup={headerGroup}
                                tableStyle={{ minWidth: '50rem' }}
                                ref={ref}
                            >
                                <Column bodyStyle={{ color: 'red', fontSize: '25.5rem' }} field="statName"></Column>
                                <Column field="totalMontant"></Column>
                                <Column field="totalPourcent"></Column>
                                <Column field="ambuMontant"></Column>
                                <Column field="ambuPourcent"></Column>
                                <Column field="hospiMontant"></Column>
                                <Column field="hospiPourcent"></Column>
                            </DataTable>
                        </div>
                    </div>
                    <div style={{ marginLeft: "1.1em" }}>
                        <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginLeft: "4.8em", marginTop: "1.5em", marginBottom: "0.5em", }}></div>
                        <div style={{ width: "95%", paddingLeft: "5.8em", }}>
                            <PieChartNew title="Graphe" series={dataSeries} pieWidth="700" labels={dataLabels} legendPosition="left" />
                        </div>
                    </div>
            </>
            }
        </>
    )
}
export default EtatsConsommationsNew;