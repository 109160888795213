import {registerProvider,
     getAllProviders,
     updateProviderInfos, 
     suspendProvider, 
     getActsFamilies,
     getProviderType, 
     getProviderSpecialities,
     getLettreCle, 
     registerProviderSpecialities,
     getAllTarifs,
     setProviderTarification,
     setProviderUpdatedTarification,
     setProviderNewTarification,
     importProviderTypeFile,
     importProviderSpecialityFile,
     createProviderSpeciality,
     createLettreCle,
     importLettreCleFile,
     getTypeActe,
     getTarifs,
     createTypeActe,
     createActe,
     getActes,
     getGaranties,
     importActeFile,
     getTypeActeurs,
     createProviderType,
     registerProviderBank,
     registerProviderMobileMoney,
     getProvider,
     createTarif,
     updateTarif,
     updateActe,
     searchActes,
     getActesPages,
     createFamilleActe,
     getFamilleActe,
     createMedicament,
     updateMedicament,
     getMedicamentsPages,
     importMedicamentFile,
     deleteParam,
     removeTarif,
     updateProviderType,
     updateProviderSpeciality,
     updateFamilleActe,
     updateTypeActe,
     updateLettreCle,
     importTarifFile,
     importFamilleActeFile,
     importTypeActeFile,
     importPaysFile,
     importVilleFile,
     importActiviteFile,
     importProfessionFile,
     importExclusionFile,
     importProvincesFile,
     searchActesConsult,
     getParametreOptique,
     getMonture,
     createParametreOptique,
     updateParametreOptique,
     createMonture,
     updateMonture,
     updateOpticPrizeGrid,
     getOpticPrizeGrid,
     deleteParametreOptique,
     getTarifsMedicaments,
     createTarifMedicament,
     updateTarifMedicament,
     deleteTarifMedicament,
     assignTarifMedicament,
     removeTarifMedicament,
     getProviderTarifs,
     getProviderTarifsMedicaments,
     getTypesActeControls,
     createTypesActeControls,
     deleteTypesActeControls,
     getActeControls,
     createActeControls,
     updateActeControls,
     deleteActeControls
    } from '../../services/ProviderService';
import blobFileUpload from '../../services/BlobStorageService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';

export const REGISTER_PROVIDER_CONFIRMED_ACTION = '[registerProvider action] confirmed registerProvider';
export const REGISTER_PROVIDER_FAILED_ACTION = '[registerProvider action] failed registerProvider';
export const GET_PROVIDER_CONFIRMED_ACTION = '[getProviders action] confirmed getProviders';
export const GET_PROVIDER_FAILED_ACTION = '[getProviders action] failed getProviders';
export const GET_PROVIDER_DATA_CONFIRMED_ACTION = '[getProvider action] confirmed getProvider';
export const GET_PROVIDER_DATA_FAILED_ACTION = '[getProvider action] failed getProvider';
export const UPDATE_PROVIDER_CONFIRMED_ACTION = '[updateProvider action] confirmed updateProvider';
export const UPDATE_PROVIDER_FAILED_ACTION = '[updateProvider action] failed updateProvider';
export const SUSPEND_PROVIDER_CONFIRMED_ACTION = '[suspendProvider action] confirmed suspendProvider';
export const SUSPEND_PROVIDER_FAILED_ACTION = '[suspendProvider action] failed suspendProvider';
export const START_LOADER_ACTION = '[providerLoader action] started providerLoader';
export const GET_FAMILIES_CONFIRMED_ACTION = '[getFamilies action] confirmed getFamilies';
export const GET_FAMILIES_FAILED_ACTION = '[getFamilies action] failed getFamilies';
export const GET_PROVIDER_TYPES_CONFIRMED_ACTION = '[getProviderType action] confirmed getProviderType';
export const GET_PROVIDER_TYPES_FAILED_ACTION = '[getProviderType action] failed getProviderType';
export const GET_PROVIDER_SPECIALITIES_CONFIRMED_ACTION = '[getSpecialities action] confirmed getSpecialities';
export const GET_PROVIDER_SPECIALITIES_FAILED_ACTION = '[getSpecialities action] failed getSpecialities';
export const REGISTER_PROVIDER_SPECIALITIES_CONFIRMED_ACTION = '[registerProviderSpecialities action] confirmed registerProviderSpecialities';
export const REGISTER_PROVIDER_SPECIALITIES_FAILED_ACTION = '[registerProviderSpecialities action] failed registerProviderSpecialities';
export const GET_ALL_TARIFS_CONFIRMED_ACTION = '[getAllTarifs action] confirmed getAllTarifs';
export const GET_ALL_TARIFS_FAILED_ACTION = '[getAllTarifs action] failed getAllTarifs';
export const GET_ALL_TARIFS_MEDICAMENT_CONFIRMED_ACTION = '[getAllTarifsMedicaments action] confirmed getAllTarifsMedicaments';
export const GET_ALL_TARIFS_MEDICAMENT_FAILED_ACTION = '[getAllTarifsMedicaments action] failed getAllTarifsMedicaments';
export const GET_ALL_OPTIC_PARAMS_CONFIRMED_ACTION = '[getAllOpticParams action] confirmed getAllOpticParams';
export const GET_ALL_OPTIC_PARAMS_FAILED_ACTION = '[getAllOpticParams action] failed getAllOpticParams';
export const GET_ALL_MONTURE_CONFIRMED_ACTION = '[getAllMonture action] confirmed getAllMonture';
export const GET_ALL_MONTURE_FAILED_ACTION = '[getAllMonture action] failed getAllMonture';
export const GET_OPTIC_PRIZE_GRID_CONFIRMED_ACTION = '[getOpticPrizeGrid action] confirmed getOpticPrizeGrid';
export const GET_OPTIC_PRIZE_GRID_FAILED_ACTION = '[getOpticPrizeGrid action] failed getOpticPrizeGrid';
export const UPDATE_OPTIC_PRIZE_GRID_CONFIRMED_ACTION = '[updateOpticPrizeGrid action] confirmed updateOpticPrizeGrid';
export const UPDATE_OPTIC_PRIZE_GRID_FAILED_ACTION = '[updateOpticPrizeGrid action] failed updateOpticPrizeGrid';
export const DELETE_OPTIC_PARAM_CONFIRMED_ACTION = '[deleteOpticParam action] confirmed deleteOpticParam';


export const UPDATE_PROVIDER_TARIFICATION_CONFIRMED_ACTION = '[updateProviderTarification action] confirmed updateProviderTarification';
export const UPDATE_PROVIDER_TARIFICATION_FAILED_ACTION = '[updateProviderTarification action] failed updateProviderTarification';
export const UPDATE_PROVIDER_UPDATED_TARIFICATION_CONFIRMED_ACTION = '[updateProviderUpdatedTarification action] confirmed updateProviderUpdatedTarification';
export const UPDATE_PROVIDER_UPDATED_TARIFICATION_FAILED_ACTION = '[updateProviderUpdatedTarification action] failed updateProviderUpdatedTarification';
export const UPDATE_PROVIDER_NEW_TARIFICATION_CONFIRMED_ACTION = '[updateProviderNewTarification action] confirmed updateProviderNewTarification';
export const UPDATE_PROVIDER_NEW_TARIFICATION_ACTION = '[updateProviderNewTarification action] failed updateProviderNewTarification';
export const REGISTER_PROVIDER_TYPE_FAILED_ACTION = '[createProviderType action] failed createProviderType';
export const REGISTER_PROVIDER_TYPE_CONFIRMED_ACTION = '[createProviderType action] confirmed createProviderType';
export const FILE_IMPORT_CONFIRMED_ACTION = '[importProviderType action] confirmed importProviderType';
export const FILE_IMPORT_FAILED_ACTION = '[importProviderType action] failed importProviderType';
export const REGISTER_PROVIDER_SPECIALITY_FAILED_ACTION = '[createProviderSpeciality action] failed createProviderSpeciality';
export const REGISTER_PROVIDER_SPECIALITY_CONFIRMED_ACTION = '[createProviderSpeciality action] confirmed createProviderSpeciality';
export const GET_LETTRE_CLE_FAILED_ACTION = '[getLettreCle action] failed getLettreCle';
export const GET_LETTRE_CLE_CONFIRMED_ACTION = '[getLettreCle action] confirmed getLettreCle';
export const REGISTER_LETTRE_CLE_CONFIRMED_ACTION = '[createLettreCle action] confirmed createLettreCle';
export const REGISTER_TYPE_ACTE_CONFIRMED_ACTION = '[createTypeActe action] confirmed createTypeActe';
export const REGISTER_FAMILLE_ACTE_CONFIRMED_ACTION = '[createFamilleActe action] confirmed createFamilleActe';
export const GET_TYPEACTE_CONFIRMED_ACTION = '[getTypeActe action] confirmed getTypeActe';
export const GET_FAMILLEACTE_CONFIRMED_ACTION = '[getFamilleActe action] confirmed getFamilleActe';
export const GET_ACTE_CONFIRMED_ACTION = '[getActes action] confirmed getActes';
export const GET_ACTE_PAGES_CONFIRMED_ACTION = '[getActesPages action] confirmed getActesPages';
export const GET_TARIFS_CONFIRMED_ACTION = '[getTarifs action] confirmed getTarifs';
export const GET_ACTEUR_CONFIRMED_ACTION = '[getActeurs action] confirmed getActeurs';
export const GET_GARANTIES_CONFIRMED_ACTION = '[getGaranties action] confirmed getGaranties';
export const CREATE_NEW_TARFIF_CONFIRMED_ACTION = '[createTarif action] confirmed createTarif';
export const CREATE_NEW_TARFIF_MEDICAMENT_CONFIRMED_ACTION = '[createTarifMedicament action] confirmed createTarifMedicament';
export const UPDATE_TARFIF_CONFIRMED_ACTION = '[updateTarif action] confirmed updateTarif';
export const UPDATE_TARFIF_MEDICAMENT_CONFIRMED_ACTION = '[updateTarifMedicament action] confirmed updateTarifMedicament';
export const UPDATE_ACTE_CONFIRMED_ACTION = '[updateActe action] confirmed updateActe';
export const CREATE_ACTE_CONFIRMED_ACTION = '[createActe action] confirmed createActe';

export const REGISTER_PROVIDER_BANK_FAILED_ACTION = '[createProviderBank action] failed createProviderBank';
export const REGISTER_PROVIDER_BANK_CONFIRMED_ACTION = '[createProviderBank action] confirmed createProviderBank';

export const SEARCH_ACTES_CONFIRMED_ACTION = '[searchActes action] confirmed searchActes';
export const SEARCH_ACTES_FAILED_ACTION = '[searchActes action] failed searchActes';

export const SEARCH_ACTES_CONSULT_CONFIRMED_ACTION = '[searchActesConsult action] confirmed searchActesConsult';
export const SEARCH_ACTES_CONSULT_FAILED_ACTION = '[searchActesConsult action] failed searchActesConsult';

export const CREATE_MEDICAMENT_CONFIRMED_ACTION = '[createMedicament action] confirmed createMedicament';
export const UPDATE_MEDICAMENT_CONFIRMED_ACTION = '[updateMedicament action] confirmed updateMedicament';
export const GET_MEDICAMENT_CONFIRMED_ACTION = '[getMedicaments action] confirmed getMedicaments';

export const DELETE_DATA_CONFIRMED_ACTION = '[deleteData action] confirmed deleteData';
export const DELETE_DATA_FAILED_ACTION = '[deleteData action] failed deleteData';

export const REMOVE_TARIF_CONFIRMED_ACTION = '[removeTarif action] confirmed removeTarif';
export const REMOVE_TARIF_FAILED_ACTION = '[removeTarif action] failed removeTarif';

export const REMOVE_TARIF_MEDICAMENT_CONFIRMED_ACTION = '[removeTarifMedicament action] confirmed removeTarifMedicament';
export const REMOVE_TARIF_MEDICAMENT_FAILED_ACTION = '[removeTarifMedicament action] failed removeTarifMedicament';

export const DELETE_TARIF_MEDICAMENT_CONFIRMED_ACTION = '[deleteTarifMedicament action] confirmed deleteTarifMedicament';
export const DELETE_TARIF_MEDICAMENT_FAILED_ACTION = '[deleteTarifMedicament action] failed deleteTarifMedicament';

export const UPDATE_PROVIDER_TYPE_CONFIRMED_ACTION = '[updateProviderType action] confirmed updateProviderType';
export const UPDATE_PROVIDER_TYPE_FAILED_ACTION = '[updateProviderType action] failed updateProviderType';

export const UPDATE_SPECIALITY_CONFIRMED_ACTION = '[updateSpeciality action] confirmed updateSpeciality';
export const UPDATE_SPECIALITY_FAILED_ACTION = '[updateSpeciality action] failed updateSpeciality';

export const UPDATE_FAMILLE_ACTES_CONFIRMED_ACTION = '[updateFamilleActes action] confirmed updateFamilleActes';
export const UPDATE_FAMILLE_ACTES_FAILED_ACTION = '[updateFamilleActes action] failed updateFamilleActes';

export const UPDATE_TYPE_ACTES_CONFIRMED_ACTION = '[updateTypeActes action] confirmed updateTypeActes';
export const UPDATE_TYPE_ACTES_FAILED_ACTION = '[updateTypeActes action] failed updateTypeActes';

export const UPDATE_LETTRE_CLE_CONFIRMED_ACTION = '[updateLettreCle action] confirmed updateLettreCle';
export const UPDATE_LETTRE_CLE_FAILED_ACTION = '[updateLettreCle action] failed updateLettreCle';

export const GET_TARIFS_PROVIDER_CONFIRMED_ACTION = '[getTarifsProvider action] confirmed getTarifsProvider';
export const GET_TARIFS_PROVIDER_FAILED_ACTION = '[getTarifsProvider action] confirmed getTarifsProvider';

export const GET_TARIFS_PROVIDER_MED_CONFIRMED_ACTION = '[getTarifsMedicamentProvider action] confirmed getTarifsMedicamentProvider';
export const GET_TARIFS_PROVIDER_MED_FAILED_ACTION = '[getTarifsMedicamentProvider action] confirmed getTarifsMedicamentProvider';

export const GET_TYPE_ACTES_CONTROLS_CONFIRMED_ACTION = '[getTypeActesControls action] confirmed getTypeActesControls';
export const GET_TYPE_ACTES_CONTROLS_FAILED_ACTION = '[getTypeActesControls action] failed getTypeActesControls';

export const CREATE_TYPE_ACTES_CONTROLS_FAILED_ACTION = '[createTypeActesControls action] failed createTypeActesControls';

export const DELETE_TYPE_ACTES_CONTROLS_FAILED_ACTION = '[deleteTypeActesControls action] failed deleteTypeActesControls';

export const GET_ACTES_CONTROLS_CONFIRMED_ACTION = '[getActesControls action] confirmed getActesControls';
export const GET_ACTES_CONTROLS_FAILED_ACTION = '[getActesControls action] failed getActesControls';
export const CREATE_ACTES_CONTROLS_CONFIRMED_ACTION = '[createActesControls action] confirmed createActesControls';
export const UPDATE_ACTES_CONTROLS_CONFIRMED_ACTION = '[updateActesControls action] confirmed updateActesControls';
export const DELETE_ACTES_CONTROLS_CONFIRMED_ACTION = '[deleteActesControls action] confirmed deleteActesControls';

var I18n = require('react-redux-i18n').I18n;

export const createProviderAction = (imageFile = null, providerDatas, history) => {
    return async (dispatch) => {

        if (imageFile) {
            let resImg = await blobFileUpload([imageFile], 'amp-providers-container');
            providerDatas['providerPhotoUrl'] = resImg[0];
        }


        registerProvider(providerDatas).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            
            dispatch(confirmedProviderRegisterAction(response.data));
            history.push('/provider-list');
        })
        .catch((error) => {
            
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(clientProviderFailedAction(error.response?.data?.message));
        });
    };
};
export const createProviderTypeAction = (providerTypeDatas) => {
    return async (dispatch) => {

        createProviderType(providerTypeDatas).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            
            dispatch(confirmedProviderTypeRegisterAction(response.data));
            //history.push('/provider-list');
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(registerProviderTypeFailedAction(error.response?.data?.message));
        });
    };
};
export const listProvidersAction = () => {
    
    return (dispatch) => {
        getAllProviders()
        .then((response) => {
            
            dispatch(confirmedProviderListAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.list_providers_error')+error.response?.data?.message);
            dispatch(providerListFailedAction(error.response?.data?.message));
        });
    };
};

export const getProviderDataAction = (id, history) => {
    
    return (dispatch) => {
        getProvider(id)
        .then((response) => {
            
            dispatch(confirmedGetProviderAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_error')+error.response?.data?.message);
            dispatch(getProviderFailedAction(error.response?.data?.message));
            history.goBack();
        });
    };
};

export const updateProviderAction = (imageFile = null, providerId, providerUpdateDatas, history) => {
    return async (dispatch) => {

        if (imageFile) {
            let resImg = await blobFileUpload([imageFile], 'amp-providers-container');
            providerUpdateDatas['providerPhotoUrl'] = resImg[0];
        }

        updateProviderInfos(providerId, providerUpdateDatas).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            
            dispatch(confirmedProviderUpdateAction(response.data));
            history.push('/provider-list');
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(providerUpdateFailedAction(error.response?.data?.message));
        });
    };
};

export const suspendProviderAction = (providerId) => {
    return (dispatch) => {

        suspendProvider(providerId).then((response) => {
            successToastr(I18n.t('provider.delete_success'));
            
            dispatch(confirmedProviderSuspendAction(providerId));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.delete_error')+error.response?.data?.message);
            dispatch(providerSuspendFailedAction(error.response?.data?.message));
        });
    };
};

export const removeTarifProviderAction = (providerId, tarifId) => {
    return (dispatch) => {

        removeTarif(providerId, tarifId).then((response) => {
            successToastr(I18n.t('provider.remove_tarif_success'));
            
            dispatch(confirmedRemoveTarifAction(providerId));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.remove_tarif_error')+error.response?.data?.message);
            dispatch(removeTarifFailedAction(error.response?.data?.message));
        });
    };
};

export const removeTarifMedicamentProviderAction = (providerId, tarifId) => {
    return (dispatch) => {

        removeTarifMedicament(tarifId).then((response) => {
            successToastr(I18n.t('provider.remove_tarif_success'));
            
            dispatch(confirmedRemoveTarifMedicamentAction(providerId));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.remove_tarif_error')+error.response?.data?.message);
            dispatch(removeTarifMedicamentFailedAction(error.response?.data?.message));
        });
    };
};

export const deleteTarifMedicamentProviderAction = (tarifId) => {
    return (dispatch) => {

        deleteTarifMedicament(tarifId).then((response) => {
            successToastr(I18n.t('provider.remove_tarif_success'));
            
            dispatch(confirmedDeleteTarifMedicamentAction(tarifId));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.remove_tarif_error')+error.response?.data?.message);
            dispatch(deleteTarifMedicamentFailedAction(error.response?.data?.message));
        });
    };
};

export const getActsByFamiliesAction = () => {
    return (dispatch) => {
        getActsFamilies().then((response) => {
            
            dispatch(confirmedGetFamiliesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.famille_act_error')+error.response?.data?.message);
            dispatch(getFamiliesFailedAction(error.response?.data?.message));
        });
    };
};

export const getProvidersTypesAction = () => {
    return (dispatch) => {
        getProviderType().then((response) => {
            
            dispatch(confirmedGetProviderTypesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_type_error')+error.response?.data?.message);
            dispatch(getProviderTypesFailedAction(error.response?.data?.message));
        });
    };
};

export const listSpecialitiesAction = () => {
    return (dispatch) => {
        getProviderSpecialities().then((response) => {
            
            dispatch(confirmedGetProviderSpecialitiesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_specialite_error')+error.response?.data?.message);
            dispatch(getProviderSpecialitiesFailedAction(error.response?.data?.message));
        });
    };
};

export const addProviderSpecialities = (specData, history) => {
    return (dispatch) => {
        registerProviderSpecialities(specData).then((response) => {
            
            dispatch(confirmedRegisterProviderSpecialitiesAction(response.data));
            history.push('/provider-view/'+specData[0].providerId);
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.add_specialite')+error.response?.data?.message);
            dispatch(registerProviderSpecialitiesFailedAction(error.response?.data?.message));
        });
    };
};
export const createProviderSpecialityAction = (providerSpecialityDatas) => {
    return async (dispatch) => {

        createProviderSpeciality(providerSpecialityDatas).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            //
            dispatch(confirmedProviderSpecialityRegisterAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(registerProviderSpecialityFailedAction(error.response?.data?.message));
        });
    };
};
export const ProviderTypeFileUploadAction = (file) => {
    return (dispatch) => {
        importProviderTypeFile(file).then((response) => {
            //
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};
export const ProviderSpecialityFileUploadAction = (file) => {
    return (dispatch) => {
        importProviderSpecialityFile(file).then((response) => {
            //
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};

export const getAllTarifsAction = () => {
    return (dispatch) => {
        getAllTarifs().then((response) => {
            
            dispatch(confirmedGetAllTarifsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_tarif_error')+error.response?.data?.message);
            dispatch(getAllTarifsFailedAction(error.response?.data?.message));
        });
    };
};

export const getAllTarifsMedicamentAction = () => {
    return (dispatch) => {
        getTarifsMedicaments().then((response) => {
            
            dispatch(confirmedGetAllTarifsMedicamentAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_tarif_error')+error.response?.data?.message);
            dispatch(getAllTarifsMedicamentFailedAction(error.response?.data?.message));
        });
    };
};


export const updateProviderTarificationAction = (mode, data, history) => {
    return async (dispatch) => {
        

        let resFile = await blobFileUpload([data.conventionFile], 'amp-providers-container');

        data['url'] = resFile[0];

        

        switch(mode) {
            case 'attribution':
                return setProviderTarification(data).then((response) => {
                    successToastr(I18n.t('provider.add_tarif_success'));
                    
                    dispatch(confirmedProviderUpdateTarificationAction(response.data));
                    history.push('/provider-view/'+data.providerId);
                })
                .catch((error) => {
                    errorToastr(I18n.t('provider.add_tarif_error')+error.response?.data?.message);
                    dispatch(providerUpdateTarificationFailedAction(error.response?.data?.message));
                });
            case 'update':
                return setProviderUpdatedTarification(data.tarifProviderId, data).then((response) => {
                    successToastr(I18n.t('provider.update_tarif_success'));
                    
                    dispatch(confirmedProviderUpdateTarificationUpdatedAction(response.data));
                    history.push('/provider-view/'+data.providerId);
                })
                .catch((error) => {
                    errorToastr(I18n.t('provider.update_tarif_error')+error.response?.data?.message);
                    dispatch(providerUpdateTarificationUpdatedFailedAction(error.response?.data?.message));
                });
            default:
                return setProviderNewTarification(data).then((response) => {
                    successToastr(I18n.t('provider.create_attr_success'));
                    
                    dispatch(confirmedProviderUpdateNewTarificationAction(response.data));
                    history.push('/provider-view/'+data.providerId);
                })
                .catch((error) => {
                    errorToastr(I18n.t('provider.create_attr_error')+error.response?.data?.message);
                    dispatch(providerUpdateNewTarificationFailedAction(error.response?.data?.message));
                });
        }

    };
};

export const updateProviderMedicamentTarificationAction = (data, history) => {
    return async (dispatch) => {
        let resFile = await blobFileUpload([data.conventionFile], 'amp-providers-container');

        data['urlConvention'] = resFile[0];

        assignTarifMedicament(data).then((response) => {
            successToastr(I18n.t('provider.add_tarif_success'));
            
            dispatch(confirmedProviderUpdateTarificationMedicamentAction(response.data));
            history.push('/provider-view/'+data.providerId);
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.add_tarif_error')+error.response?.data?.message);
            dispatch(providerUpdateTarificationMedicamentFailedAction(error.response?.data?.message));
        });

    };
};

export const createLettreCleAction = (datas) => {
    return async (dispatch) => {

        createLettreCle(datas).then((response) => {
            successToastr(I18n.t('provider.create_success'));
           // 
            dispatch(confirmedRegisterLettreCleAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            //dispatch(registerProviderTypeFailedAction(error.response?.data?.message));
        });
    };
};
export const getLettreCleAction = () => {
    return (dispatch) => {
        getLettreCle().then((response) => {
            //
            dispatch(confirmedGetLettreCleAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_lettre_cle_error')+error.response?.data?.message);
            dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};
export const getTarifsAction = () => {
    return (dispatch) => {
        getTarifs().then((response) => {
            dispatch(confirmedGetTarifsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_tarif_error')+error.response?.data?.message);
            dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};
export const getTarifsMedicamentAction = () => {
    return (dispatch) => {
        getTarifsMedicaments().then((response) => {
            dispatch(confirmedGetTarifsMedicamentAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_tarif_error')+error.response?.data?.message);
            dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};
export const createTypeActeAction = (providerSpecialityDatas) => {
    return async (dispatch) => {

        createTypeActe(providerSpecialityDatas).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedTypeActeRegisterAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            //dispatch(registerProviderTypeFailedAction(error.response?.data?.message));
        });
    };
};
export const createFamilleActeAction = (providerSpecialityDatas) => {
    return async (dispatch) => {

        createFamilleActe(providerSpecialityDatas).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFamilleActeRegisterAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            //dispatch(registerProviderTypeFailedAction(error.response?.data?.message));
        });
    };
};
export const createProviderActeurAction = (providerSpecialityDatas) => {
    return async (dispatch) => {

        createTypeActe(providerSpecialityDatas).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            //dispatch(confirmedProviderTypeRegisterAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            //dispatch(registerProviderTypeFailedAction(error.response?.data?.message));
        });
    };
};
export const getActesAction = () => {
    return (dispatch) => {
        getActes().then((response) => {
            dispatch(confirmedGetActeAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_actes_error')+error.response?.data?.message);
            //dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};
export const getGarantiesAction = () => {
    return (dispatch) => {
        getGaranties().then((response) => {
            dispatch(confirmedGetGarantiesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_garanties_error')+error.response?.data?.message);
            //dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};

export const createTarifAction = (tarifData) => {
    return async (dispatch) => {

        createTarif(tarifData).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedTarifCreatedAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
        });
    };
};

export const createTarifMedicamentAction = (tarifData) => {
    return async (dispatch) => {

        createTarifMedicament(tarifData).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedTarifMedicamentCreatedAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
        });
    };
};
export const getTarifAction = () => {
    return (dispatch) => {
        getTarifs().then((response) => {
            dispatch(confirmedGetTypeActeAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_tarif_error')+error.response?.data?.message);
            //dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};
export const getTypeActesAction = () => {
    return (dispatch) => {
        getTypeActe().then((response) => {
            dispatch(confirmedGetTypeActeAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_type_acte_error')+error.response?.data?.message);
            //dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};
export const getFamilleActesAction = () => {
    return (dispatch) => {
        getFamilleActe().then((response) => {
            dispatch(confirmedGetFamilleActeAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.famille_act_error')+error.response?.data?.message);
            //dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};
export const getProviderActeurAction = () => {
    return (dispatch) => {
        getTypeActeurs().then((response) => {
            dispatch(confirmedGetTypeActeurAction(response.data));
        })
        .catch((error) => {
            errorToastr("Erreur lors de la récupération des types de prestataire: "+error.response?.data?.message);
            //dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};
export const createActeAction = (providerSpecialityDatas) => {
    return async (dispatch) => {

        createActe(providerSpecialityDatas).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedActeCreatedAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            //dispatch(registerProviderTypeFailedAction(error.response?.data?.message));
        });
    };
};
export const getActeAction = () => {
    return (dispatch) => {
        getActes().then((response) => {
            dispatch(confirmedGetActeAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_actes_error')+error.response?.data?.message);
            //dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};
export const getActePagesAction = (page, max) => {
    return (dispatch) => {
        getActesPages(page, max).then((response) => {
            dispatch(confirmedGetActePagesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_actes_error')+error.response?.data?.message);
            //dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};
export const importLettreCleFileAction = (file) => {
    return (dispatch) => {
        importLettreCleFile(file).then((response) => {
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};
export const importActeFileUploadAction = (file) => {
    return (dispatch) => {
        importActeFile(file).then((response) => {
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};

export const addProviderBank = (bankData, history) => {
    return async (dispatch) => {
        if (bankData.bankDetail.fileSrc) {
            let res = await blobFileUpload([bankData.bankDetail.fileSrc], 'amp-providers-container');
            bankData.bankDetail['ribPhoto'] = res[0];
        }

        /*let datas = bankData.paymentMeans.map(async bdata => {
            bdata['providerId'] = bankData.providerId;
            if(bdata.file) {
                let res = await blobFileUpload([bdata.file], 'amp-providers-container');
                bdata['idPhoto'] = res[0];
            }
            return bdata;
        })*/

        let datas = [];
        for (let i = 0; i<bankData.paymentMeans.length; i++){
            let mmdata = bankData.paymentMeans[i];
            mmdata['providerId'] = bankData.providerId;

            if(bankData.paymentMeans[i].file) {
                let res = await blobFileUpload([bankData.paymentMeans[i].file], 'amp-providers-container');
                mmdata['idPhoto'] = res[0];
            }
            datas.push(mmdata);
        }


        let finalData = {
            bankAccount: {providerId: bankData.providerId, ...bankData.bankDetail},
            mobileAccount: datas,
        }

        registerProviderBank(finalData).then((response) => {
            
            dispatch(confirmedRegisterProviderBankAction(response.data));
            history.push('/provider-view/'+bankData.providerId);
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.add_prov_bank_error')+error.response?.data?.message);
            dispatch(registerProviderBankFailedAction(error.response?.data?.message));
        });
    };
};

export const updateTarifAction = (id, tarifData) => {
    return async (dispatch) => {
        updateTarif(id, tarifData).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            response.data['id'] = id;
            dispatch(confirmedTarifUpdatedAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
        });
    };
};

export const updateTarifMedicamentAction = (id, tarifData) => {
    return async (dispatch) => {
        updateTarifMedicament(id, tarifData).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            response.data['id'] = id;
            dispatch(confirmedTarifMedicamentUpdatedAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
        });
    };
};

export const removeTarifMedicamentAction = (id) => {
    return async (dispatch) => {
        removeTarifMedicament(id).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            response.data['id'] = id;
            dispatch(confirmedRemoveTarifMedicamentUpdatedAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
        });
    };
};

export const updateActeAction = (id, acteData) => {
    return async (dispatch) => {

        updateActe(id, acteData).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            response.data['id'] = id;
            dispatch(confirmedActeUpdatedAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
        });
    };
};

export const searchActesAction = (keyword) => {
    
    return (dispatch) => {
        searchActes(keyword)
        .then((response) => {
            
            dispatch(confirmedSearchActesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.search_act_error')+error.response?.data?.message);
            dispatch(searchActesFailedAction(error.response?.data?.message));
            //history.goBack();
        });
    };
};

export const searchActesConsultAction = (keyword, providerId, type) => {
    
    return (dispatch) => {
        searchActesConsult(keyword, providerId, type)
        .then((response) => {
            
            dispatch(confirmedSearchActesConsultAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.search_act_error')+error.response?.data?.message);
            dispatch(searchActesConsultFailedAction(error.response?.data?.message));
            //history.goBack();
        });
    };
};


export const createMedicamentAction = (data) => {
    return async (dispatch) => {

        createMedicament(data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedCreateMedicamentAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            //dispatch(registerProviderTypeFailedAction(error.response?.data?.message));
        });
    };
};

export const updateMedicamentAction = (id, data) => {
    return async (dispatch) => {
        data.id = id;
        updateMedicament(data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            response.data['id'] = id;
            dispatch(confirmedUpdateMedicamentAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            //dispatch(registerProviderTypeFailedAction(error.response?.data?.message));
        });
    };
};

export const getMedicamentsAction = (page, max) => {
    return (dispatch) => {
        getMedicamentsPages(page, max).then((response) => {
            dispatch(confirmedGetMedicamentsPagesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            //dispatch(getLettreCleFailedAction(error.response?.data?.message));
        });
    };
};

export const getTarifsProviderAction = (id) => {
    
    return (dispatch) => {
        getProviderTarifs(id)
        .then((response) => {
            
            dispatch(confirmedGetTarifsProviderAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_error')+error.response?.data?.message);
            dispatch(getTarifsProviderFailedAction(error.response?.data?.message));
        });
    };
};

export const getTarifsMedicamentProviderAction = (id) => {
    
    return (dispatch) => {
        getProviderTarifsMedicaments(id)
        .then((response) => {
            
            dispatch(confirmedGetTarifsMedicamentProviderAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_error')+error.response?.data?.message);
            dispatch(getTarifsMedicamentProviderFailedAction(error.response?.data?.message));
        });
    };
};


export const importMedicamentFileUploadAction = (file) => {
    return (dispatch) => {
        importMedicamentFile(file).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_drugs_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};


export const importTarifFileUploadAction = (file) => {
    return (dispatch) => {
        importTarifFile(file).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_drugs_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};


export const importFamilleActeFileUploadAction = (file) => {
    return (dispatch) => {
        importFamilleActeFile(file).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};


export const importTypeActeFileUploadAction = (file) => {
    return (dispatch) => {
        importTypeActeFile(file).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};


export const importPaysFileUploadAction = (file) => {
    return (dispatch) => {
        importPaysFile(file).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};


export const importVilleFileUploadAction = (file) => {
    return (dispatch) => {
        importVilleFile(file).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};


export const importActiviteFileUploadAction = (file) => {
    return (dispatch) => {
        importActiviteFile(file).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};


export const importProfessionFileUploadAction = (file) => {
    return (dispatch) => {
        importProfessionFile(file).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};


export const importExclusionFileUploadAction = (file) => {
    return (dispatch) => {
        importExclusionFile(file).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};


export const importProvincesFileUploadAction = (file) => {
    return (dispatch) => {
        importProvincesFile(file).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedFileImportAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(failedFileImportAction(error.response?.data?.message));
        });
    };
};


export const deleteDataAction = (data) => {
    
    return (dispatch) => {
        deleteParam(data).then((response) => {
            successToastr(data.type+I18n.t('provider.deleted_success'));
            dispatch(confirmedDeleteDataAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.deleted_error')+data.type+" :"+error.response?.data?.message);
            dispatch(deleteDataFailedAction(error.response?.data?.message));
        });
    };
};


export const updateProviderTypeAction = (id, data) => {
    
    return (dispatch) => {
        updateProviderType(id, data).then((response) => {
            response.data['id'] = id;
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedUpdateProviderTypeAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(UpdateProviderTypeFailedAction(error.response?.data?.message));
        });
    };
};


export const updateSpecialityAction = (id, data) => {
    
    return (dispatch) => {
        updateProviderSpeciality(id, data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            response.data['id'] = id;
            dispatch(confirmedUpdateSpecialityAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(UpdateSpecialityFailedAction(error.response?.data?.message));
        });
    };
};


export const updateFamilleActesAction = (id, data) => {
    
    return (dispatch) => {
        updateFamilleActe(id, data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            response.data['id'] = id;
            dispatch(confirmedUpdateFamilleActesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(UpdateFamilleActesFailedAction(error.response?.data?.message));
        });
    };
};


export const updateTypeActesAction = (id, data) => {
    
    return (dispatch) => {
        updateTypeActe(id, data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(confirmedUpdateTypeActesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(UpdateTypeActesFailedAction(error.response?.data?.message));
        });
    };
};


export const updateLettreCleAction = (id, data) => {
    
    return (dispatch) => {
        updateLettreCle(id, data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            response.data['id'] = id;
            dispatch(confirmedUpdateLettreCleAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(UpdateLettreCleFailedAction(error.response?.data?.message));
        });
    };
};


export const getOptiqueParameterAction = (reference) => {
    return (dispatch) => {
        getParametreOptique(reference).then((response) => {
            dispatch(confirmedListOptiqueParametersAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_optic_param_error')+error.response?.data?.message);
            dispatch(listOptiqueParametersFailedAction(error.response?.data?.message));
        });
    };
};


export const createParametreOptiqueAction = (data) => {
    return (dispatch) => {
        createParametreOptique(data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(getOptiqueParameterAction('paramOptique'));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
        });
    };
};


export const updateParametreOptiqueAction = (id, data) => {
    return (dispatch) => {
        updateParametreOptique(id, data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(getOptiqueParameterAction('paramOptique'));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
        });
    };
};


export const getMonturesAction = () => {
    return (dispatch) => {
        getMonture().then((response) => {
            dispatch(confirmedListMonturesAction(response.data));
        })
        .catch((error) => {
            errorToastr("Erreur lors du listing des montures:"+error.response?.data?.message);
            dispatch(listMonturesFailedAction(error.response?.data?.message));
        });
    };
};


export const createMontureAction = (data) => {
    return (dispatch) => {
        createMonture(data).then((response) => {
            successToastr("Monture créée avec succès");
            dispatch(getMonturesAction());
        })
        .catch((error) => {
            errorToastr("Erreur lors de la création de la monture:"+error.response?.data?.message);
        });
    };
};


export const updateMontureAction = (id, data) => {
    return (dispatch) => {
        updateMonture(id, data).then((response) => {
            successToastr("Monture mise à jour avec succès");
            dispatch(getMonturesAction());
        })
        .catch((error) => {
            errorToastr("Erreur lors de la modification de la monture:"+error.response?.data?.message);
        });
    };
};

export const getOpticPrizeGridAction = () => {
    return (dispatch) => {
        getOpticPrizeGrid().then((response) => {
            dispatch(confirmedGetOpticPrizeGridAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_optic_grid_error')+error.response?.data?.message);
        });
    };
};

export const updateOpticPrizeGridAction = (data) => {
    return (dispatch) => {
        updateOpticPrizeGrid(data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            dispatch(getOpticPrizeGridAction());
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
        });
    };
};

export const deleteOpticParamAction = (id) => {
    return (dispatch) => {
        deleteParametreOptique(id).then((response) => {
            successToastr(I18n.t('provider.deleted_success'));
            dispatch(getOptiqueParameterAction('paramOptique'));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.deleted_error')+error.response?.data?.message);
        });
    };
};

export const getTypesActesControlsAction = () => {
    return (dispatch) => {
        getTypesActeControls().then((response) => {
            dispatch(confirmedGetTypesActesControlsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_type_acte_error')+error.response?.data?.message);
            dispatch(getTypesActesControlsFailedAction());
        });
    };
};

export const getActesControlsAction = () => {
    return (dispatch) => {
        getActeControls().then((response) => {
            dispatch(confirmedGetActesControlsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_acte_error')+error.response?.data?.message);
            dispatch(getActesControlsFailedAction());
        });
    };
};

export const createActesControlsAction = (data) => {
    return (dispatch) => {
        createActeControls(data).then((response) => {
            dispatch(getActesControlsAction());
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_acte_error')+error.response?.data?.message);
            dispatch(getActesControlsFailedAction());
        });
    };
};

export const updateActesControlsAction = (id, data) => {
    return (dispatch) => {
        updateActeControls(id, data).then((response) => {
            dispatch(getActesControlsAction());
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_acte_error')+error.response?.data?.message);
            dispatch(getActesControlsFailedAction());
        });
    };
};

export const deleteActesControlsAction = (id) => {
    return (dispatch) => {
        deleteActeControls(id).then((response) => {
            dispatch(getActesControlsAction());
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_acte_error')+error.response?.data?.message);
            dispatch(getActesControlsFailedAction());
        });
    };
};

export const createTypesActesControlsAction = (data) => {
    return (dispatch) => {
        createTypesActeControls(data).then((response) => {
            dispatch(getTypesActesControlsAction());
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_type_acte_error')+error.response?.data?.message);
            dispatch(getTypesActesControlsFailedAction());
        });
    };
};

export const deleteTypesActesControlsAction = (id) => {
    return (dispatch) => {
        deleteTypesActeControls(id).then((response) => {
            dispatch(getTypesActesControlsAction());
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_type_acte_error')+error.response?.data?.message);
            dispatch(getTypesActesControlsFailedAction());
        });
    };
};

export function confirmedGetActesControlsAction(payload) {
    return {
        type: GET_ACTES_CONTROLS_CONFIRMED_ACTION,
        payload,
    };
};

export function getActesControlsFailedAction() {
    return {
        type: GET_ACTES_CONTROLS_FAILED_ACTION,
    };
};


export function confirmedGetTypesActesControlsAction(payload) {
    return {
        type: GET_TYPE_ACTES_CONTROLS_CONFIRMED_ACTION,
        payload,
    };
};

export function getTypesActesControlsFailedAction() {
    return {
        type: GET_TYPE_ACTES_CONTROLS_FAILED_ACTION,
    };
};

export function confirmedGetTarifsProviderAction(payload) {
    return {
        type: GET_TARIFS_PROVIDER_CONFIRMED_ACTION,
        payload,
    };
};

export function getTarifsProviderFailedAction(payload) {
    return {
        type: GET_TARIFS_PROVIDER_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetTarifsMedicamentProviderAction(payload) {
    return {
        type: GET_TARIFS_PROVIDER_MED_CONFIRMED_ACTION,
        payload,
    };
};

export function getTarifsMedicamentProviderFailedAction(payload) {
    return {
        type: GET_TARIFS_PROVIDER_MED_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetOpticPrizeGridAction(payload) {
    return {
        type: GET_OPTIC_PRIZE_GRID_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedListMonturesAction(payload) {
    return {
        type: GET_ALL_MONTURE_CONFIRMED_ACTION,
        payload,
    };
};

export function listMonturesFailedAction(payload) {
    return {
        type: GET_ALL_MONTURE_FAILED_ACTION,
        payload,
    };
};

export function confirmedListOptiqueParametersAction(payload) {
    return {
        type: GET_ALL_OPTIC_PARAMS_CONFIRMED_ACTION,
        payload,
    };
};

export function listOptiqueParametersFailedAction(payload) {
    return {
        type: GET_ALL_OPTIC_PARAMS_FAILED_ACTION,
        payload,
    };
};

export function confirmedUpdateLettreCleAction(payload) {
    return {
        type: UPDATE_LETTRE_CLE_CONFIRMED_ACTION,
        payload,
    };
};

export function UpdateLettreCleFailedAction(payload) {
    return {
        type: UPDATE_LETTRE_CLE_FAILED_ACTION,
        payload,
    };
};

export function confirmedUpdateTypeActesAction(payload) {
    return {
        type: UPDATE_TYPE_ACTES_CONFIRMED_ACTION,
        payload,
    };
};

export function UpdateTypeActesFailedAction(payload) {
    return {
        type: UPDATE_TYPE_ACTES_FAILED_ACTION,
        payload,
    };
};

export function confirmedUpdateFamilleActesAction(payload) {
    return {
        type: UPDATE_FAMILLE_ACTES_CONFIRMED_ACTION,
        payload,
    };
};

export function UpdateFamilleActesFailedAction(payload) {
    return {
        type: UPDATE_FAMILLE_ACTES_FAILED_ACTION,
        payload,
    };
};

export function confirmedUpdateSpecialityAction(payload) {
    return {
        type: UPDATE_SPECIALITY_CONFIRMED_ACTION,
        payload,
    };
};

export function UpdateSpecialityFailedAction(payload) {
    return {
        type: UPDATE_SPECIALITY_FAILED_ACTION,
        payload,
    };
};

export function confirmedUpdateProviderTypeAction(payload) {
    return {
        type: UPDATE_PROVIDER_TYPE_CONFIRMED_ACTION,
        payload,
    };
};

export function UpdateProviderTypeFailedAction(payload) {
    return {
        type: UPDATE_PROVIDER_TYPE_FAILED_ACTION,
        payload,
    };
};

export function confirmedDeleteDataAction(payload) {
    return {
        type: DELETE_DATA_CONFIRMED_ACTION,
        payload,
    };
};

export function deleteDataFailedAction(payload) {
    return {
        type: DELETE_DATA_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetMedicamentsPagesAction(payload) {
    return {
        type: GET_MEDICAMENT_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedUpdateMedicamentAction(payload) {
    return {
        type: UPDATE_MEDICAMENT_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedCreateMedicamentAction(payload) {
    return {
        type: CREATE_MEDICAMENT_CONFIRMED_ACTION,
        payload,
    };
};


export function confirmedSearchActesAction(payload) {
    return {
        type: SEARCH_ACTES_CONFIRMED_ACTION,
        payload,
    };
};

export function searchActesFailedAction(payload) {
    return {
        type: SEARCH_ACTES_FAILED_ACTION,
        payload,
    };
};



export function confirmedSearchActesConsultAction(payload) {
    return {
        type: SEARCH_ACTES_CONSULT_CONFIRMED_ACTION,
        payload,
    };
};

export function searchActesConsultFailedAction(payload) {
    return {
        type: SEARCH_ACTES_CONSULT_FAILED_ACTION,
        payload,
    };
};

export function confirmedTarifUpdatedAction(payload) {
    return {
        type: UPDATE_TARFIF_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedTarifMedicamentUpdatedAction(payload) {
    return {
        type: UPDATE_TARFIF_MEDICAMENT_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedActeUpdatedAction(payload) {
    return {
        type: UPDATE_ACTE_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedProviderRegisterAction(payload) {
    return {
        type: REGISTER_PROVIDER_CONFIRMED_ACTION,
        payload,
    };
};

export function clientProviderFailedAction(payload) {
    return {
        type: REGISTER_PROVIDER_FAILED_ACTION,
        payload,
    };
};
export function confirmedGetLettreCleAction(payload) {
    return {
        type: GET_LETTRE_CLE_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedGetActeAction(payload) {
    return {
        type: GET_ACTE_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedGetActePagesAction(payload) {
    return {
        type: GET_ACTE_PAGES_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedGetGarantiesAction(payload) {
    return {
        type: GET_GARANTIES_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedGetTypeActeAction(payload) {
    return {
        type: GET_TYPEACTE_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedGetFamilleActeAction(payload) {
    return {
        type: GET_FAMILLEACTE_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedGetTarifsAction(payload) {
    return {
        type: GET_TARIFS_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedGetTarifsMedicamentAction(payload) {
    return {
        type: GET_ALL_TARIFS_MEDICAMENT_CONFIRMED_ACTION,
        payload,
    };
};
export function getLettreCleFailedAction(payload) {
    return {
        type: GET_LETTRE_CLE_FAILED_ACTION,
        payload,
    };
};
export function confirmedProviderListAction(payload) {
    return {
        type: GET_PROVIDER_CONFIRMED_ACTION,
        payload,
    };
};

export function providerListFailedAction(payload) {
    return {
        type: GET_PROVIDER_FAILED_ACTION,
        payload,
    };
};
export function confirmedGetProviderAction(payload) {
    return {
        type: GET_PROVIDER_DATA_CONFIRMED_ACTION,
        payload,
    };
};

export function getProviderFailedAction(payload) {
    return {
        type: GET_PROVIDER_DATA_FAILED_ACTION,
        payload,
    };
};
export function confirmedProviderUpdateAction(payload) {
    return {
        type: UPDATE_PROVIDER_CONFIRMED_ACTION,
        payload,
    };
};

export function providerUpdateFailedAction(payload) {
    return {
        type: UPDATE_PROVIDER_FAILED_ACTION,
        payload,
    };
};

export function confirmedProviderSuspendAction(payload) {
    return {
        type: SUSPEND_PROVIDER_CONFIRMED_ACTION,
        payload,
    };
};

export function providerSuspendFailedAction(payload) {
    return {
        type: SUSPEND_PROVIDER_FAILED_ACTION,
        payload,
    };
};

export function startLoader() {
    return {
        type: START_LOADER_ACTION
    };
};

export function confirmedGetFamiliesAction(payload) {
    return {
        type: GET_FAMILIES_CONFIRMED_ACTION,
        payload,
    };
};

export function getFamiliesFailedAction(payload) {
    return {
        type: GET_FAMILIES_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetProviderTypesAction(payload) {
    return {
        type: GET_PROVIDER_TYPES_CONFIRMED_ACTION,
        payload,
    };
};

export function getProviderTypesFailedAction(payload) {
    return {
        type: GET_PROVIDER_TYPES_FAILED_ACTION,
        payload,
    };
};
export function confirmedGetTypeActeurAction(payload) {
    return {
        type: GET_ACTEUR_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedGetProviderSpecialitiesAction(payload) {
    return {
        type: GET_PROVIDER_SPECIALITIES_CONFIRMED_ACTION,
        payload,
    };
};

export function getProviderSpecialitiesFailedAction(payload) {
    return {
        type: GET_PROVIDER_SPECIALITIES_FAILED_ACTION,
        payload,
    };
};

export function confirmedRegisterProviderSpecialitiesAction(payload) {
    return {
        type: REGISTER_PROVIDER_SPECIALITIES_CONFIRMED_ACTION,
        payload,
    };
};

export function registerProviderSpecialitiesFailedAction(payload) {
    return {
        type: REGISTER_PROVIDER_SPECIALITIES_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetAllTarifsAction(payload) {
    return {
        type: GET_ALL_TARIFS_CONFIRMED_ACTION,
        payload,
    };
};

export function getAllTarifsFailedAction(payload) {
    return {
        type: GET_ALL_TARIFS_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetAllTarifsMedicamentAction(payload) {
    return {
        type: GET_ALL_TARIFS_MEDICAMENT_CONFIRMED_ACTION,
        payload,
    };
};

export function getAllTarifsMedicamentFailedAction(payload) {
    return {
        type: GET_ALL_TARIFS_MEDICAMENT_FAILED_ACTION,
        payload,
    };
};

export function confirmedProviderUpdateTarificationAction(payload) {
    return {
        type: UPDATE_PROVIDER_TARIFICATION_CONFIRMED_ACTION,
        payload,
    };
};

export function providerUpdateTarificationFailedAction(payload) {
    return {
        type: UPDATE_PROVIDER_TARIFICATION_FAILED_ACTION,
        payload,
    };
};

export function confirmedProviderUpdateTarificationMedicamentAction(payload) {
    return {
        type: UPDATE_PROVIDER_TARIFICATION_CONFIRMED_ACTION,
        payload,
    };
};

export function providerUpdateTarificationMedicamentFailedAction(payload) {
    return {
        type: UPDATE_PROVIDER_TARIFICATION_FAILED_ACTION,
        payload,
    };
};

export function confirmedProviderUpdateTarificationUpdatedAction(payload) {
    return {
        type: UPDATE_PROVIDER_UPDATED_TARIFICATION_CONFIRMED_ACTION,
        payload,
    };
};

export function providerUpdateTarificationUpdatedFailedAction(payload) {
    return {
        type: UPDATE_PROVIDER_UPDATED_TARIFICATION_FAILED_ACTION,
        payload,
    };
};

export function confirmedProviderUpdateNewTarificationAction(payload) {
    return {
        type: UPDATE_PROVIDER_NEW_TARIFICATION_CONFIRMED_ACTION,
        payload,
    };
};

export function providerUpdateNewTarificationFailedAction(payload) {
    return {
        type: UPDATE_PROVIDER_NEW_TARIFICATION_ACTION,
        payload,
    };
};
export function confirmedProviderTypeRegisterAction(payload){
    return {
        type: REGISTER_PROVIDER_TYPE_CONFIRMED_ACTION,
        payload,
    };
}
export function registerProviderTypeFailedAction(payload){
    return {
        type: REGISTER_PROVIDER_TYPE_FAILED_ACTION,
        payload,
    };
}
export function confirmedFileImportAction(payload) {
    return {
        type: FILE_IMPORT_CONFIRMED_ACTION,
        payload,
    };
};
export function failedFileImportAction(payload) {
    return {
        type: FILE_IMPORT_FAILED_ACTION,
        payload,
    };
};

export function confirmedRegisterProviderBankAction(payload) {
    return {
        type: REGISTER_PROVIDER_BANK_CONFIRMED_ACTION,
        payload,
    };
};

export function registerProviderBankFailedAction(payload) {
    return {
        type: REGISTER_PROVIDER_BANK_FAILED_ACTION,
        payload,
    };
};

export function confirmedTarifCreatedAction(payload) {
    return {
        type: CREATE_NEW_TARFIF_CONFIRMED_ACTION,
        payload,
    }
}

export function confirmedTarifMedicamentCreatedAction(payload) {
    return {
        type: CREATE_NEW_TARFIF_MEDICAMENT_CONFIRMED_ACTION,
        payload,
    }
}

export function confirmedRemoveTarifAction(payload) {
    return {
        type: REMOVE_TARIF_CONFIRMED_ACTION,
        payload,
    };
};

export function removeTarifFailedAction(payload) {
    return {
        type: REMOVE_TARIF_FAILED_ACTION,
        payload,
    }
}

export function confirmedRemoveTarifMedicamentAction(payload) {
    return {
        type: REMOVE_TARIF_MEDICAMENT_CONFIRMED_ACTION,
        payload,
    };
};

export function removeTarifMedicamentFailedAction(payload) {
    return {
        type: REMOVE_TARIF_MEDICAMENT_FAILED_ACTION,
        payload,
    }
}

export function confirmedDeleteTarifMedicamentAction(payload) {
    return {
        type: DELETE_TARIF_MEDICAMENT_CONFIRMED_ACTION,
        payload,
    };
};

export function deleteTarifMedicamentFailedAction(payload) {
    return {
        type: DELETE_TARIF_MEDICAMENT_FAILED_ACTION,
        payload,
    }
}

export function confirmedProviderSpecialityRegisterAction(payload) {
    return {
        type: REGISTER_PROVIDER_SPECIALITY_CONFIRMED_ACTION,
        payload,
    };
};

export function registerProviderSpecialityFailedAction(payload) {
    return {
        type: REGISTER_PROVIDER_SPECIALITY_FAILED_ACTION,
        payload,
    }
}

export function confirmedFamilleActeRegisterAction(payload) {
    return {
        type: REGISTER_FAMILLE_ACTE_CONFIRMED_ACTION,
        payload,
    }
}

export function confirmedTypeActeRegisterAction(payload) {
    return {
        type: REGISTER_TYPE_ACTE_CONFIRMED_ACTION,
        payload,
    }
}

export function confirmedActeCreatedAction(payload) {
    return {
        type: CREATE_ACTE_CONFIRMED_ACTION,
        payload,
    }
}

export function confirmedRegisterLettreCleAction(payload) {
    return {
        type: REGISTER_LETTRE_CLE_CONFIRMED_ACTION,
        payload,
    }
}
