import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import RemboursementListItem from "./RemboursementListItem";
import RemboursementPagination from './RemboursementPagination';
import { getRemboursementsAction, payRemboursementAction, startLoader } from "../../../../store/actions/FacturationAction";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from "primereact/tooltip";
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../../DataTables/DataTableBase";
import{withRouter} from 'react-router-dom'
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { Spinner, Modal } from "react-bootstrap";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { set } from "date-fns";

export const FacturationList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const [addCard, setAddCard] = useState(false);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    
    const [paidBordereaux, setPaidBordereaux] = useState([]);
    const [payStatut, setPayStatut] = useState(null);
    const [payMean, setPayMean] = useState(null);
    const [numCheque, setNumCheque] = useState(null);
    const [numVirement, setNumVirement] = useState(null);
    const [reference, setReference] = useState(null);
    const [description, setDescription] = useState(null);
    const [payDate, setPayDate] = useState(new Date());
    const [payAmount, setPayAmount] = useState(0);
    const [codeBanque, setCodeBanque] = useState(null);
    const [reglementDate, setReglementDate] = useState(new Date());
    const [dateComptable, setDateComptable] = useState(new Date());

    const moyenPaiements = [
        {label: 'Chèque', value: 'cheque'},
        {label: 'Virement', value: 'virement'},
        {label: 'Espèce', value: 'espece'},
        {label: 'Paiement mobile', value: 'Paiement mobile'}
    ];

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            souscripteurName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            montantTotal: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dateFacturation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            statusPaiement: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            datePaiement: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'name', headerName: t('common.bill-number'), filterPlaceholder: t('facturation.bill-number-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'montantTotal', dataType: 'numeric', headerName:  t('common.amount'), filterPlaceholder: t('facturation.filter-by-amount'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'souscripteurName', headerName: 'Client', filterPlaceholder: t('facturation.provider-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'dateFacturation', filterField: 'dateFacturation', dataType: 'date', headerName: t('facturation.create-date'), filterPlaceholder: t('facturation.create-date-filter'), sortable: true, style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateFacturationBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'statusPaiement', headerName: t('facturation.payment-status'), filterPlaceholder: t('facturation.payment-status-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'datePaiement', filterField: 'dateFacturation', dataType: 'date', headerName: t('facturation.payment-date'), filterPlaceholder: t('facturation.payement-date-filter'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
        <Tooltip target=".btn-primary" position="top" />
        <Tooltip target=".btn-warning" position="top" />
                {rowData.statusPaiement !== STATUSES.enCoursPaiement && rowData.statusPaiement !== STATUSES.paye && <button className="btn btn-primary shadow btn-xs sharp mr-2" data-pr-tooltip={t("common.edit")}
                    onClick={e => viewFacture(rowData)}
                >
                    <i className="fa fa-pencil"></i>
                </button>}

                {hasMenu('MENREMBOURS') && canDo('MENREMBOURS', 'VIEWREMBOURSDETAIL') && <button className="btn btn-warning shadow btn-xs sharp mr-2" data-pr-tooltip={t("common.view")}
                    onClick={(e) => printFacture(rowData)}
                >
                    <i className="la la-eye"></i>
                </button>}
        </div>
    }

    const viewFacture = (facture) => {
        if(hasMenu('MENREMBOURS') && canDo('MENREMBOURS', 'VALIDATEREMBOURS')) {
            props.history.push('/remboursement-update/'+facture.id);
        }else if(hasMenu('MENREMBOURS') && canDo('MENREMBOURS', 'REQUESTREMBOURS')){
            props.history.push('/demande-remboursement-update/'+facture.id);
        }else{
            props.history.push('/access-denied');
        } 
    }

    const printFacture = (rembData) => {
        props.history.push('/remboursement-details/'+rembData.id);
    }

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.remboursements.map(facture => {
            return facture.statusPaiement === STATUSES.pending ? t('statuses.'+facture.statusPaiement) : facture.statusPaiement
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate}  placeholder={t('common.choose')} className="p-column-filter" showClear />;
    };

    const statusBordFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    {t('facturation.closed')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.datePaiement);
    };

    const dateFacturationBodyTemplate = (rowData) => {
        return formatDate(rowData.dateFacturation);
    };

    /*const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.statusPaiement === STATUSES.pending ? t('statuses.'+rowData.statusPaiement) : rowData.statusPaiement} severity={getSeverity(rowData.statusPaiement)} />;
    };*/

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.statusPaiement ? t('statuses.'+rowData.statusPaiement) : rowData.statusPaiement} severity={getSeverity(rowData.statusPaiement)} />;
    };

    const statusBordBodyTemplate = (rowData) => {
        return <Tag value={rowData.isClose ? t('facturation.closed') :  t('facturation.oppened')} severity={getSeverity(rowData.isClose)} />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case null:
                return 'danger';
            
            case 'non payé':
                return 'danger';

            case 'payé':
                return 'success';

            case true:
                return 'success';

            case false:
                return 'warning';

            case t('facturation.closed'):
                return 'success';

            case 'ouvert':
                return 'warning';

            default:
                return 'warning';
        }
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const onProviderTypeSave = (e) => {
        e.preventDefault();
        dispatch(startLoader());
        let data = {
            factureRemboursementId: paidBordereaux,
            reference: reference,
            description: description,
            montantTotal: payAmount,
            moyenPayment: payMean,
            numCheque: numCheque,
            numVirement: numVirement,
            codeBanque: codeBanque,
            datePaiement: payDate,
            dateEnvoiePaiement: reglementDate,
            dateComptable: dateComptable
        };

        dispatch(payRemboursementAction(data));
        setAddCard(false);
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }
    
    
    useEffect(() => {
        if(hasMenu('MENREMBOURS') && canDo('MENREMBOURS', 'LISTREMBOURS')){
            initFilters();
            dispatch(startLoader());
            dispatch(getRemboursementsAction());
        }else{
            props.history.push('/access-denied');
        } 
    }, []);

    //inverse le tableau par date
    const dataInverse = props.remboursements.sort((a,b)=>new Date(b.dateFacturation) - new Date(a.dateFacturation) )

    return (
        <>
            { props.showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading &&
            <>

            <div className="row">
                <div className="col-xl-12">
                    <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                        <div className="mr-auto d-none d-lg-block">
                            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATEACTE') && <Link
                                to="#"
                                className="btn btn-primary btn-rounded"
                                onClick={() => setAddCard(true)}
                            >
                                Payer
                            </Link>}
                        </div>
                    </div>
                    <Modal className="modal fade" size="lg" show={addCard} onHide={setAddCard} >
                        <div className="">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Enregistrer un paiement</h4>
                                </div>
                                <div className='card-body'>
                                    <div className='basic-form'>
                                        <form onSubmit={onProviderTypeSave}>
                                            <div className='row'>
                                                <div className='form-group col-12'>
                                                    <label className='mb-1'>Bordereau(x)</label>
                                                    <Select
                                                        className="select-search"
                                                        value={props.remboursements?.filter(elt => paidBordereaux.includes(elt.id)).map((val)=>{return {label:val.name+' - '+val.souscripteurName, value:val.id, total: val.montantTotal}})}
                                                        placeholder="Choisir le(s) bordereau(x)"
                                                        onChange={(vals) => {
                                                            setPaidBordereaux(vals ? vals.map(val => val.value) : []);
                                                            setPayAmount(vals ? vals.reduce((acc, curr) => acc + curr.total, 0) : 0);
                                                        }}
                                                        disabled={props.showLoading}
                                                        required={props.showLoading}
                                                        options={props.remboursements.filter(elt => elt.statusPaiement !== STATUSES.paye).map(facture => {return {label: facture.name+' - '+facture.souscripteurName, value: facture.id, total: facture.montantTotal}})}
                                                        isMulti
                                                        style={{
                                                            lineHeight: '40px',
                                                            color: '#7e7e7e',
                                                            paddingLeft: ' 15px',
                                                        }}
                                                    />
                                                </div>
                                                
                                                {/*<div className='form-group col-6'>
                                                    <label className='mb-1'>Statut</label>
                                                    <Select
                                                        className="select-search"
                                                        value={Object.keys(STATUSES).filter((key)=>{return payStatut === STATUSES[key]}).map((val)=>{return {label:val,value:STATUSES[val]}})[0]}
                                                        placeholder="Choisir un statut"
                                                        onChange={(val) => setPayStatut(val.value)}
                                                        disabled={props.showLoading}
                                                        required={props.showLoading}
                                                        options={Object.keys(STATUSES).map((key)=>{return {label:key, value:STATUSES[key]}})}
                                                        style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                        }}
                                                    />
                                                </div> */}

                                                <div className='form-group col-6'>
                                                    <label className='mb-1'>Moyen Paiement</label>
                                                    <Select
                                                        className="select-search"
                                                        value={moyenPaiements.filter((opt)=>{return opt.value === payMean})[0]}
                                                        placeholder="Choisir un moyen de paiement"
                                                        onChange={(val) => setPayMean(val.value)}
                                                        disabled={props.showLoading}
                                                        required={props.showLoading}
                                                        options={moyenPaiements}
                                                        style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                        }}
                                                    />
                                                </div>

                                                <div className='form-group col-6'>
                                                    <label className='mb-1'>Numéro chèque</label>
                                                    <input
                                                        type='text'
                                                        name='numCheque'
                                                        className='form-control'
                                                        disabled={props.showLoading}
                                                        required={props.showLoading}
                                                        placeholder={t('layout.pharmacy')}
                                                        value={numCheque}
                                                        onChange={(e) => {
                                                            setNumCheque(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className='form-group col-6'>
                                                    <label className='mb-1'>Date de paiement</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DateTimePicker
                                                            autoOk
                                                            label=""
                                                            clearable
                                                            value={payDate}
                                                            onChange={setPayDate}
                                                            format="dd/MM/yyyy"
                                                            required
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>

                                                <div className='form-group col-6'>
                                                    <label className='mb-1'>Montant</label>
                                                    <input
                                                        type='number'
                                                        name='payAmount'
                                                        min={0}
                                                        className='form-control'
                                                        disabled={props.showLoading}
                                                        required={props.showLoading}
                                                        placeholder="Montant"
                                                        value={payAmount}
                                                        onChange={(e) => {
                                                            setPayAmount(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className='form-group col-6'>
                                                    <label className='mb-1'>Code Banque</label>
                                                    <input
                                                        type='text'
                                                        name='codeBanque'
                                                        className='form-control'
                                                        disabled={props.showLoading}
                                                        required={props.showLoading}
                                                        placeholder="Code banque"
                                                        value={codeBanque}
                                                        onChange={(e) => {
                                                            setCodeBanque(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className='form-group col-6'>
                                                    <label className='mb-1'>Numéro de virement</label>
                                                    <input
                                                        type='text'
                                                        name='numVirement'
                                                        className='form-control'
                                                        disabled={props.showLoading}
                                                        required={props.showLoading}
                                                        placeholder="Numero de virement"
                                                        value={numVirement}
                                                        onChange={(e) => {
                                                            setNumVirement(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className='form-group col-6'>
                                                    <label className='mb-1'>Référence</label>
                                                    <input
                                                        type='text'
                                                        name='reference'
                                                        className='form-control'
                                                        disabled={props.showLoading}
                                                        required={props.showLoading}
                                                        placeholder="Référence"
                                                        value={reference}
                                                        onChange={(e) => {
                                                            setReference(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className='form-group col-6'>
                                                    <label className='mb-1'>Date de règlement</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DateTimePicker
                                                            autoOk
                                                            label=""
                                                            clearable
                                                            value={reglementDate}
                                                            onChange={setReglementDate}
                                                            format="dd/MM/yyyy"
                                                            required
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>

                                                <div className='form-group col-6'>
                                                    <label className='mb-1'>Date comptable</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DateTimePicker
                                                            autoOk
                                                            label=""
                                                            clearable
                                                            value={dateComptable}
                                                            onChange={setDateComptable}
                                                            format="dd/MM/yyyy"
                                                            required
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>

                                                <div className='form-group col-12'>
                                                    <label className='mb-1'>Description</label>
                                                    <input
                                                        type='text'
                                                        name='description'
                                                        className='form-control'
                                                        disabled={props.showLoading}
                                                        required={props.showLoading}
                                                        placeholder="Description"
                                                        value={description}
                                                        onChange={(e) => {
                                                            setDescription(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                
                                            </div>

                                            <div className='form-group align-items-center justify-content-center'>
                                                <div className=''>
                                                    { props.showLoading && 
                                                        <Button variant="primary" disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />{' '}
                                                            <span className="visually-hidden">{t('common.await-text')}</span>
                                                        </Button> }
                                                    { !props.showLoading &&
                                                        <button type='submit' className='btn btn-primary'>
                                                        {t('common.save')}
                                                        </button> }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <DataTableBase 
                        data={dataInverse.map(facture => {
                            facture.dateFacturation = new Date(facture.dateFacturation);
                            facture.datePaiement = new Date(facture.datePaiement);
                            return facture;
                        })}
                        emptyMessage={"Aucun remboursement trouvé"} 
                        filters={filters}
                        globalFiltersFields={['name', 'montantTotal', 'prestataireName', 'dateFacturation', 'statusPaiement', 'datePaiement']}
                        header={renderHeader()}
                        columns={setColumns()}
                        rows={10} 
                        loading={props.showLoading}
                        size={'small'}
                        exportFilename={"remboursements"}
                        exportColumns={[
                            {  title: t('common.number'), dataKey: 'name' },
                            { title: t('facturation.amount'), dataKey: 'montantTotal' },
                            {  title: 'Client', dataKey: 'souscripteurName' },
                            { title: t('facturation.invoice-date'), dataKey: 'dateFacturation' },
                            { title: t('facturation.payment-status'), dataKey: 'statusPaiement' },
                            { title: t('facturation.payment-date'), dataKey: 'datePaiement' },
                        ]}
                        showExport={true}
                    />
                </div>
            </div>
            </>
            }
        </> 
    )
};

const mapStateToProps = (state) => {
    return {
        remboursements: state.facturation.remboursements,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(FacturationList));