import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};


const jiraServiceRequest = axios.create({
    baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND,
    headers: headers
});

/*function stringToBase64(input) {
    try {
      return btoa(input);
    } catch (error) {
      console.error('Error converting string to Base64:', error);
      return null;
    }
}*/

export const createJiraTicket = (data) => jiraServiceRequest.post('/api/Support/create-issue', null, {params: data});