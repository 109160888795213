import React, { Suspense, useEffect, useState } from "react";

import { connect, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import LogoutLink from './Logout'; 

import profile from "../../../images/user.jpg";

import { currentUser } from "../../../store/selectors/CurrentUserSelector";

import { listNotificationsGroupByCategoryAction, boxLoadingToggleAction } from "../../../store/actions/NotificationAction";

//firebase
import { db } from "../../../Utils/firebase";
import { onValue, ref } from "firebase/database";
import Lang from "./lang";
import { useTranslation } from "react-i18next";

import "./Header.css";

const Header = ({ onNote, toggle, onProfile, onNotification, onBox, currentUser, boxShowLoading, notificationCategs }) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const [notifications, setNotifications] = useState(0);

   var path = window.location.pathname.split("/");
   var name = path[path.length - 1].split("-");
   var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
   var finalName =  filterName;

   const showNotifications = () => {
      dispatch(boxLoadingToggleAction(true));
      dispatch(listNotificationsGroupByCategoryAction());
   }

   useEffect(() => {
      const query = ref(db, "Notifications/admin");
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if(data) {
         setNotifications(data);
        }
      });
    }, []);


   return (
      <div className="header">
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div
                        className="dashboard_bar"
                        style={{ textTransform: "capitalize", color: "white"  }}
                     >
                        {/*{finalName.join(" ")}*/}
                     </div>
                  </div>
                  <ul className="navbar-nav header-right">
                     <li className="nav-item dropdown notification_dropdown">
                        <Link
                           className="nav-link  ai-icon text-white border-white"
                           to="#"
                           role="button"
                           data-toggle="dropdown"
                           onClick={() => {
                              onNotification(); 
                              //showNotifications();
                           }}
                        >
                           <i className="la la-bell"></i>
                           <span className={notifications > 0 ? "badge light text-white bg-danger blink" : "badge light text-white bg-danger"}>
                              {Object.keys(notifications).length}
                           </span>
                        </Link>
                        <div
                           className={`dropdown-menu dropdown-menu-right ${
                              toggle === "notification" ? "show" : ""
                           }`}
                        >
                           <PerfectScrollbar
                              id="DZ_W_Notification1"
                              className={` widget-media dz-scroll p-3 height380 ${
                                 toggle === "notification"
                                    ? "ps ps--active-y"
                                    : ""
                              }`}
                           >
                              <ul className="timeline">
                                 {boxShowLoading && 
                                    <li>
                                       <div className="sk-three-bounce">
                                          <div className="sk-child sk-bounce1"></div>
                                          <div className="sk-child sk-bounce2"></div>
                                          <div className="sk-child sk-bounce3"></div>
                                       </div>
                                    </li> }
                                 {!boxShowLoading &&
                                    <>
                                       {Object.keys(notifications).map(notifKey => {
                                          return <li>
                                             <Link to='#'>
                                                <div className="timeline-panel">
                                                   <div className="media mr-2 media-info">
                                                      {notifications[notifKey].Type?.split('')[0].toUpperCase()}
                                                   </div>
                                                   <div className="d-flex justify-content-between">
                                                      <small>
                                                         {notifications[notifKey].Message}
                                                      </small>
                                                   </div>
                                                </div>
                                             </Link>
                                          </li>
                                          })
                                       }
                                    </> 
                                 }
                              </ul>
                           </PerfectScrollbar>
                           <Link className="all-notification" to="#">
                              See all notifications{" "}
                              <i className="ti-arrow-right" />
                           </Link>
                        </div>
                     </li>
                     <li
                        className="nav-item dropdown header-profile"
                        onClick={() => onProfile()}
                     >
                        <Link
                           className="nav-link"
                           to="#"
                           role="button"
                           data-toggle="dropdown"
                        >
                           <img src={profile} width={20} alt="" />
                           <div className="header-info">
                              <span>
                              {t('common.greet')},<strong> {currentUser.username}</strong>
                              </span>
                           </div>
                        </Link>

                        <div
                           className={`dropdown-menu dropdown-menu-right ${
                              toggle === "profile" ? "show" : ""
                           }`}
                        >
                           <Link
                              to="/profile"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-user1"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-primary"
                                 width={18}
                                 height={18}
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth={2}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                 <circle cx={12} cy={7} r={4} />
                              </svg>
                              <span className="ml-2">Profile </span>
                           </Link>
                           <Link
                              to="./help-center"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-inbox"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-success"
                                 width={18}
                                 height={18}
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth={2}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                 <polyline points="22,6 12,13 2,6" />
                              </svg>
                              <span className="ml-2">Help Center </span>
                           </Link>
                           <Link
                              to="./email-inbox"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-inbox"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-success"
                                 width={18}
                                 height={18}
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth={2}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                 <polyline points="22,6 12,13 2,6" />
                              </svg>
                              <span className="ml-2">Inbox </span>
                           </Link>
                          
                           <LogoutLink />
                        </div>
                     </li>
                     <li className="nav-item">
                        <Lang />
                     </li>
                  </ul>
               </div>
            </nav>
         </div>
      </div>
   );
};
const mapStateToProps = (state) => {
   return {
       currentUser: currentUser(state),
       notificationCategs: state.notification.notificationsGroups,
       boxShowLoading: state.notification.boxShowLoading,
   };
};

export default connect(mapStateToProps)(Header);
