import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Card, Form, InputGroup, Table, Button, Badge, Nav } from 'react-bootstrap';
import { Search, ListFilter, AlertTriangle, CheckCircle } from 'lucide-react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useParams  } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { getTrackingPoliceContractAction } from '../../../store/actions/ContratActions';

const MonitoringDashboard = (props) => {
     const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all'); // 'all', 'with-difference', 'no-difference'
  const [data, setData] = useState([]);  
    useEffect(() => {
        setData([]);
        dispatch(getTrackingPoliceContractAction());
    }, []); 
    useEffect(() => {
    if (props.dataMonitoring) {
        setData(props.dataMonitoring);
    }
    }, [props.dataMonitoring]);
  // Calculate summary statistics
  const totalPolicies = data.length;
  const policiesWithDifference = data.filter(item => item.difference !== 0).length;
  const totalDifference = data.reduce((sum, item) => sum + Math.abs(item.difference), 0);
  
  // Filter and search data
  const filteredData = data
    .filter(item => {
      if (filter === 'with-difference') return item.difference !== 0;
      if (filter === 'no-difference') return item.difference === 0;
      return true;
    })
    .filter(item => 
      item.numeroPolice?.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // Sort data to show differences at the top
  const sortedData = [...filteredData].sort((a, b) => 
    Math.abs(b.difference) - Math.abs(a.difference)
  );


  return (
    <div className="">
      {/* Header */}
      
          <h1 className="h3 mb-0 fw-bold">ACARE vs ORASS Monitoring Dashboard</h1>
    
      
      <div className="py-4">
        {/* Summary Stats */}
        <div className="mb-4 row">
          <div md={4} className="mb-3 mb-md-0 col-4">
            <Card className="h-100">
              <Card.Body className="d-flex align-items-center">
                <div className="rounded-circle bg-primary bg-opacity-10 p-3 me-3">
                  <ListFilter className="text-white" size={24} />
                </div>
                <div>
                  <p className="text-muted medium mb-0">Total des polices avec Bénéficiaires</p>
                  <h2 className="mb-0 fw-bold">{totalPolicies}</h2>
                </div>
              </Card.Body>
            </Card>
          </div>
          
          <div md={4} className="mb-3 mb-md-0 col-4">
            <Card className="h-100">
              <Card.Body className="d-flex align-items-center">
                <div className="rounded-circle bg-warning bg-opacity-10 p-3 me-3">
                  <AlertTriangle className="text-white" size={24} />
                </div>
                <div>
                  <p className="text-muted medium mb-0">Nombres de polices avec écarts</p>
                  <h2 className="mb-0 fw-bold">{policiesWithDifference}</h2>
                </div>
              </Card.Body>
            </Card>
          </div>
          
          <div md={4} className='col-lg-4'>
            <Card className="h-100">
              <Card.Body className="d-flex align-items-center">
                <div className="rounded-circle bg-danger bg-opacity-10 p-3 me-3">
                  <ListFilter className="text-white" size={24} />
                </div>
                <div>
                  <p className="text-muted medium mb-0">Total Différence (Absolute)</p>
                  <h2 className="mb-0 fw-bold">{totalDifference}</h2>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        
        {/* Search and Filters */}
        <div className="mb-4 row">
          <div md={6} className="mb-3 mb-md-0 col-lg-6">
            <InputGroup>
              <Form.Control
                type="text"
                size={50}
                placeholder="Search by NumeroPolice..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
          </div>
          
          <div md={6} className='col-lg-6'>
            <Nav className="bg-white rounded border">
              <Nav.Item>
                <Button
                  variant={filter === 'all' ? 'primary' : 'light'}
                  className="rounded-0 border-0"
                  onClick={() => setFilter('all')}
                >
                  All
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  variant={filter === 'with-difference' ? 'primary' : 'light'}
                  className="rounded-0 border-0"
                  onClick={() => setFilter('with-difference')}
                >
                  With Differences
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  variant={filter === 'no-difference' ? 'primary' : 'light'}
                  className="rounded-0 border-0"
                  onClick={() => setFilter('no-difference')}
                >
                  No Differences
                </Button>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        
        {/* Data Table */}
        <Card className="mb-4 shadow-sm">
          <Card.Body className="p-0">
            <Table hover responsive className="mb-0">
              <thead className="bg-light">
                <tr>
                  <th className="border-bottom">Status</th>
                  <th className="border-bottom">Numero Police</th>
                  <th className="border-bottom">ACARE Count</th>
                  <th className="border-bottom">ORASS Count</th>
                  <th className="border-bottom">Difference</th>
                  <th className="border-bottom">Action</th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((item, index) => (
                  <tr key={index} className={item.difference !== 0 ? 'bg-warning bg-opacity-10' : ''}>
                    <td>
                      {item.difference !== 0 ? (
                        <AlertTriangle className="text-danger" size={20} />
                      ) : (
                        <CheckCircle className="text-success" size={20} />
                      )}
                    </td>
                    <td className="fw-medium">{item.numeroPolice}</td>
                    <td>{item.sqlCount}</td>
                    <td>{item.oracleCount}</td>
                    <td>
                      <span className={`fw-bold ${
                        item.difference !== 0 ? 'text-danger' : 
                        item.difference !== 0 ? 'text-warning' : 'text-success'
                      }`}>
                        {item.difference}
                      </span>
                    </td>
                    <td><Link to={`police/${item.numeroPolice}`}>Detail</Link></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
      
      {/* Footer */}
      <footer className="mt-auto py-3 bg-light border-top text-center text-muted">
        <small>ACARE vs ORASS Count Monitoring - {new Date().toLocaleDateString()}</small>
      </footer>
    </div>
  );
};

const mapStateToProps = (state) => {
   return {
      dataMonitoring: state.contrat.dataMonitoring,
      errorMessage: state.contrat.errorMessage,
      successMessage: state.contrat.successMessage,
      showLoading: state.contrat.showLoading,
   };
};

export default connect(mapStateToProps)(MonitoringDashboard);