import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Spinner, Button, Modal, ListGroup } from "react-bootstrap";
import BasicDatatable from '../DataTable';
import { Link } from "react-router-dom";
import {
    startLoader,
    getActesControlsAction,
    getTypesActesControlsAction,
    createActesControlsAction,
    updateActesControlsAction,
    deleteActesControlsAction,
    searchActesAction
} from '../../../../store/actions/ProviderActions';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import Select from 'react-select';
import swal from 'sweetalert';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { useTranslation } from "react-i18next";
import { Tag } from 'primereact/tag';
import { set } from 'date-fns';

const ActeControls = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [id, setId] = useState(null);
    const [typeControl, setTypeControl] = useState('');
    const [comparator, setComparator] = useState('');
    const [valeur, setValeur] = useState(0);
    const [periode, setPeriode] = useState(0);
    const [listActs, setListActs] = useState([]);
    const [errors, setErrors] = useState(errorsObj);

    const [data, setData] = useState([]);

    const [updateMode, setUpdateMode] = useState(false);
    const [addCard, setAddCard] = useState(false);

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const compators = [
        { label: 'Egal', value: '=' },
        { label: 'Inférieur', value: '<' },
        { label: 'Supérieur', value: '>' },
        { label: 'Inférieur ou égal', value: '<=' },
        { label: 'Supérieur ou égal', value: '>=' }
    ]

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            description: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            typeControl: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            comparator: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            valeur: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            periode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)} placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            { name: 'description', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null },
            { name: 'typeControl', headerName: "Type de contrôle", filterPlaceholder: 'Filtrer par type de contrôle', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null },
            { name: 'periode', headerName: "Période(en mois)", filterPlaceholder: 'Filtrer par type de période', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null },
            { name: 'listActs', headerName: "Actes", filterPlaceholder: '', style: { minWidth: '12rem' }, filter: false, bodyTemplate: actesBodyTemplate },
            { name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem' }, filter: false, bodyTemplate: getActionsTemplate },
        ]
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actesBodyTemplate = (rowData) => {
        return rowData.listActs?.map(acte => {
            return <Tag value={acte.nameFr} severity={'secondary'} />;
        })
    }

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'UPDATEACTE') && <Link href="#" to="#" onClick={e => updateAct(e, rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>{" "}
            </Link>}
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'DELETEACTE') && <Link href="#" to="#" title="Close"
                onClick={() =>
                    swal({
                        title: "Supprimer le contrôle " + rowData.nameFr + " ?",
                        text:
                            "Une fois le contrôle supprimé, il ne pourra plus être accessible",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            dispatch(startLoader());
                            dispatch(deleteActesControlsAction(rowData.id));
                        }
                    })
                }
            >
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if (!theMenu) {
            return false;
        } else {
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }

    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        initFilters();
        dispatch(getActesControlsAction());
        dispatch(getTypesActesControlsAction());
    }, []);

    function onProviderTypeSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        /*if (file) {
            dispatch(startLoader());
            //dispatch(importActeFileUploadAction(file));
            return;
        }*/
        if (typeControl === '') {
            errorObj.code = 'Le type de contrôle est réquise';
            error = true;
        }
        if (valeur === 0) {
            errorObj.name = 'La valeur est requise';
            error = true;
        }
        if (listActs.length === 0) {
            errorObj.name = 'La liste des actes est requise';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
            return;
        }
        dispatch(startLoader());

        let obj = {
            comparateur: comparator,
            valeur: valeur + "",
            controlPeriod: periode + "",
            controlId: typeControl,
            acteId: listActs.map(act => act.value)
        };
        if (updateMode) {
            dispatch(updateActesControlsAction(id, obj));
        } else {
            dispatch(createActesControlsAction(obj));
        }
    }

    useEffect(() => {
        setId(null);
        setValeur(0);
        setTypeControl('');
        setComparator('');
        setPeriode(0);
        setListActs([]);
        setUpdateMode(false);
        setAddCard(false);

        // Regrouper les éléments de props.actesControls par acteControlId
        let temp = {};
        Object.values(props.actesControls).forEach(item => {
            const key = item.acteControlId; // Clé de regroupement
            if (!temp[key]) {
                temp[key] = []; // Initialisation d'un tableau si la clé n'existe pas
            }
            temp[key].push(item); // Ajout de l'élément dans le groupe correspondant
        });

        // Extraire les clés pour itérer
        let keys = Object.keys(temp);

        setData(keys.map((key) => {
            return {
                id: key,
                listActs: temp[key].map(elt => elt.acte),
                typeControlId: temp[key][0].acteControl.id,
                typeControl: temp[key][0].acteControl.controlType,
                description: temp[key][0].acteControl.description,
                comparator: temp[key][0].comparateur,
                valeur: temp[key][0].valeur,
                periode: temp[key][0].controlPeriod
            }
        }));

    }, [props.actesControls]);

    const updateAct = (e, act) => {
        e.preventDefault();
        setId(act.id);
        setValeur(act.valeur);
        setTypeControl(act.typeControlId);
        setComparator(act.comparator);
        setPeriode(act.periode);
        setListActs(act.listActs.map((acte) => { return { label: acte.nameFr, value: acte.id } }));
        setUpdateMode(true);
        setAddCard(true);
    }

    return (
        <>

            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATEACTE') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddCard(true)}
                    >
                        + Nouveau contrôle
                    </Link>}
                </div>
            </div>

            <Modal className="modal fade" size="lg" show={addCard} onHide={setAddCard} >
                <div className="">
                    <div className="card">
                        <div className="card-header">
                            {!updateMode && <h4 className="card-title">Créer un nouveau contrôle</h4>}
                            {updateMode && <h4 className="card-title">Modifier le contrôle</h4>}
                        </div>
                        <div className='card-body'>
                            <div className='basic-form'>
                                <form onSubmit={onProviderTypeSave}>
                                    <div className='row'>
                                        <div className='form-group col-6'>
                                            <label className='mb-1'>Type de contrôle</label>
                                            <Select
                                                className="select-search"
                                                value={props.typeActesControls.filter(elt => elt.id === typeControl).map((val) => { return { label: val.controlType, value: val.id } })[0]}
                                                placeholder="Choisir un type"
                                                onChange={(val) => setTypeControl(val.value)}
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                options={props.typeActesControls.map((opt) => { return { label: opt.controlType, value: opt.id } })}
                                                style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>Valeur</label>
                                            <input
                                                type='text'
                                                name='valeur'
                                                className='form-control'
                                                placeholder='0'
                                                value={valeur}
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                onChange={(e) => setValeur(e.target.value)}
                                            />
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>Comparateur</label>
                                            <Select
                                                className="select-search"
                                                value={compators.filter((opt) => { return opt.value === comparator })[0]}
                                                placeholder="Choisir un comparateur"
                                                onChange={(val) => setComparator(val.value)}
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                options={compators}
                                                style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>Période(en mois)</label>
                                            <input
                                                type='number'
                                                name='periode'
                                                className='form-control'
                                                placeholder='0'
                                                value={periode}
                                                min={0}
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                onChange={(e) => setPeriode(e.target.value)}
                                            />
                                        </div>

                                        <div className='form-group col-12'>
                                            <label className='mb-1'>Actes</label>
                                            <Select
                                                className="select-search"
                                                value={listActs}
                                                placeholder="Rechercher des actes et choisir"
                                                onInputChange={(val) => {
                                                    if(val.length >= 1){
                                                        dispatch(searchActesAction(val));
                                                    }
                                                }}
                                                onChange={(vals) => {
                                                    vals && vals.length > 0 ?
                                                        setListActs(vals) :
                                                        setListActs([]);
                                                }}
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                isMulti
                                                options={props.actesRes?.map((opt) => { return { label: opt.nameFr, value: opt.id } })}
                                                style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='form-group align-items-center justify-content-center'>
                                        <div className=''>
                                            {props.showLoading &&
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                                </Button>}
                                            {!props.showLoading &&
                                                <button type='submit' className='btn btn-primary'>
                                                    {t('common.save')}
                                                </button>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <DataTableBase
                data={data.length > 0 ? data : []}
                emptyMessage="Aucun contrôle trouvé"
                filters={filters}
                globalFiltersFields={['description', 'valeur', 'periode', 'typeControl']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: "Type de contrôle", dataKey: 'typeControl' },
                    { title: 'Période', dataKey: 'periode' },
                    { title: 'Comparateur', dataKey: 'comparator' },
                    { title: 'Valeur', dataKey: 'valeur' }
                ]}
                rows={10}
                showExport={true}
                exportFilename={"controles"}
                loading={props.showLoading}
                lazy={false}
            />
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        typeActesControls: state.provider.typeActesControls,
        actesControls: state.provider.actesControls,
        actesRes: state.provider.actesRes,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.provider.showLoading,
        successMessage: state.provider.successMessage,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(ActeControls);