import {
    getdataDashboard,getRapportAmbuHospi
} from '../../services/HomeService';
import { createJiraTicket } from '../../services/ContactService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_DASHBOARD_CONFIRMED_ACTION = '[getdataDashboard action] confirmed getdataDashboard';
export const GET_RAPPORT_AMBUHOSPI_CONFIRMED_ACTION = '[getRapportAmbuHospi action] confirmed getRapportAmbuHospi';
export const GET_DASHBOARD_FAILED_ACTION = '[getDashboardDataFailed action] failed getDashboardDataFailed';
export const CREATE_JIRA_TICKET_CONFIRMED_ACTION = '[createJiraTicket action] confirmed createJiraTicket';

export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';

var I18n = require('react-redux-i18n').I18n;

export function getdataDashboardAction(data) {
    return (dispatch) => {
        getdataDashboard(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetDashboardDataAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('home.error_data')+error.response?.data?.message);
            dispatch(getDashboardDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}

export function getRapportAmbuHospiAction(data) {
    return (dispatch) => {
        getRapportAmbuHospi(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetRapportAmbuHospiAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('home.error_data')+error.response?.data?.message);
            dispatch(getDashboardDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}

export function createJiraTicketAction(data) {
    return (dispatch) => {
        createJiraTicket(data)
        .then((response) => {
            let data = response.data;
            successToastr('Ticket Jira créé avec succès, trouvez le lien ci-dessous');
            dispatch(confirmedCreateJiraTicketAction(data));
        })
        .catch((error) => {
            console.log(error);
            errorToastr('Erreur lors de la création'+error.response?.data?.message);
            dispatch(getDashboardDataFailedAction(error.response?.data?.message));
        });
    };
}

export function confirmedCreateJiraTicketAction(data) {
    return {
        type: CREATE_JIRA_TICKET_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedGetRapportAmbuHospiAction(data) {
    return {
        type: GET_RAPPORT_AMBUHOSPI_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedGetDashboardDataAction(data) {
    return {
        type: GET_DASHBOARD_CONFIRMED_ACTION,
        payload: data,
    };
}
export function getDashboardDataFailedAction(data) {
    return {
        type: GET_DASHBOARD_FAILED_ACTION,
        payload: data,
    };
}
export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}