import React, { useRef } from "react";

import ReactApexChart from "react-apexcharts";
import { chartColors } from "./colors";
import { useTranslation } from "react-i18next";
import { PieChart,  } from "@mui/x-charts";
import { FormControlLabel, Stack } from "@mui/material";


const PieChartNew = (props) => {
  const { series, pieWidth, labels, legendPosition, title, type, } = props;
  const { t } = useTranslation();
  const dt = useRef(null)

  const options = {
    title: {
      text: type === 'Actes' ? t("common.hospi-act") : title,
      align: "center", // Centre le titre
      style: {
        fontSize: "12px",
        color: "#333",

      },
    },

    chart: {
      //  width: pieWidth,
      type: 'donut',

    },
    legend: {
      position: legendPosition, // Position de la légende à droite
      fontSize: "8px",
      marginRight: "50px",
      paddingRight: "50px",
      labels: {
        colors: chartColors,
      },
      offsetX: 0, // Décalage horizontal pour la légende
      offsetY: 1, // Décalage horizontal pour la légende
    },
    labels: labels,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  const total = series.reduce((sum, value) => sum + value, 0);

  return (
    <div style={{ width: 1350 ,marginBottom:20, borderRadius:5 }}>
    <div style={{fontSize:15, textAlign:"center", color:"black", fontWeight:"bold"}}> {title} </div>
      <PieChart
        title={title}
        
        series={[
          {
            data: labels.map((label, index) => ({
              label: `${label.length > 20 ? label.slice(0, 20) + "..." : label} (${((series[index] / total) * 100).toFixed(1)}%)`,
              value: series[index],
            })),
            type: 'pie',
            // arcLabel: 'label'
          },
        ]}
        //slotProps={{ direction: 'row', position: { vertical: 'middle', horizontal: 'right' } }}
        slotProps={{
          legend: {
            direction: 'column',
            hidden: false,
            position: {
              vertical: 'middle',
              horizontal: 'right',
            },
            itemMarkWidth: 20,
            itemMarkHeight: 5,
            markGap: 5,
            itemGap: 10,
            labelStyle: {
              fontSize: 12,
              fill: 'black',
              marginLeft:10,
              paddingLeft:10
            },
          }
        }}
        width={1000}
        height={500}
        colors={chartColors}
        
      />
    </div>
  );
}

{/**
 <div id="chart">
        <ReactApexChart style={{ width: "100%", justifyContent: "space-between", flexDirection: "row", display: "flex", alignSelf: "center" }} options={options} series={series} type="pie" width={pieWidth} />
      </div>
      <div id="html-dist"></div>

  */}

export default PieChartNew