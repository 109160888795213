import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
import './EtatsGlobaux.css';
import { Margin, usePDF } from 'react-to-pdf';
import { Button } from 'primereact/button';
import { useReactToPrint } from 'react-to-print';
import PieChart from '../../chart/PieChart';
import PieChartNew from '../../chart/PieChartNew';



const EtatsGlobauxNew = (props) => {
    const { t } = useTranslation();
    const { data, showLoading, clientIds, dateMin, dateMax, clientName } = props;
    const dt = useRef(null);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })




    const [dataSeries, setDataSeries] = useState([]);
    const [dataLabels, setDataLabels] = useState([]);
    const [graph, setGraph] = useState(false);
    const [pdf, setPdf] = useState(false);

    useEffect(() => {
        // console.log('le monskre');
        // console.log(data);
        if (Object.keys(data).length > 0) {
            console.log("Inside the if");
            console.log(data);
            setDataSeries([parseFloat(data.totalRejete), parseFloat(data.totalPaye)]);
            setDataLabels(["Montant Rejeté", "Montant Payé"]);
        }
    }, [data]);


    const Ligne = ({ nom, chiffre }) => {
        return (
            <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ fontSize: 13, fontWeight: "bolder", width: "5%", color: 'black', marginRight: "0.1em", }}>-</div>
                <div style={{ width: "40%", fontSize: 13, color: "black", marginLeft: "0.5em", marginRight: "0.5em", }}>{nom}</div>
                <div style={{ fontSize: 13, color: "black", marginLeft: "0.5em", marginRight: "0.5em", }}>:</div>
                <div style={{ fontSize: 13, fontWeight: "bolder", width: "30%", color: 'black', marginRight: "0.5em", }}>{chiffre}</div>
            </div>
        )
    }
    return (
        <>
            {Object.keys(data).length === 0 && <span>
                {t("common.no-data-display")}
            </span>
            }
            {Object.keys(data).length > 0 && 
              <>
              <div style={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "3em", paddingLeft: "2em", paddingRight: "2em", paddingTop: "1em", }}>
                  <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                      <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "1.5em", marginBottom: "0.5em", }}>1- ETAT GLOBAL</div>

                      <Ligne nom={t("common.total-amount-benefits")} chiffre={data.totalPrestations + " F CFA"} />
                      <Ligne nom={t("common.total-rejected")} chiffre={data.totalRejete + " F CFA"} />
                      <Ligne nom={t("common.total-paid")} chiffre={data.totalPaye + " F CFA"} />
                      <Ligne nom={t("common.total-num-prov")} chiffre={data.nbrePrestataire} />
                      <Ligne nom={t("common.total-num-vis")} chiffre={data.nbreVisite} />
                  </div>
              </div>
              <div style={{ marginLeft: "2.5em" }}>
                  <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginLeft: "4em", marginTop: "1.5em", marginBottom: "0.5em", }}></div>
                  <div style={{ width: "95%", paddingLeft: "5em", }}>
                      <PieChartNew title="Graphe" series={dataSeries} pieWidth="700" labels={dataLabels} legendPosition="left" />
                  </div>
              </div></>
            }



        </>
    )
}
export default EtatsGlobauxNew;