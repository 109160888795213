import React from 'react'
import logoActiva from "../../../../images/logoa1.png"
import PieChart from '../chart/PieChart';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import HorizontalBarChart from '../chart/HorizontalBarChart';
import HorizontalBarChartNew from '../chart/HorizontalBarChartNew';
import PieChartNew from '../chart/PieChartNew';
import EtatsGlobauxNew from './export/EtatsGlobauxNew';
import EtatsConsommationsNew from './export/EtatsConsommationsNew';
import EtatsVisitesNew from './export/EtatsVisitesNew';
import EtatsDepenseRubriqueNew from './export/EtatsDepenseRubriqueNew';
import EtatsVisistePrestataireNew from './export/EtatsVisistePrestataireNew';
import EtatsTopConsommationsNew from './export/EtatsTopConsommationsNew';
import EtatTopPathologiesNew from './export/EtatTopPathologiesNew';



export default function ExportRapportTotal(props) {

    const { t } = useTranslation();
    const { dataSeries, dataLabels, typeRapport, data,
        ref, clientName, nomRapport, headerGroup, dataAmbuLabels, dataAmbuSeries
        , dataHospiSeries, statsDatas, dataHospiLabels, dt, type } = props;

    const { clientIds, dateMin, dateMax, showLoading, maxResults,
        etatsGlobauxData, etatsConsommationData, etatsVisitesData, etatsDepenseRubriqueData,
        etatsTopPathologiesData, etatsTopSpecialistesVisitesData, etatsTopConsommateursData } = props






    // FIN ETATS GLOBAUX 





    function formatDate(dateString) {
        const date = new Date(dateString); // Convertit en objet Date
        const day = String(date.getUTCDate()).padStart(2, "0"); // Jour avec 2 chiffres
        const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Mois (0-11) + 1 avec 2 chiffres
        const year = String(date.getUTCFullYear()).slice(-2); // Année à 2 chiffres
        return `${day}/${month}/${year}`; // Format final
    }


    const Ligne = ({ nom, chiffre }) => {
        return (
            <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ fontSize: 13, fontWeight: "bolder", width: "5%", color: 'black', marginRight: "0.1em", }}>-</div>
                <div style={{ width: "40%", fontSize: 13, color: "black", marginLeft: "0.5em", marginRight: "0.5em", }}>{nom}</div>
                <div style={{ fontSize: 13, color: "black", marginLeft: "0.5em", marginRight: "0.5em", }}>:</div>
                <div style={{ fontSize: 13, fontWeight: "bolder", width: "30%", color: 'black', marginRight: "0.5em", }}>{chiffre}</div>
            </div>
        )
    }
    return (
        <div ref={ref} style={{ backgroundColor: "white" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", paddingTop: "3em" }}>
                {/**HEADER */}
                <div style={{ display: "flex", flexDirection: "row", width: '100%', justifyContent: "space-between" }}>

                    <div style={{ width: "40.9%", height: 150, alignContent: 'center', alignItems: "center", }}>
                        <img src={logoActiva} style={{ height: "80%", width: "100%", alignSelf: "center", marginLeft: "3em" }} />
                    </div>

                    <div style={{ width: "40.9%", height: 150, alignContent: "end", alignItems: "end", border: "1px solid black", marginRight: "5em", display: 'flex', justifyContent: "space-between", flexDirection: 'column', borderRadius: 5 }}>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>Siège Social : </span> Immeuble Activa, rue Prince de Galles </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>B.P. : </span>  12970 Douala (Cameroun) </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>Tel. : </span>  (+237) 233.50.12.00 - <span style={{ fontWeight: "bolder" }}>Fax : </span> (+237) 233.43.45.72  </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>Site-Internet : </span>  www.group-activa.com/cameroun </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>Email : </span>  activa.assur@group-activa.com </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12 }}> <span style={{ fontWeight: "bolder" }}>        </span>  reclamations@group-activa.com </div>
                        <div style={{ height: "10%", width: "100%", color: "black", textAlign: "center", alignContent: "center", fontSize: 12, marginBottom: 2 }}> <span style={{ fontWeight: "bolder" }}>Call center : </span> 8033 (appel gratuit) </div>
                    </div>
                </div>

                {/**BODY */}
                <div style={{ width: "100%" }}>
                    <div style={{ width: "100%", textAlign: "center", alignContent: "center", alignItems: "center", marginTop: "2em" }}>
                        <span style={{ fontSize: 25, color: "black", fontWeight: "bolder", textDecoration: "underline" }}> RAPPORT {clientName} de {formatDate(dateMin)} à {formatDate(dateMax)} </span>
                    </div>

                    <div style={{ width: '100%', display: "flex", flexDirection: "column", justifyContent: "space-between", paddingBottom: "3em", paddingLeft: "2em", paddingRight: "2em", paddingTop: "1em", }}>

                        {/*nomRapport === 'etatGlobal' */}
                        {clientIds.length > 1 && <div>
                            {Object.keys(etatsGlobauxData).map(key => {
                                return <div className='content-div'>
                                    <h3>{etatsGlobauxData[key].souscripteur}</h3>
                                    <EtatsGlobauxNew clientName={etatsGlobauxData[key].souscripteur} dateMin={dateMin} dateMax={dateMax} clientIds={etatsGlobauxData[key].souscripteur} data={etatsGlobauxData[key].datas} showLoading={showLoading} />
                                </div>
                            })}
                        </div>}

                        {clientIds.length === 1 && <EtatsGlobauxNew clientName={clientName} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsGlobauxData} showLoading={showLoading} />}



                        {/*nomRapport === 'etatConsommation' */}
                    {clientIds.length > 1 && <div>
                        {Object.keys(etatsConsommationData).map(key => {
                            return <div className='content-div'>
                                <h3>{etatsConsommationData[key].souscripteur}</h3>
                                <EtatsConsommationsNew ref={ref} clientName={etatsConsommationData[key].souscripteur} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsConsommationData[key].datas} showLoading={showLoading} />
                            </div>
                        })}
                    </div>}

                    {clientIds.length === 1 && <EtatsConsommationsNew ref={ref} clientName={clientName} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsConsommationData} showLoading={showLoading} />}


                        {/*nomRapport === 'visitesPrestataires' */}
                    {clientIds.length > 1 && <div>
                        {Object.keys(etatsVisitesData).map(key => {
                            return <div className='content-div'>
                                <h3>{etatsVisitesData[key].souscripteur}</h3>
                                <EtatsVisitesNew ref={ref} clientName={etatsVisitesData[key].souscripteur} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsVisitesData[key].datas} showLoading={showLoading} />
                            </div>
                        })}
                    </div>}
                    {clientIds.length === 1 && <EtatsVisitesNew ref={ref} clientName={clientName} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsVisitesData} showLoading={showLoading} />}
                    
                        {/*nomRapport === 'depenseRubrique' */}
                        {clientIds.length > 1 && <div>
                            {Object.keys(etatsDepenseRubriqueData).map(key => {
                                return <div className='content-div'>
                                    <h3>{etatsDepenseRubriqueData[key].souscripteur}</h3>
                                    <EtatsDepenseRubriqueNew clientName={etatsDepenseRubriqueData[key].souscripteur} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsDepenseRubriqueData[key].datas} showLoading={showLoading} />
                                </div>
                            })}
                        </div>}
                        {clientIds.length === 1 && <EtatsDepenseRubriqueNew clientName={clientName} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsDepenseRubriqueData} showLoading={showLoading} />}

                        {/*nomRapport === 'topPathologies' */}
                        {clientIds.length > 1 && <div>
                            {Object.keys(etatsTopPathologiesData).map(key => {
                                return <div className='content-div'>
                                    <h3>{etatsTopPathologiesData[key].souscripteur}</h3>
                                    <EtatTopPathologiesNew clientName={etatsTopPathologiesData[key].souscripteur} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsTopPathologiesData[key].datas} showLoading={showLoading} />
                                </div>
                            })}
                        </div>}
                        {clientIds.length === 1 && <EtatTopPathologiesNew clientName={clientName} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsTopPathologiesData} showLoading={showLoading} />}


                        {/*nomRapport === 'topSpecialistes' */}
                        {clientIds.length > 1 && <div>
                            {Object.keys(etatsTopSpecialistesVisitesData).map(key => {
                                return <div className='content-div'>
                                    <h3>{etatsTopSpecialistesVisitesData[key].souscripteur}</h3>
                                    <EtatsVisistePrestataireNew clientName={etatsTopSpecialistesVisitesData[key].souscripteur} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsTopSpecialistesVisitesData[key].datas} showLoading={showLoading} />
                                </div>
                            })}
                        </div>}
                        {clientIds.length === 1 && <EtatsVisistePrestataireNew clientName={clientName} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsTopSpecialistesVisitesData} showLoading={showLoading} />}


                        {/*nomRapport === 'topConsommateurs' */}
                    {clientIds.length > 1 && <div>
                        {Object.keys(etatsTopConsommateursData).map(key => {
                            return <div className='content-div'>
                                <h3>{etatsTopConsommateursData[key].souscripteur}</h3>
                                <EtatsTopConsommationsNew ref={ref} clientName={etatsTopConsommateursData[key].souscripteur} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsTopConsommateursData[key].datas.slice(0, maxResults + 1)} type='Famille' showLoading={showLoading} />
                            </div>
                        })}
                    </div>}
                    {clientIds.length === 1 && <EtatsTopConsommationsNew ref={ref} clientName={clientName} dateMin={dateMin} dateMax={dateMax} clientIds={clientIds} data={etatsTopConsommateursData.slice(0, maxResults + 1)} type='Famille' showLoading={showLoading} />}
                    

                    </div>
                </div>

            </div>
        </div>
    )
}
