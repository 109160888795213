import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import './EtatsGlobaux.css';
import { Button } from 'primereact/button';
import { usePDF, Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
import PieChartNew from '../../chart/PieChartNew';



const EtatTopPathologiesNew = (props) => {
    const { t } = useTranslation();
    const dt = useRef(null);
    const { data, showLoading, clientIds, dateMin, dateMax, clientName } = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media print {
          .p-datatable th, .p-datatable td {
            padding: 8px !important;
            font-size: 10px !important;
          }
        }
      `,
        // Ajoute une entête sur chaque page du PDF
        onBeforeGetContent: () => {
            const doc = document.querySelector('.p-datatable');
            if (doc) {
                // Applique des sauts de page personnalisés et autres styles
                let pageBreaks = document.querySelectorAll('.p-datatable-pagebreak');
                pageBreaks.forEach((breakElement) => breakElement.remove()); // Nettoie les sauts de page existants
                let rows = doc.querySelectorAll('.p-datatable-row');
                for (let i = 15; i < rows.length; i += 15) {
                    let pageBreak = document.createElement('div');
                    pageBreak.classList.add('p-datatable-pagebreak');
                    rows[i].parentNode.insertBefore(pageBreak, rows[i]);
                }
            }
        },
    });

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataAmbuSeries, setDataAmbuSeries] = useState([]);
    const [dataAmbuSerie2, setDataAmbuSerie2] = useState([]);
    const [dataHospiSeries, setDataHospiSeries] = useState([]);
    const [dataHospiSerie2, setDataHospiSerie2] = useState([]);
    const [dataAmbuLabels, setDataAmbuLabels] = useState([]);
    const [dataHospiLabels, setDataHospiLabels] = useState([]);
    const [graph, setGraph] = useState(false);

    useEffect(() => {
        if (data.length > 0) {
            console.log(data);
            setStatsDatas(data.map(key => {
                return {
                    statName: key.name,
                    totalPrix: key["total"]["montant"],
                    totalMontant: key["total"]["nbreOccurence"],
                    totalPourcent: parseFloat(key["total"]["taux"]),
                    ambuPrix: key["ambulatoire"]["montant"],
                    ambuMontant: key["ambulatoire"]["nbreOccurence"],
                    ambuPourcent: parseFloat(key["ambulatoire"]["taux"]),
                    hospiPrix: key["hospitalier"]["montant"],
                    hospiMontant: key["hospitalier"]["nbreOccurence"],
                    hospiPourcent: parseFloat(key["hospitalier"]["taux"]),

                }
            }))

            let prestsAmbu = data.filter(elt => elt.ambulatoire.nbreOccurence > 0).sort((a, b) => b.ambulatoire.nbreOccurence - a.ambulatoire.nbreOccurence);
            let prestsHospi = data.filter(elt => elt.hospitalier.nbreOccurence > 0).sort((a, b) => b.hospitalier.nbreOccurence - a.hospitalier.nbreOccurence);

           

            setDataHospiSeries([{
                data: prestsHospi.map(key => {
                    return key["hospitalier"]["nbreOccurence"]
                })
            }]);
            setDataAmbuLabels(prestsAmbu.map(key => key.name));
            setDataHospiLabels(prestsHospi.map(key => key.name));

            setDataHospiSerie2(
                prestsHospi.map(key => {
                    return key["hospitalier"]["nbreOccurence"]
                })
            );

            setDataAmbuSerie2(
                prestsAmbu.map(key => {
                    return key["ambulatoire"]["nbreOccurence"]
                })
        );
        }
    }, [data]);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header={t("common.pathology")} rowSpan={2} />
                <Column header={t("common.total")} colSpan={3} />
                <Column header={t("common.ambulatory")} colSpan={3} />
                <Column header={t("common.hospital")} colSpan={3} />
            </Row>
            <Row>
                <Column header={t("facturation.amount")} field="totalPrix" />
                <Column header={t("common.num")} field="totalMontant" />
                <Column header={t("common.rate")} field="totalPourcen" />
                <Column header={t("facturation.amount")} field="ambuPrix" />
                <Column header={t("common.num")} field="ambuMontant" />
                <Column header={t("common.rate")} field="ambuPourcen" />
                <Column header={t("facturation.amount")} field="hospiPrix" />
                <Column header={t("common.num")} field="hospiMontant" />
                <Column header={t("common.rate")} field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );


    // Fonction pour exporter en Excel
    const transformData = (data) => {
        return data.map(item => ({
            pathologie: item.statName,
            Total_Montant: item.totalPrix,
            Total_Nombre: item.totalMontant,
            Total_Pourcentage: item.totalPourcent,
            Ambulance_Montant: item.ambuPrix,
            Ambulance_Nombre: item.ambuMontant,
            Ambulance_Pourcentage: item.ambuPourcent,
            Hospitalisation_Montant: item.hospiPrix,
            Hospitalisation_Nombre: item.hospiMontant,
            Hospitalisation_Pourcentage: item.hospiPourcent,

        }));
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(transformData(statsDatas), { skipHeader: false });
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, 'Pathologies_Diagnostiquées');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    //PDF
    const { toPDF, targetRef } = usePDF({ filename: 'Pathologies_Diagnostiquées.pdf', page: { margin: Margin.SMALL } })

    //fichier csv
    const exportToCSVe = (datacsv) => {
        console.log(statsDatas)
        const csvRows = [];
        const headers = [
            t("common.pathology"),
            'Total' + t("facturation.amount"),
            'Total ' + t("common.num"),
            'Total ' + t("common.rate"),
            t("common.ambulatory") + t("facturation.amount"),
            t("common.ambulatory") + t("common.num"),
            t("common.ambulatory") + t("common.rate"),
            t("common.hospital") + t("facturation.amount"),
            t("common.hospital") + t("common.num"),
            t("common.hospital") + t("common.rate"),

        ];
        csvRows.push(headers.join(','));

        datacsv.forEach(item => {
            const row = [
                item.name,
                item.total.montant,
                item.total.nbreOccurence,
                item.total.taux,
                item.ambulatoire.montant,
                item.ambulatoire.nbreOccurence,
                item.ambulatoire.taux,
                item.hospitalier.montant,
                item.hospitalier.nbreOccurence,
                item.hospitalier.taux,


            ];
            csvRows.push(row.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Pathologies_Diagnostiquées.csv');
        a.click();
    };

    return (
        <>
            {Object.keys(data).length === 0 && <span>
                {t("common.no-data-display")}
            </span>
            }
            {Object.keys(data).length > 0 && 
            <>
            <div style={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "3em", paddingLeft: "2em", paddingRight: "2em", paddingTop: "1em", }}>
                        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', marginLeft: "5em" }}>
                            <div style={{ fontSize: 15, fontWeight: "lighter", width: "100%", color: 'black', marginRight: "0.5em", marginTop: "1.5em", marginBottom: "0.5em", }}>5- Etat Pathologies</div>
                            <DataTable
                                value={statsDatas}
                                showGridlines
                                headerColumnGroup={headerGroup}
                                tableStyle={{ minWidth: '50rem', width: '100%', }}
                               
                            >
                                <Column field="statName"></Column>
                                <Column field="totalPrix"></Column>
                                <Column field="totalMontant"></Column>
                                <Column field="totalPourcent"></Column>
                                <Column field="ambuPrix"></Column>
                                <Column field="ambuMontant"></Column>
                                <Column field="ambuPourcent"></Column>
                                <Column field="hospiPrix"></Column>
                                <Column field="hospiMontant"></Column>
                                <Column field="hospiPourcent"></Column>
                            </DataTable>
                        </div>
                    </div>
                    <div style={{ marginLeft:  "2.5em",}}>
                        <div style={{ width: "100%", alignContent: "center", marginLeft: 50 }}>
                            <div style={{ alignSelf: "center", marginLeft: 20 }} >
                                <div className='col-15'>
                                    <PieChartNew dt={dt} pieWidth="700" type='' title={t("common.section-outpatient")} series={dataAmbuSerie2} labels={dataAmbuLabels} height="150" legendPosition="left" />
                                </div>
                                <div className='col-15'>
                                    <PieChartNew dt={dt} title={t("common.section-hospi")} type='' series={dataHospiSerie2} pieWidth="700" labels={dataHospiLabels} legendPosition="left" />
                                </div>
                            </div>
                        </div>
                    </div>
            </>
            }
        </>
    )
}
export default EtatTopPathologiesNew;