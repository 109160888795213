import React, { useState, useEffect, useRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Webcam from 'react-webcam';
import { useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import {
    startLoader,
    registerClientAction,
    listClientsAction,
    getClientAction,
    updateClientAction,
    getClientDataAction
} from '../../../../store/actions/ClientAuthActions';
import { getParameterAction } from '../../../../store/actions/AdministrationAction';
import { getBeneficiaireAction } from '../../../../store/actions/ContratActions';
import { Button, Spinner } from "react-bootstrap";
import SelectSearch from "react-select-search";
import Select from 'react-select';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Steps } from 'primereact/steps';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Button as PButton } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { FileUpload } from 'primereact/fileupload';
import * as faceapi from 'face-api.js';
import { warningToastr, successToastr, infoToastr } from '../../../../Utils/ToastrMessage';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import '../Styles/selectStyle.css';
import { useTranslation } from "react-i18next";
import CardClient from '../CardClient';
function ClientRegistration(props) {
    const dispatch = useDispatch();
    const { id, updateMode } = useParams();
    const { clients, admin, client, updateClient, history } = props;
    const { t } = useTranslation();
    const items = [
        {
            label: t('client.biometry')
        },
        {
            label: t('client.personal-infos')
        },
        {
            label: t('client.health-params')
        },
        {
            label: t('client.location')
        },
        {
            label: t('client.complementary-infos')
        },
        {
            label: t('client.confirmation')
        }
    ];

    const [activeIndex, setActiveIndex] = useState(0);
    const [pictureType, setPictureType] = useState("camera");

    const [choosenCountry, setChoosenCountry] = useState([]);
    const [choosenActivity, setChoosenActivity] = useState([]);

    const [imgSrc, setImgSrc] = useState(null);
    const [uploadImgSrc, setUploadImgSrc] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [whatsappPhone, setWhatsappPhone] = useState("");
    const [lon, setLon] = useState(0);
    const [lat, setLat] = useState(0);
    const [postal, setPostal] = useState("");
    const [idCity, setIdCity] = useState(0);
    const [idCountry, setIdCountry] = useState(0);
    const [email, setEmail] = useState("");
    const [sexe, setSexe] = useState("");
    const [birthdate, setBirthdate] = useState(new Date());
    const [idProfession, setIdProfession] = useState(0);
    const [idWorkingField, setIdWorkingField] = useState(0);
    //const [niu, setNiu] = useState("");
    const [maritalSituation, setMaritalSituation] = useState("");
    const [childNumber, setChildNumber] = useState("0");
    const [numAssure, setNumAssure] = useState("");


    const [type, setType] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [deleveryDate, setDeleveryDate] = useState(new Date());
    const [expirationDate, setExpirationDate] = useState(new Date());

    const [weight, setWeight] = useState(0);
    const [height, setHeight] = useState(0);
    const [bloodPressionD, setBloodPressionD] = useState(0);
    const [bloodPressionS, setBloodPressionS] = useState(0);

    const [modelsLoaded, setModelsLoaded] = useState(null);

    const [phoneNumbers, setPhoneNumbers] = useState([]);

    let errorsObj = { image: '', firstName: '' };
    const [errors, setErrors] = useState(errorsObj);

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if (!theMenu) {
            return false;
        } else {
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }

    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if (hasMenu('MENENROLL') && canDo('MENENROLL', 'ACENRRF')) {
            const controller = new AbortController();
            const signal = controller.signal;

            if (updateMode === "1") {
                dispatch(getClientDataAction(id, history));
            } else {
                dispatch(getBeneficiaireAction(id, history));
            }
            //dispatch(listClientsAction());
            dispatch(getParameterAction());

            const loadModels = async () => {
                const MODEL_URL = process.env.PUBLIC_URL + '/models';

                Promise.all([
                    faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                    faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
                    faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
                    faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
                    faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
                ]).then(setModelsLoaded(true));
            }
            loadModels();

            return () => {
                controller.abort();
            };
        } else {
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        if (updateMode === "1") {
            setFirstName(updateClient.firstName ? updateClient.firstName : "");
            setLastName(updateClient.lastName ? updateClient.lastName : "");
            setPhoneNumber(updateClient.phoneNumber || "");
            setWhatsappPhone(updateClient.whatsappPhone || "");
            setEmail(updateClient.email || "");
            setSexe(updateClient.sexe || "");
            setBirthdate(new Date(updateClient.birthdate));
            setType(updateClient.cni ? "cni" : "");
            setIdNumber(updateClient.clientIdDetails?.type || "");
            setNumAssure(updateClient.idClient);
            setLon(updateClient.lon || "");
            setLat(updateClient.lat || "");
            setPostal(updateClient.postal || "");
            setIdCity(updateClient.idCity || 0);
            setIdCountry(updateClient.idCountry || 0);
            setIdProfession(updateClient.idProfession || 0);
            setIdWorkingField(updateClient.idWorkingField || 0);
            setMaritalSituation(updateClient.maritalSituation);
            setChildNumber(updateClient.childNumber || 0);
            setIdNumber(updateClient.clientIdDetails?.idNumber || "");
            setDeleveryDate(updateClient.clientIdDetails?.deleveryDate || new Date());
            setExpirationDate(updateClient.clientIdDetails?.expirationDate || new Date());
        } else {
            setFirstName(client.nom);
            setLastName(client.prenoms ? client.prenoms : "");
            setPhoneNumber(client.telephone);
            setWhatsappPhone(client.telephone);
            setEmail(client.email);
            setSexe(client.sexe);
            setBirthdate(new Date(client.dateNaissance));
            setType(client.cni ? "cni" : "");
            setIdNumber(client.cni ? client.cni : "");
            setNumAssure(client.id);
        }
    }, [client, updateClient]);

    const { webcamRef, facesDetected } = useFaceDetection({
        faceDetectionOptions: {
            model: 'short',
        },
        faceDetection: new FaceDetection.FaceDetection({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
        }),
        camera: ({
            mediaSrc, onFrame, width, height,
        }) => new Camera(mediaSrc, {
            onFrame,
            width,
            height,
        })
    });

    const capture = () => {
        const errorObj = { ...errorsObj };
        errorObj.image = "";
        if(facesDetected === 1) {
            const imageSrc = webcamRef.current.getScreenshot();
            setImgSrc(imageSrc);
        } else {
            warningToastr(t('client.camera-waring'));
        }
        setErrors(errorObj);
    };

    const recapture = () => {
        setImgSrc(null);
    }

    const base64MimeType = (encoded) => {
        var result = null;

        if (typeof encoded !== 'string') {
            return result;
        }

        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).,./);

        if (mime && mime.length) {
            result = mime[1];
        }

        return result;
    }

    const urlToFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    async function onSignUp(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        let imageFile = null;

        if (imgSrc) {

            const mimeTypeImg = base64MimeType(imgSrc);

            const filename = client.id + '.jpg';

            imageFile = await urlToFile(imgSrc, filename, mimeTypeImg);
        }else{
            imageFile = uploadImgSrc;
        }

        dispatch(startLoader());
        if (updateMode === "1") {
            let clientDatas = {
                firstName,
                lastName,
                phoneNumber,
                whatsappPhone,
                lon: lon.toString(),
                lat: lat.toString(),
                postal,
                idCity,
                idCountry,
                email,
                sexe,
                birthdate,
                idProfession,
                idWorkingField,
                //niu,
                maritalSituation,
                childNumber,
                numeroPolice: updateClient.numeroPolice,
                numeroBeneficiaire: updateClient.numeroBeneficiaire,
                numeroBeneficiairePrin: updateClient.numeroBeneficiairePrin,
                nomBeneficiairePrin: updateClient.nomBeneficiairePrin,
                telBeneficiairePrin: updateClient.telBeneficiairePrin,
                numeroAssurer: updateClient.numeroAssurer,
                souscripteur: updateClient.souscripteur,
                assureur: updateClient.assureur,
                numeroAssureur: updateClient.numeroAssureur,
                phoneNumbers: phoneNumbers.map(elt => {
                    return { phoneNumber: elt }
                }),
                clientIdDetails: {
                    type,
                    idNumber,
                    deleveryDate,
                    expirationDate
                },
                healhtParameters: {
                    height,
                    weight,
                    bloodPressionD,
                    bloodPressionS
                }
            };
            dispatch(updateClientAction(id, imageFile, numAssure, clientDatas, props.history, imgSrc ? imgSrc : uploadImgSrc));
        } else {
            let clientDatas = {
                firstName,
                lastName,
                phoneNumber,
                whatsappPhone,
                lon: lon.toString(),
                lat: lat.toString(),
                postal,
                idCity,
                idCountry,
                email,
                sexe,
                birthdate,
                idProfession,
                idWorkingField,
                //niu,
                maritalSituation,
                childNumber,
                numeroPolice: client.numeroPolice,
                numeroBeneficiaire: client.numBeneficiaire,
                numeroBeneficiairePrin: client.numBeneficiairePrincipal,
                nomBeneficiairePrin: client.nomBeneficiairePrinc,
                telBeneficiairePrin: client.telBeneficiairePrinc,
                numeroAssurer: client.numBeneficiairePrincipal,
                souscripteur: client.souscripteur?.nom_Assu,
                assureur: client.police?.codeIntermediaire,
                numeroAssureur: client.police?.codeIntermediaire,
                phoneNumbers: phoneNumbers.map(elt => {
                    return { phoneNumber: elt }
                }),
                clientIdDetails: {
                    type,
                    idNumber,
                    deleveryDate,
                    expirationDate
                },
                healhtParameters: {
                    height,
                    weight,
                    bloodPressionD,
                    bloodPressionS
                }
            };
            dispatch(registerClientAction(imageFile, numAssure, clientDatas, props.history, imgSrc ? imgSrc : uploadImgSrc));
        }
    }

    const onUpload = (e) => {
        //e.preventDefault();
        let upFiles = e.files;
        const keys = Object.keys(upFiles);
        setUploadImgSrc(upFiles[keys[0]]);
    }



    useEffect(async () => {
        if (uploadImgSrc) {
            infoToastr(t('client.facescan'));
            const myImg = document.getElementById("uploadedFile");
            const detectedFaces = await faceapi.detectAllFaces(myImg);
            if (detectedFaces.length > 0) {
                if (detectedFaces.length === 1) {
                    successToastr(t('client.invalidpic'));
                } else {
                    warningToastr(t('client.more-face'));
                    setUploadImgSrc(null);
                }
            } else {
                warningToastr(t('client.no-face'));
                setUploadImgSrc(null);
            }
        }
    }, [uploadImgSrc]);

    const canGoNext = (index) => {
        switch (index) {
            case 0:
                return imgSrc || uploadImgSrc;
            case 1:
                return lastName?.trim().length > 0 &&
                    firstName?.trim().length > 0 &&
                    email?.trim().length > 0 &&
                    whatsappPhone?.trim().length > 0 &&
                    phoneNumber?.trim().length > 0 &&
                    idNumber?.trim().length > 0 &&
                    deleveryDate &&
                    expirationDate &&
                    type?.trim().length > 0 &&
                    phoneNumbers.filter(elt => elt.trim().length === 0).length === 0;
            case 2:
                return true
            case 3:
                return idCity && idCountry
            case 4:
                return maritalSituation?.trim().length > 0 &&
                    childNumber >= 0;
            default:
                return false;
        }
    }

    const canSubmitForm = () => {
        return canGoNext(0) && canGoNext(1) && canGoNext(2) && canGoNext(3) && canGoNext(4);
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };


    return (
        <div>

            <div className="card py-4">
                <h3 className='text-center mb-4 '>
                    Enrôlement de <b>{client.nom}</b>
                </h3>
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={!canSubmitForm()} />
                <form onSubmit={onSignUp}>
                    {/** modif */}          {activeIndex === 0 && <div className='px-5 pt-5'>
                        <Splitter style={{ height: '300px' }}>
                            <SplitterPanel className="flex align-items-center justify-content-center">
                                <Splitter style={{ height: '100%' }} layout="vertical">
                                    <SplitterPanel className="flex align-items-center justify-content-center" style={{ position: 'relative' }}>
                                        <RadioButton style={{ position: 'absolute', top: '10px', left: '10px' }} inputId="pictype1" name="picture" value="camera" onChange={(e) => { setPictureType(e.value); setUploadImgSrc(null) }} checked={pictureType === 'camera'} />
                                        <PButton
                                            label={imgSrc ? t('client.resnap') : t('client.snap')}
                                            icon={imgSrc ? "pi pi-check" : "pi pi-camera"}
                                            severity={imgSrc ? "success" : "primary"}
                                            disabled={pictureType === 'file'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (imgSrc) {
                                                    recapture();
                                                } else {
                                                    capture();
                                                }
                                            }}
                                        />
                                    </SplitterPanel>
                                    <SplitterPanel className="flex align-items-center justify-content-center" style={{ position: 'relative' }}>
                                        <RadioButton style={{ position: 'absolute', top: '10px', left: '10px' }} inputId="pictype2" name="picture" value="file" onChange={(e) => { setPictureType(e.value); setImgSrc(null) }} checked={pictureType === 'file'} />
                                        <FileUpload mode="basic" name="demo[]" url="" accept="image/*" maxFileSize={100000000} onSelect={e => onUpload(e)} onClear={e => setUploadImgSrc(null)} disabled={pictureType === 'camera'} chooseLabel={"Ajouter une photo"} />
                                    </SplitterPanel>
                                </Splitter>
                            </SplitterPanel>
                            <SplitterPanel className="flex align-items-center justify-content-center" style={{ overflow: 'hidden' }}>
                                {pictureType === 'camera' && <div className='picture-frame'
                                    style={{
                                        width: '100%',
                                    }}>
                                    {!imgSrc && (
                                        <Webcam
                                            audio={false}
                                            mirrored
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        />
                                    )}
                                    {imgSrc && (
                                        <img
                                            src={imgSrc}
                                            alt="camimg"
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        />
                                    )}
                                </div>}
                                {pictureType === 'file' &&
                                    <>
                                        {uploadImgSrc && <img
                                            id="uploadedFile"
                                            src={uploadImgSrc.objectURL}
                                            alt="uploadimg"
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        />}
                                        {!uploadImgSrc && <i className="pi pi-image" style={{ fontSize: '2.5rem' }}></i>}
                                    </>
                                }
                                {errors.image && <div className="text-danger fs-12 my-1">{errors.image}</div>}
                            </SplitterPanel>
                        </Splitter>

                        <div className='row justify-content-end pt-4 pr-4 align-items-center'>
                            <button className='btn btn-primary' disabled={!canGoNext(activeIndex)} onClick={e => setActiveIndex(activeIndex + 1)}>Suivant</button>
                        </div>
                    </div>}


                    {activeIndex === 1 && <div className='px-5 pt-5'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.name')}<span className='text-danger'>*</span></strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />

                                {errors.firstName && <div className="text-danger fs-12">{errors.firstName}</div>}
                            </div>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.firstname')}<span className='text-danger'>*</span></strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>

                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>Sexe<span className='text-danger'>*</span></strong>
                                </label>
                                <select
                                    value={sexe}
                                    disabled="true"
                                    className='form-control form-control-lg bg-light'
                                    onChange={(e) => setSexe(e.target.value)}
                                >
                                    <option value='option'>
                                        {t('common.choose')}
                                    </option>
                                    <option value='F'>{t('common.female')}</option>
                                    <option value='M'>{t('common.male')}</option>
                                </select>
                            </div>

                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.birthdate')}<span className='text-danger'>*</span></strong>
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        label=""
                                        clearable
                                        disabled="true"
                                        format="dd/MM/yyyy"
                                        disableFuture
                                        value={birthdate}
                                        onChange={setBirthdate}
                                        className="bg-light"
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong> {t("common.insured-number")}<span className='text-danger'>*</span></strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={numAssure}
                                    onChange={(e) => setNumAssure(e.target.value)}
                                />

                                {errors.numAssure && <div className="text-danger fs-12">{errors.numAssure}</div>}
                            </div>
                        </div>

                        <div className='row justify-content-between align-items-center'>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.email')}<span className='text-danger'>*</span></strong>
                                </label>
                                <div className='input-group mb-3 input-success-o'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text'>
                                            <i className='fa fa-at' />
                                        </span>
                                    </div>
                                    <input type='email' className='form-control'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>Whatsapp<span className='text-danger'>*</span></strong>
                                </label>

                                <div className='input-group mb-3 input-success-o'>
                                    {/*<input type='text' className='form-control'
                                value={whatsappPhone}
                                onChange={(e) => setWhatsappPhone(e.target.value)}
                                />*/}

                                    <PhoneInput
                                        country='cm'
                                        onlyCountries={['cm', 'ci', 'cd']}
                                        localization='fr'
                                        value={whatsappPhone}
                                        autoFormat={whatsappPhone ? !whatsappPhone.startsWith('225') : true}
                                        onChange={phone => setWhatsappPhone(phone)}
                                    />
                                </div>

                            </div>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.phone')}<span className='text-danger'>*</span></strong>
                                </label>
                                <div className='input-group mb-3 input-success-o'>
                                    {/*<input type='text' className='form-control'
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    />*/}

                                    <PhoneInput
                                        country='cm'
                                        onlyCountries={['cm', 'ci', 'cd']}
                                        localization='fr'
                                        value={phoneNumber}
                                        autoFormat={phoneNumber ? !phoneNumber.startsWith('225') : true}
                                        onChange={phone => setPhoneNumber(phone)}
                                    />
                                </div>
                            </div>
                            {/*<div className='col-3 form-group'>
                            <label className='mb-1 '>
                                <strong>NIU<span className='text-danger'>*</span></strong>
                            </label>
                            <input type='text' className='form-control'
                                value={niu}
                                onChange={(e) => setNiu(e.target.value)}
                                />
                        </div>*/}
                        </div>

                        <div className='row justify-content-between align-items-center'>
                            {phoneNumbers.map((phone, i) => (
                                <div className='col-4 form-group' style={{ position: "relative" }} key={i}>
                                    <label className='mb-1 '>
                                        <strong>{t('additional-phone')}{' ' + (i + 1)}</strong>
                                    </label>
                                    <div className='input-group mb-3 input-success-o'>

                                        <PhoneInput
                                            country='cm'
                                            onlyCountries={['cm', 'ci', 'cd']}
                                            localization='fr'
                                            value={phone}
                                            autoFormat={phone ? !phone.startsWith('225') : true}
                                            onChange={val => {
                                                let temp = [...phoneNumbers];
                                                temp[i] = val;
                                                setPhoneNumbers(temp);
                                            }}
                                        />
                                        <a
                                            type="button"
                                            className='icons-sm text-danger'
                                            style={{
                                                position: "absolute",
                                                top: '-35px',
                                                right: '-15px',
                                            }}
                                            onClick={() => {
                                                const temp = [...phoneNumbers];
                                                temp.splice(i, 1);
                                                setPhoneNumbers(temp);
                                            }}
                                        >
                                            <i className="fa fa-close"></i>
                                        </a>
                                    </div>
                                </div>
                            ))}

                            <div className='col-4 form-group'>
                                <PButton
                                    label={t("common.add-phone")}
                                    icon="pi pi-plus"
                                    severity="info"
                                    onClick={(e) => {
                                        console.log("the action is called");
                                        e.preventDefault();
                                        setPhoneNumbers(prevPhoneNumbers => [...prevPhoneNumbers, ""]);
                                    }}
                                />
                            </div>
                        </div>

                        <div className='row justify-content-between align-items-center'>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.type')}<span className='text-danger'>*</span></strong>
                                </label>
                                <select
                                    defaultValue={type}
                                    className='form-control form-control-lg'
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option value='option'>
                                        {t('common.choose')}
                                    </option>
                                    <option value='cni'>CNI</option>
                                    <option value='passeport'> {t("provider.passport")}</option>
                                    <option value='carte électeur'>{t('common.electoral-card')}</option>
                                    <option value='permis de conduire'>{t('common.driver-license')}</option>
                                    <option value='carte de service'>{t('common.service-card')}</option>
                                </select>
                            </div>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.number')}<span className='text-danger'>*</span></strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={idNumber}
                                    onChange={(e) => setIdNumber(e.target.value)}
                                />
                            </div>

                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.delivery-date')}<span className='text-danger'>*</span></strong>
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        label=""
                                        clearable
                                        format="dd/MM/yyyy"
                                        disableFuture
                                        value={deleveryDate}
                                        onChange={setDeleveryDate}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.expiration-date')}<span className='text-danger'>*</span></strong>
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        label=""
                                        clearable
                                        format="dd/MM/yyyy"
                                        disablePast
                                        value={expirationDate}
                                        onChange={setExpirationDate}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className='row justify-content-end pt-4 pr-4 align-items-center'>
                            <button className='btn btn-info mr-2' onClick={e => setActiveIndex(activeIndex - 1)}>{t("provider.previous")}</button>
                            <button className='btn btn-primary' disabled={!canGoNext(activeIndex)} onClick={e => setActiveIndex(activeIndex + 1)}>{t("provider.next")}</button>
                        </div>
                    </div>}
                    {activeIndex === 2 && <div className='px-5 pt-5'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.height')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                />

                                {errors.firstName && <div className="text-danger fs-12">{errors.firstName}</div>}
                            </div>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.weight')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                />
                            </div>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.diastolicPressure')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={bloodPressionS}
                                    onChange={(e) => setBloodPressionS(e.target.value)}
                                />

                            </div>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.systolicPressure')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={bloodPressionD}
                                    onChange={(e) => setBloodPressionD(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='row justify-content-end pt-4 pr-4 align-items-center'>
                            <button className='btn btn-info mr-2' onClick={e => setActiveIndex(activeIndex - 1)}>{t("provider.previous")}</button>
                            <button className='btn btn-primary' disabled={canGoNext(activeIndex)} onClick={e => setActiveIndex(activeIndex + 1)}>{t("provider.next")}</button>
                        </div>
                    </div>}
                    {activeIndex === 3 && <div className='px-5 pt-5'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.country')}<span className='text-danger'>*</span></strong>
                                </label>
                                <Select
                                    className="select-search"
                                    value={admin.countries?.filter(elt => elt.id === idCountry).map((opt) => { return { label: opt.name, value: opt.id } })}
                                    options={admin.countries?.map((opt) => { return { label: opt.name, value: opt.id } })}
                                    placeholder={t('client.chooseCountry')}
                                    search
                                    onChange={(val) => {
                                        setChoosenCountry(admin.countries?.filter((country) => { return country.id === val.value }))
                                        setIdCountry(val.value);
                                    }}
                                    style={{
                                        lineHeight: '40px',
                                        color: '#7e7e7e',
                                        paddingLeft: ' 15px',
                                    }}
                                />
                            </div>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.city')}<span className='text-danger'>*</span></strong>
                                </label>
                                <Select
                                    className="select-search"
                                    value={choosenCountry[0]?.city.filter(elt => elt.id === idCity).map((opt) => { return { label: opt.name, value: opt.id } })}
                                    options={choosenCountry[0]?.city.map((opt) => { return { label: opt.name, value: opt.id } })}
                                    placeholder={t('client.chooseCity')}
                                    isDisabled={props.showLoading || choosenCountry.length === 0}
                                    search
                                    onChange={(val) => {
                                        setIdCity(val.value);
                                    }}
                                    style={{
                                        lineHeight: '40px',
                                        color: '#7e7e7e',
                                        paddingLeft: ' 15px',
                                    }}
                                />
                            </div>


                            <div className='col-2 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.postalCode')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={postal}
                                    onChange={(e) => setPostal(e.target.value)}
                                />
                            </div>

                            <div className='col-5 form-group'>
                                <label className='mb-1 '>
                                    <strong>Latitude</strong>
                                </label>
                                <input type='number' className='form-control'
                                    value={lat}
                                    onChange={(e) => setLat(e.target.value)}
                                />
                            </div>
                            <div className='col-5 form-group'>
                                <label className='mb-1 '>
                                    <strong>Longitude</strong>
                                </label>
                                <input type='number' className='form-control'
                                    value={lon}
                                    onChange={(e) => setLon(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='row justify-content-end pt-4 pr-4 align-items-center'>
                            <button className='btn btn-info mr-2' onClick={e => setActiveIndex(activeIndex - 1)}>{t("provider.previous")}</button>
                            <button className='btn btn-primary' disabled={!canGoNext(activeIndex)} onClick={e => setActiveIndex(activeIndex + 1)}>{t("provider.next")}</button>
                        </div>
                    </div>}
                    {activeIndex === 4 && <div className='px-5 pt-5'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.matrimony')}<span className='text-danger'>*</span></strong>
                                </label>
                                <select
                                    value={maritalSituation}
                                    className='form-control form-control-lg'
                                    onChange={(e) => setMaritalSituation(e.target.value)}
                                >
                                    <option value='option'>
                                        {t('common.choose')}
                                    </option>
                                    <option value='maried'>{t('maritalStatus.maried')}</option>
                                    <option value='single'>{t('maritalStatus.single')}</option>
                                    <option value='divorced'>{t('maritalStatus.divorced')}</option>
                                    <option value='widow'>{t('maritalStatus.widow')}</option>
                                </select>
                            </div>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.numberOfChildren')}<span className='text-danger'>*</span></strong>
                                </label>
                                <input type='number' className='form-control'
                                    value={childNumber}
                                    onChange={(e) => setChildNumber(e.target.value)}
                                />
                            </div>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.workingField')}</strong>
                                </label>
                                <Select
                                    className="select-search"
                                    value={admin.activities?.filter(elt => elt.id === idWorkingField).map((opt) => { return { label: opt.nameFr, value: opt.id } })}
                                    options={admin.activities?.map((opt) => { return { label: opt.nameFr, value: opt.id } })}
                                    placeholder={t('client.chooseWorkingField')}
                                    search
                                    onChange={(val) => {
                                        setChoosenActivity(admin.activities?.find((activity) => { return activity.id === val.value })?.prefession || [])
                                        setIdWorkingField(val.value);
                                    }}
                                    style={{
                                        lineHeight: '40px',
                                        color: '#7e7e7e',
                                        paddingLeft: ' 15px',
                                    }}
                                />
                            </div>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.job')}</strong>
                                </label>

                                <Select
                                    className="select-search"
                                    value={choosenActivity?.filter(elt => elt.id === idProfession).map((opt) => { return { label: opt.nameFr, value: opt.id } })}
                                    options={choosenActivity?.map((opt) => { return { label: opt.nameFr, value: opt.id } })}
                                    placeholder={t('client.chooseProfession')}
                                    isDisabled={props.showLoading || !choosenActivity.length}
                                    search
                                    onChange={(val) => {
                                        setIdProfession(val.value);
                                    }}
                                    style={{
                                        lineHeight: '40px',
                                        color: '#7e7e7e',
                                        paddingLeft: ' 15px',
                                    }}
                                />
                            </div>
                        </div>
                        <div className='row justify-content-end pt-4 pr-4 align-items-center'>
                            <button className='btn btn-info mr-2' onClick={e => setActiveIndex(activeIndex - 1)}>{t("provider.previous")}</button>
                            <button className='btn btn-primary' disabled={!canGoNext(activeIndex)} onClick={e => setActiveIndex(activeIndex + 1)}>{t("provider.next")}</button>
                        </div>
                    </div>}
                    {activeIndex === 5 && <div className='px-5 pt-5'>
                        <div className='row justify-content-start mb-2 align-items-center'>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.picture")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    {imgSrc && (
                                        <img
                                            src={imgSrc}
                                            alt="camimg"
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        />
                                    )}
                                    {uploadImgSrc && <img
                                        id="uploadedFile"
                                        src={uploadImgSrc.objectURL}
                                        alt="uploadimg"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    />}
                                </div>
                            </div>
                            <div className='col-9'></div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('common.name')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{firstName}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('common.firstname')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{lastName}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('common.email')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{email}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>Whatsapp</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{whatsappPhone}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong> {t("provider.phone")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{phoneNumber}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('client.type')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{type}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('client.number')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{idNumber}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('client.deliveryDate')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{formatDate(new Date(deleveryDate))}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('client.expirationDate')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{formatDate(new Date(expirationDate))}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('common.height')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{height}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('common.weight')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{weight}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('client.diastolicPressure')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{bloodPressionD}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('client.systoliccPressure')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{bloodPressionS}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('common.country')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{choosenCountry[0]?.name}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('common.city')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{choosenCountry[0]?.city?.find(elt => elt.id === idCity)?.name}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('common.matrimony')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{maritalSituation}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('client.nmberOfChildren')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{childNumber}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('client.workingField')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{admin.activities?.filter(elt => elt.id === idWorkingField)[0]?.nameFr}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('common.job')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{choosenActivity?.find(elt => elt.id === idProfession)?.nameFr}</span>
                                </div>
                            </div>
                        </div>

                        <div className='row justify-content-end px-3 align-items-center'>
                            {props.showLoading &&
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                </Button>}
                            {!props.showLoading &&
                                <>
                                    <button className='btn btn-info mr-2' onClick={e => setActiveIndex(activeIndex - 1)}>{t("provider.previous")}</button>
                                    <input type='submit' disabled={!canSubmitForm()} value="Enrôler" className='btn btn-primary' />
                                </>}
                        </div>
                    </div>}
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        clients: state.clientAuth.clients,
        client: state.contrat.beneficiaire,
        updateClient: state.clientAuth.clientData,
        admin: state.admin.administration,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.clientAuth.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(ClientRegistration));