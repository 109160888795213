import React from "react";
import Lang from "./nav/lang";
import Chatbot from "../pages/Chatbot";

const Footer = () => {
  const date = new Date();
  const annee = date.getFullYear();

  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © Activa {annee}
        </p>
      </div>
      {/*<Chatbot />*/}
    </div>
  );
};

export default Footer;