import React, {useState,useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { createTypesActesControlsAction, deleteTypesActesControlsAction, getTypesActesControlsAction, startLoader } from '../../../store/actions/ProviderActions';
import swal from 'sweetalert';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import {Spinner, Button} from 'react-bootstrap';
import { Button as PButton } from 'primereact/button';
import { DataTableBase } from '../DataTables/DataTableBase';
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";

const ParametrageTypeControlActes = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { controlType: '', description: '' };
    const [controlType, setControlType] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    //Modal box
    const [addCard, setAddCard] = useState(false);

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            controlType: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            description: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'controlType', headerName: t("provider.controlTypes"), filterPlaceholder: t("common.filter-typ-amend"), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'description', headerName: t("provider.description"), style: { minWidth: '12rem' }, filter: false, bodyTemplate: null},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAM') && canDo('MENPARAM', 'DELETESTANDACT') && <Link href="#" to="#" title="Close"
                onClick={() =>
                    swal({
                        title: t('provider.delete-control-type')+rowData.controlType+" ?",
                        text: t('client.delete-message'),
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            dispatch(startLoader());
                            dispatch(deleteTypesActesControlsAction(rowData.id));
                        }
                    })
                    }>
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        dispatch(startLoader());
        initFilters();
        dispatch(getTypesActesControlsAction());    
    }, []);


    useEffect(() => {
        setControlType("");
        setDescription("");
        setAddCard(false);  
    }, [props.typeActesControls]);
    
    function onLettreCleSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (controlType === '') {
            errorObj.typeIdent = t('common.acte-error');
            error = true;
        }

        if (description === '') {
            errorObj.typeIdent = t('common.acte-error');
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}

        let obj = { controlType, description };
        dispatch(startLoader());
        dispatch(createTypesActesControlsAction(obj));
    }

    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAM') && canDo('MENPARAM', 'CREATESTANDACT') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddCard(true)}
                    >
                        + {t('provider.new-control-type')}
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" show={addCard} onHide={setAddCard} >
            <div className="">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">{t('common.create-ident-param')}</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={onLettreCleSave}>
                                <div className='form-group col-12'>
                                    <label className='mb-1'>Type de contrôle</label>
                                    <input type='text' className='form-control'
                                        value={controlType}
                                        onChange={(e) => setControlType(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className='form-group col-12'>
                                    <label className='mb-1'>Description</label>
                                    <textarea rows={2} className='form-control'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                    />
                                </div>
                                <hr />
                                <div className='form-group align-items-center justify-content-center'>
                                    { props.showLoading && 
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            <span className="visually-hidden">{t('common.await-text')}</span>
                                        </Button> }
                                    { !props.showLoading &&
                                        <div className='col-4'>
                                            <button type='submit' className='btn btn-primary'>
                                               {t('common.save')}
                                            </button>
                                        </div> }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
            <DataTableBase 
                data={props.typeActesControls}
                emptyMessage={"Aucun type de contrôle trouvé"} 
                filters={filters}
                globalFiltersFields={['controlType', 'description']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'Type de contrôle', dataKey: 'controlType' },
                    { title: 'Description', dataKey: 'description' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"types_controles"}
                loading={props.showLoading}
                lazy={false}
            />

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        typeActesControls: state.provider.typeActesControls,
        errorMessage: state.provider.errorMessage,
        successMessage: state.provider.successMessage,
        showLoading: state.provider.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(ParametrageTypeControlActes);