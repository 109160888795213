import { BarChart } from "@mui/x-charts";
import React from "react";

import ReactApexChart from "react-apexcharts";
import { chartColors } from "./colors";



function valueFormatter(value) {
    return `${value}mm`;
}

const HorizontalBarChartNew = (props) => {
    const { series, height, labels, title, type } = props;

    const options = {
        chart: {
            type: 'bar',
            height: 450
        },

        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: labels,
        },
        title: {
            text: type === 'Actes' ? t("common.hospi-act") : title,
            align: "center", // Centre le titre
            style: {
                fontSize: "12px",
                color: "#333",

            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },

            }
        }]
    };

    const chartSetting = {
        xAxis: [
            {
                label: '',
            },
        ],

        width: 1300,
        height: 400,
    };

    return (
        <div style={{  marginBottom: 20, borderRadius: 5, width: 1350 }}>

            <BarChart
                dataset={labels.map((label, index) => ({
                    label: `${label.length > 20 ? label : label}`,
                    value: series[index],
                }))}
                yAxis={[{
                    scaleType: 'band',
                   dataKey: 'label',
                   min: 200,
                   max: 500,
                   
                }]}
                margin={{ left: 150 }}
                series={[{ dataKey: 'value', label: title, valueFormatter }]}
                slotProps={{ legend: { hidden: false } }}
                colors={chartColors}
                layout="horizontal"
                {...chartSetting}
                sx={{ marginLeft: 10, width: '50%', color: "red" }}
            />
        </div>
    );
}

export default HorizontalBarChartNew;