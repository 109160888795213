
import { connect, useDispatch } from 'react-redux';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import "./PreIdentify.css";
import "./PreIdentify.scss";

import swal from "sweetalert";
import {
    loadingToggleAction, identificationAssuAction, identificationCodeAssuAction, reInitAuthAction, resendSMSTriggerAction
} from '../../../store/actions/PatientAction';

const PreIdentify = (props) => {
    const { patient, authStepOne, authStepTwo, codeAttempts, currentUser } = props;

    const facialIdent = () => {
        history.push('/patient-identify');
    }

    const cardIdent = () => {
        history.push('/identification-assure');
    }

        // const canDo = (menuCode, actionCode) => {
        //     let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        //     if (!theMenu) {
        //         return false;
        //     } else {
        //         return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        //     }
        // }

        // const hasMenu = (menuCode) => {
        //     return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
        // }

    useEffect(() => {
        if (hasMenu('MENIDENT')) {
        } else {
            props.history.push("/");
        }
    }, []);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { history} = props;
    const [numAssu, setNumAssu] = useState('');
    const [code, setCode] = useState('');
    const [showResendSMS, setShowResendSMS] = useState(false);
    
    const authenticate = () => {
        if(!authStepOne && !authStepTwo) {
            let data = numAssu;
            dispatch(loadingToggleAction(true));
            dispatch(identificationAssuAction(data));
        }else{
            let data = {clientId: numAssu, code}; 
            dispatch(loadingToggleAction(true));
            dispatch(identificationCodeAssuAction(data, history));
        }
    }

    const resendSMS = () => {
        let data = numAssu;
        dispatch(loadingToggleAction(true));
        dispatch(resendSMSTriggerAction());
        dispatch(identificationAssuAction(data));
    }

    useEffect(() => {
        if(codeAttempts === 3) {
            dispatch(reInitAuthAction());
            setShowResendSMS(false);
        }
    }, [codeAttempts]);

    useEffect(() => {
        if(authStepOne) {
            setTimeout(() => {
                setShowResendSMS(true);
            }, 5000)
        }
    }, [authStepOne]);

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

      useEffect(() => {
        if(hasMenu('MENIDENT') && canDo('MENIDENT', 'ACIDENTNUMASSU')){
            dispatch(reInitAuthAction());
        }else{
            props.history.push("/");
        }
    }, []);



    return (
        <>
            <div className="page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <Link to="#">{t('common.patient')}</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="#">{t('layout.authentication')}</Link>
                    </li>
                </ol>
            </div>
            <div className="authincation h-100 p-meddle">
                <div className="container h-100" style={{ position: 'fixed' }}>


                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-6">

                            < div className="authincation-content" >
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form row">
                                            <h3 className="text-center mb-5">
                                                {t("patient.identification-patient")}
                                            </h3>
                                            {/* ###################### Start test ########## */}
                                            {/* <div className="justify-content-center rounded-circle">
                                                {!authStepOne && !authStepTwo && <>
                                                    <h5 className='mt-4 text-primary'>Etape 1</h5>
                                                    <div className="border-top mt-2 mb-4"></div>
                                                    <div className='row justify-content-between align-items-center'>
                                                        <div className='col-12 form-group'>
                                                            <label className='mb-1 '>
                                                                <strong>Numéro Assuré</strong>
                                                            </label>
                                                            <input type='text' className='form-control'
                                                                value={numAssu}
                                                                onChange={(e) => setNumAssu(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </>}
                                                {authStepOne && !authStepTwo && (codeAttempts < 3) && <>
                                                    <h5 className='mt-4 text-primary'>Etape 2</h5>
                                                    <div className="border-top mt-2 mb-4"></div>
                                                    <div className='row justify-content-between align-items-center'>
                                                        <div className='col-12 form-group'>
                                                            <label className='mb-1 '>
                                                                <strong>Code SMS({3 - codeAttempts} tentatives restantes)</strong>
                                                                <small>Entrez le code reçu par sms</small>
                                                            </label>
                                                            <input type='password' className='form-control'
                                                                value={code}
                                                                onChange={(e) => setCode(e.target.value)}
                                                            />
                                                            {showResendSMS && <a href="#"
                                                                style={{ color: "#24439D", textDecoration: "underline" }}
                                                                onClick={resendSMS}>
                                                                Renvoyer le code
                                                            </a>}
                                                        </div>
                                                    </div>
                                                </>}


                                                {props.showLoading &&
                                                    <Button variant="outline-primary btn-lg" disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />{' '}
                                                        <span className="visually-hidden">Identification en cours...</span>
                                                    </Button>}
                                                {!props.showLoading &&
                                                    <Button type="submit" variant="outline-primary btn-lg" onClick={authenticate}>Authentifier
                                                    </Button>
                                                }
                                            </div> */}
                                            {/* ###################### End test ########## */}

                                            <div>

                                                <div className="row justify-content-center">
                                                    {hasMenu('MENIDENT') && canDo('MENIDENT', 'ACIDENTRF') &&
                                                        <Button className='mr-2' variant='outline-primary btn-lg' onClick={e => facialIdent()} style={{ width: '90%' }}>
                                                            <span className='btn-icon-left text-primary' style={{ background: 'none' }}>
                                                                <i className='fa fa-camera' />
                                                            </span>
                                                            <span style={{ textTransform: 'uppercase' }}>{t('patient.facial-recognition')}</span>
                                                        </Button>
                                                        // <div className='col-6' onClick={e => facialIdent()}>
                                                        //     <div className='text-white d-flex flex-column p-3 align-items-center justify-content-center bg-div' style={{width: '200px', height: '200px'}}>
                                                        //         <i className='la la-camera pb-3' style={{fontSize: '75px'}}></i>
                                                        //         <h5 style={{color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Par identification faciale</h5>
                                                        //     </div>
                                                        // </div>
                                                    }
                                                    <br />
                                                    <br />
                                                    <br />
                                                    {hasMenu('MENIDENT') && canDo('MENIDENT', 'ACIDENTNUMASSU') &&
                                                        <Button className='mr-2' variant='outline-primary btn-lg' onClick={e => cardIdent()} style={{ width: '90%' }}>
                                                            <span className='btn-icon-left text-primary' style={{ background: 'none' }}>
                                                                <i className='fa fa-id-card' />
                                                            </span>
                                                            <span style={{ textTransform: 'uppercase' }}>{t('patient.with-insured-number')}</span>
                                                        </Button>
                                                        // <div className='col-6' onClick={e => cardIdent()}>
                                                        //     <div className='bg-primary text-white rounded-circle d-flex flex-column p-3 align-items-center justify-content-center bg-div' style={{width: '200px', height: '200px'}}>
                                                        //         <i className='la la-id-card pb-3' style={{fontSize: '75px'}}></i>
                                                        //         <h5 style={{color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Par numéro assuré</h5>
                                                        //     </div>
                                                        // </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </div >

        </>
    )
};
const mapStateToProps = (state) => {
    return {
        currentUser: currentUser(state)
    };
};
export default withRouter(connect(mapStateToProps)(PreIdentify));